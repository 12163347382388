/**
 * @file
 * Location contact
 */

//////////////////////////////////////////
// Location: Contact
//////////////////////////////////////////

.location-contact {
  @extend %clearfix;

  @include media($medium-min) {
    @include grid-span(6, 1);
    margin: 2.5em 0em;
  }
}





//////////////////////////////////////////
// Location: Contact address
//////////////////////////////////////////

.location-contact__address {
  background: url('/assets/images/svg/ico--map-pin--blue.svg') no-repeat 0 5px;
  padding-left: 35px;
  margin-bottom: rhythm(2);

  .no-svg & {
    background: url('/assets/images/png/ico--map-pin--blue.png') no-repeat 0 5px;
  }

  @include media($x-small-min) {
    float: left;
    width: 50%;
    margin-bottom: 0;
  }
}

// Location address title styles
.location-contact__address__title {
  @include sans-gotham($weight: 700, $style: normal);
}

// Location contact link styles
.location-contact__links {
    padding-left: 35px;

    @include media($x-small-min) {
        float: right;
        width: 50%;
        text-align: center;
        padding-left: 0;
    }

    .tel {
        @include sans-gotham($weight: 700, $style: normal);
        font-size: 1.1em;
        display: block;
        margin-bottom: rhythm(1);
    }

    .contact_clusters {
        padding-bottom: rhythm(0.5);
    }

    .button {
        margin-bottom: rhythm(1);
    }
}


.location-contact__hours {
  font-size: .9em;
  line-height: 1.25em;
  margin-bottom: 2em;

  h6 {
    text-transform: capitalize;
    margin-bottom: 0;
    margin-top: 1.5em;
  }
}


//////////////////////////////////////////
// Location: Map
//////////////////////////////////////////
.location-contact__image,
.location-map {
  display: none;
  overflow: hidden;

  @include media($medium-min) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 47%;
    height: 100%;
  }
}

.location-contact__image {
  background-size: cover;
  background-position: left center;
  background-repeat: no-repeat;
}

a.anchor
{
    display: block;
    position: relative;
    top: -70px;
    visibility: hidden;
}