/**
 * @file
 * Button styles
 */

//////////////////////////////////////////
// Button: Default
//////////////////////////////////////////

button,
.button,
input[type="button"],
input[type="submit"] {
    @include sans-gotham($weight: 700);
    @include adjust-font-size-to($button, 0.5);
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    background: $mlh-blue-link;
    border: 1px solid $mlh-blue-link;
    border-radius: 6px;
    display: inline-block;
    color: $white;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    transition: all .3s;
    vertical-align: middle;
    padding: rhythm(1) rhythm(1.5) rhythm(.8);
    margin-top: 0;
    line-height: 1.2em;

    &:active {
        color: $white;
    }

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
        background: $mlh-blue-gray;
        border-color: $mlh-blue-gray-dark;
        color: $mlh-blue-link;
    }

    &:focus:not(:disabled) {
        box-shadow: inset 0 1px 3px rgba($black, .15);
    }

    &:disabled {
        background: rgba($mlh-blue,.5);
        border: 1px solid rgba($mlh-blue,.1);
    }

    @media (min-width: 400px) {
        .button {
            @include adjust-font-size-to($button, 1);
        }
    }
    //////////////////////////////////////////
    // Button: Secondary button
    //////////////////////////////////////////
    &.button--secondary {
        background: $mlh-blue-gray;
        border-color: $mlh-blue-gray-dark;
        color: $mlh-blue-link;

        &:hover:not(:disabled),
        &:focus:not(:disabled) {
            background: $white;
            border-color: darken($mlh-blue-gray-dark, 10%);
        }
    }
    //////////////////////////////////////////
    // Button: White button
    //////////////////////////////////////////
    &.button--white {
        background: $white;
        border-color: $white;
        color: $mlh-blue;

        &:hover {
            background: $mlh-blue-gray;
            border-color: $mlh-blue-gray;
        }
    }
    //////////////////////////////////////////
    // Button: Orange button
    //////////////////////////////////////////
    &.button--orange {
        background: $mlh-orange;
        border-color: $mlh-orange;
        color: $white;

        &:hover {
            background: rgba($mlh-orange, .7);
            border-color: rgba($mlh-orange, .7);
        }
    }
    //////////////////////////////////////////
    // Button: Show code - styleguide
    //////////////////////////////////////////
    &.button--show-code {
        margin: rhythm(1) 0 rhythm(8);
    }
    //////////////////////////////////////////
    // Button: Large button
    //////////////////////////////////////////
    &.button--large {
        font-size: .8em;
        padding: rhythm(1.8) rhythm(2.4) rhythm(1.6);
    }
    //////////////////////////////////////////
    // Button: Top page button
    //////////////////////////////////////////
    &.button-full-width {
        width: 100%;
    }
    //////////////////////////////////////////
    // Button: Top page button
    //////////////////////////////////////////
    &.button-top {
        border-radius: 0 0 6px 6px;
        border-top: 0;
    }
    //////////////////////////////////////////
    // Button: Postfix
    //////////////////////////////////////////
    &.postfix-overlay {
        position: absolute;
        top: 0;
        right: 0;
        background-color: transparent;
        border-color: transparent;
        color: $mlh-blue;
        font-size: 1.1em;
        line-height: 2.2em;
        padding: 0 rhythm(1);
        margin: 0;

        &:hover {
            color: $mlh-blue-dark;
        }
    }

    .postfix & {
        display: block;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        width: 100%;
        line-height: 3.45em;
        padding-top: 0;
        padding-bottom: 0;
        margin: 0;
    }
}




//////////////////////////////////////////
// Button: Load more items
//////////////////////////////////////////

.load-more {
  text-align: center;
  margin: rhythm(4) 0 0;

  .button {
    width: 100%;

    @include media($x-small-min) {
      width: 75%;
    }
  }
}




//////////////////////////////////////////
// Button: More filter options
//////////////////////////////////////////

.button--more-options {
  width: 100%;
  margin-bottom: rhythm(2);

  @include media($xx-small-min) {
    width: auto;
    margin: 0;
  }
}

.button--slim
{
    width: 30%;
    border-radius: 50%;
    cursor: pointer;
}

//////////////////////////////////////////
// Button: ZocDoc button
//////////////////////////////////////////

.button--zocdoc {
    margin-bottom: rhythm(2);
    background: $mlh-blue-gray;
    border-color: $mlh-blue-gray-dark;
    color: $mlh-blue-link;

    &:hover,
    &:focus {
        background: $white;
        border-color: darken($mlh-blue-gray-dark, 10%);
    }
}