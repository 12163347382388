// /**
//  * @file
//  * Maps and Directions
//  */

//////////////////////////////////////////
// Directions: Default styles
//////////////////////////////////////////

.directions {
  position: relative;
}

.directions-result {
  @include media($x-small-min) {
    max-height: 600px;
    overflow-y: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
  }

  @include media($medium-min) {
    @include grid-span(6, 1);
  }
}

.directions-map {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 47%;
  height: 100%;

  iframe {
    height: 100%;
    position: relative;
    width: 100%;
  }

  @include media($medium-min) {
    display: block;
  }
}
