/**
 * @file
 * Doctor profile splash
 */

//////////////////////////////////////////
// Profile Splash: Default
//////////////////////////////////////////

.splash--small {

  // Hide the splash image at mobile viewport
  .splash__image {

    @include media($x-small-min) {
      // Overlay defuse
      &:before {
        background-color: rgba($mlh-gray-dark, .65);
      }
    }
  }
}
