.mlhpp {
	position: relative;
	margin: 4px 0 12px;
	font-size: 13px;
	line-height: 12px;
	font-weight: normal;
	// tooltip.png
	img {
		margin-left: 2px;
	}

	figcaption {
		background-color: #e7eff3;
		border-radius: 3px;
		display: block;
		font-size: .75rem;
		font-style: normal;
		position: absolute;
		top: calc(100% + 10px);
		left: 0;
		line-height: 1.25rem;
		opacity: 0;
		padding: 10px;
		transition: all 500ms ease-in-out 500ms;
		visibility: hidden;
		width: auto;
		z-index: 100;
	}
	// make sure the link color doesn't get overwritten by it's parent
	a {
		color: $link-color;

		&:hover,
		&:focus,
		&:active {
			color: $link-hover;
		}
	}

	&:hover {
		figcaption {
			opacity: 1;
			visibility: visible;
		}
	}
}
