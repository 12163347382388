/**
 * @file
 * Search Results
 */

//////////////////////////////////////////
// Search: Default
//////////////////////////////////////////

.search-results__wrap {
  background-color: $white;
  padding: rhythm(3) rhythm(1);
  margin: 0 rhythm(-1);

  @include media($x-small-min) {
    padding-left: rhythm(1.5);
    padding-right: rhythm(1.5);
    margin: 0 rhythm(-1.5);
  }

  @include media($medium-min) {
    padding: rhythm(3);
    margin: 0;
  }
}




//////////////////////////////////////////
// Location Section: Menu toggle
//////////////////////////////////////////

.content-type__menu-toggle {
  @include adjust-font-size-to($nav, 2);
  @include sans-gotham($weight: 700);
  display: block;
  background-color: $mlh-blue;
  text-transform: uppercase;
  color: $white;
  cursor: pointer;
  padding: rhythm(1);

  &.open i {
    transform: rotate(180deg);
  }

  @include media($medium-min) {
    display: none;
  }
}
