/**
 * @file
 * Sidebar ads
 */

//////////////////////////////////////////
// Sidebar Ads: Default styles
//////////////////////////////////////////

.sidebar--ad {
  background-color: $off-white;
  border: 1px solid $gray-light;
  border-radius: 4px;
  text-align: center;
  padding: rhythm(1);

  @include media($medium-min) {
    border: 0;
    border-radius: 0;
    padding: 0;
  }

  a {
    display: block;
  }
}
