.doctor-bio {
	@extend %clearfix;

	&__image {
		float:left;
		width: 75px;
		height: auto;

		@include media($x-small-min) {
			width: 125px;
		}

		@include media($large-min) {
			width: 150px;
			height: 160px;
		}
		& > a {
			display:block;
			text-align: center;

			@include media($medium-min) {
				text-align: left;
			}
			img{
		@include media($large-min) {
				height: 160px
				}
			}
		}
	}

	&__more {
		background-color: $mlh-gray-dark;
		color: $white;
		cursor: pointer;
		display: block;
		font-size: .6em;
		font-weight: 700;
		padding: rhythm(.5) 0;
		text-transform: uppercase;
		text-align: center;

		@include media($medium-min) {
			display: none;
		}
	}

	&__info {
		float:left;
		margin-left:20px;

		@include media($medium-min) {
			margin-top: rhythm(1.5);
			margin-right: rhythm(3);
			width: 280px;
		}

		@include media($x-large-min) {
			width: 280px;
		}
		@media only screen and (max-width: 630px)  {
	        position: absolute;
			margin-left: 90px;
	    }
	}

	&__title {
		@extend %gray-link;
		@include adjust-font-size-to($h4-font-size, 2);
		@include leader(0, $h4-font-size);
		@include trailer(.25, $h4-font-size);
	}

	&__specialties {
		@extend %no-bullets;
		font-size: .9em;
		line-height: 1.4;
		margin-bottom: rhythm(.5);
	}

	&__rating {
		@extend %no-bullets;
		display: none;

		li {
			color: $yellow;
			display: inline-block;

			&.empty {
				color: $gray-light;
			}
		}
	}
}
