/**
 * @file
 * Header global styles
 */

//////////////////////////////////////////
// Header: Default
//////////////////////////////////////////


.site-header {
  @extend %clearfix;
  position: relative;
  background: $white;
  width: 100%;
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
}

//////////////////////////////////////////
// Thin Crust Navigation
//////////////////////////////////////////
.site-header--thincrustNavigation
{
    height: 35px;
    display: none;
    position:relative;
    margin-top:0px !important;
    width: 100%;

    ~ .site-header
    {
        padding-top:0px;
    }

    ul
    {
        list-style-type: none;
        margin: 0px;
        margin-right: 1em;
        padding-top: .2em;

        li
        {
            display: inline-block;
            margin-right: 0.5em;

            a
            {
                @include adjust-font-size-to($nav, 2.5);
                @include sans-gotham($weight: 500);
                font-size: 0.6em;
                line-height: 2;
                text-transform: uppercase;

                &:hover
                {
                    color: #009fda !important;
                }
            }
        }

        li:last-child
        {
            display:none;
        }

       
    }
    @include media($medium-min) {
        display: block;
    }
}


//////////////////////////////////////////
// Header: Small display
//////////////////////////////////////////
.site-header--small {
    padding: 0;

    @include media($medium-min) {
        display: none;
    }

    .site-header__wrap {
        background-color: $white;
        box-shadow: 0 5px 10px rgba($black, .2);
        position: relative;
        min-height: 4.75em;
        z-index: 100;
    }
}

.site-header--logo--small {
    float: left;
    margin-top: rhythm(1.75);

    img {
        width: 140px;
        height: auto;

        @include media($xx-small-min) {
            width: 200px;
        }

        &.fallback {
            display: none;
        }

        .no-svg & {
            &:first-child {
                display: none;
            }

            &.fallback {
                display: block;
            }
        }
    }
}

.site-header--buttons-small {
    float: right;
    margin-top: rhythm(1.75);

    ul {
        @extend %no-bullets;
    }

    li {
        display: inline-block;
    }

    .button--like-to__text {
        display: none;

        @include media($xx-small-min) {
            display: inline;
        }
    }
}

.like-to {
    transition: all .3s ease;
    transform: translateY(-100px);
    position: absolute;
    top: 4.75em;
    left: 0;
    background: transparent;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    z-index: 99;
    height: calc(100vh - 80px);
    overflow-y: auto;


    &.active {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }

    i {
        float: right;
        color: $mlh-gray-light;
        line-height: 1.4;
    }

    @include media($medium-min) {
        display: none !important;
    }

    ul {
        @extend %no-bullets;
    }

    li {
        border-bottom: 1px solid $mlh-blue-gray;
        background: $white;

        &:last-child {
            border-bottom: 0;
        }
    }

    a {
        display: block;
        padding: rhythm(1) 5%;

        @include media($x-small-min) {
            padding: rhythm(1) 3%;
        }

        @include media($small-max-landscape) {
            padding: rhythm(.4) 3%;
        }

        &:hover,
        &:focus {
            background: $mlh-blue;
            color: $white;

            i {
                color: $white;
            }
        }
    }
}

.search--small {
    @include trans($timing, $easing);
    transform: translateY(-100px);
    position: absolute;
    top: 4.75em;
    left: 0;
    background: $white;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    padding: rhythm(2) 0;
    z-index: 99;

    &.active {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }

    @include media($medium-min) {
        display: none !important;
    }
}

.search--small__wrap {
    position: relative;
}
//////////////////////////////////////////
// Header: Large display
//////////////////////////////////////////
.site-header--large {
    display: none;
    box-shadow: 0 5px 15px rgba($black, .15);

    @include media($medium-min) {
        display: block;
    }

    .site-header__wrap {
        @extend %clearfix;
        position: relative;
        background-color: $white;
        padding: 0 0 rhythm(1);
        z-index: 100;

        .no-boxshadow & {
            border-bottom: 1px solid $gray-light;
        }
        /*@include media($xx-large-min) {
    padding: rhythm(1.75) 0;
}*/
        @include breakpoint($bp4, $no-query: true) {
            padding: rhythm(1.75) 0;
        }
    }
}
//////////////////////////////////////////
// Header: Logo
//////////////////////////////////////////
.site-header--logo {
    position: absolute;
    top: rhythm(0.5);
    left: rhythm(0.75);
    /*background: $white;*/
    border-radius: 0 0 4px 4px;
    /*box-shadow: 0 -10px 60px rgba($black, .15);*/
    z-index: 100;
    width: 100%;
    max-width: 250px;

    .no-boxshadow & {
        border-bottom: 1px solid $gray-light;
    }

    h2 {
        margin: 0;
    }

    a {
        display: block;
        padding: .51em 0em;
        background-color: transparent;
    }
}
//////////////////////////////////////////
// Header: Navs
//////////////////////////////////////////
.site-header--navs {
    @extend %clearfix;
    float: right;
    margin-right: rhythm(0.75);
    /*padding-left: rhythm(16.5);*/
}

.site-header--nav {
    float: left;
    margin-top: rhythm(1.6);



    @include media($xx-large-min) {
        border-right: 1px solid $mlh-blue-gray-dark;
        margin-top: 0;
    }

    ul {
        padding: 0;
        margin: 0;
    }

    li {
        display: inline-block;
        margin-right: rhythm(0.75);

        /*@include media($large-min) {
            margin-right: rhythm(.75);
        }

        @include media($x-large-min) {
            margin-right: rhythm(0.75);
        }*/

        &:last-child {
            margin-right: 0;

            @include media($large-min) {
                margin-right: rhythm(1);
            }
        }
    }
    // Top link styles
    a {
        @include adjust-font-size-to($nav, 2.5);
        @include sans-gotham($weight: 700);
        color: $mlh-gray-dark;
        text-transform: uppercase;

        &:hover,
        &:focus {
            color: $mlh-blue-link;
        }

        &.active {
            color: $mlh-blue-link;
        }
    }
    // Set active section
    .doctors & {

        .doctors {
            color: $mlh-blue;
        }
    }
}
//////////////////////////////////////////
// Header: Site section map
//////////////////////////////////////////

$main-nav: 'doctors', 'location', 'specialty', 'condition-treament';

// Loop
@each $site-section in $main-nav {

    .#{$site-section} .site-header--nav {

        a.#{$site-section} {
            color: $mlh-blue;
        }
    }
}
//////////////////////////////////////////
// Header: Buttons
//////////////////////////////////////////
.site-header--buttons {
    //display: none;
    float: left;
    margin-left: rhythm(1);

    @include media($medium-min) {
        display: block;
        position: absolute;
        top: 0;
        right: 1em;

        .button {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }

    @include media($xx-large-min) {
        position: relative;
        top: auto;
        right: auto;

        .button {
            border-top-left-radius: $global-radius;
            border-top-right-radius: $global-radius;
        }
    }

    /*@include media($x-large-min) {
        margin-left: rhythm(1.25);
    }*/

    ul {
        padding: 0;
        margin: 0;
    }

    li {
        display: inline-block;
        /*margin-right: rhythm(.5);*/

        &:last-child {
            margin-right: 0;
        }
    }
}
