//////////////////////////////////////////
// Splash: Social bar
//////////////////////////////////////////

.share {
	float: left;

	.share__label {
		display: inline-block;
		font-size: .75em;
		font-weight: 700;
		text-transform: uppercase;
		vertical-align: middle;
	}

	.social.addthis_custom_sharing {
		@extend %no-bullets;
		display: inline-block;
		vertical-align: middle;

		> div {
			font-size: .9em;
			height: 2em;
		}
		li {
			display: inline-block;
		}

		.at-share-btn {
			border-radius: 100%;
			background-color: $off-white;
			color: darken($gray-medium, 10%);
			display: inline-block;
			font-size: 0.9em;
			height: 2em;
			line-height: 2em;
			margin-right: 0.5em;
			position: relative;
			text-align: center;
			transition: transform .3s ease;			
			vertical-align: middle;
			width: 2em;

			@include media($x-small-min) {
				background-color:#ffffff;

				&:hover {
					transform: translateY(-4px);
				}
			}

			&:before {
				color: inherit;
				display: inline-block;
				font-family: FontAwesome;
				font-size: inherit;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				transform: translate(0, 0);
				transition: color .3s ease;
				vertical-align: middle;
			}

			span {
				display:none;
			}
		}
		.at-svc-facebook:before {
			content: "\f09a";
		}

		.at-svc-twitter:before {
			content: "\f099";
		}

		.at-svc-google_plusone_share:before {
			content: "\f0d5"
		}

		.at-svc-compact:before {
			content: "\f1e0";
		}

		// Share map
		$share-social:  
			('facebook', $facebook),
			('twitter', $twitter),
			('google_plusone_share', $youtube),
			('compact', $orange);

		// Loop
		@each $social-network, $color in $share-social {

			.at-svc-#{$social-network}:hover {
				color: $color;
			}
		}

	}
}



