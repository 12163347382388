/**
 * @file
 * Location splash
 */

//////////////////////////////////////////
// Location Splash: Default
//////////////////////////////////////////

.splash--location {

  // Spanning splash image
  .splash__image {

    @include media($x-small-min) {

      // Overlay defuse
      &:before {
        background: rgba($mlh-gray-dark, .2);
      }

      // Overlay bottom gradient
      &:after {
        background: linear-gradient(to bottom, rgba($black, 0) 0%, rgba($black, 0) 25%, rgba($black, .8) 100%);
      }
    }
  }

  .splash__content {
    @include media($medium-min) {
      padding-bottom: 85px;
    }
  }

  .share {
    @include media($medium-min) {
      bottom: 2.5em;
    }
  }
}
