$spotlight-bp-not-tablet: new-breakpoint(min-width 769px);

/**
 * @file
 * Patient spotlight
 */
.spotlight-container--empty {
    background-color: #fff;
    padding: 2.4375em;
}

.spotlight-conditions-filter, .spotlight-treatments-filter {
    margin-left: 2%;
    position: relative;
}

.spotlight-conditions-filter {
    z-index: 100;
    margin-bottom: rhythm(1);
}

@include media($x-small-min) {
    .spotlight-conditions-filter {

        margin-bottom:0;
    }
}

.spotlight-results {
    @extend %clearfix;
}

.spotlight--block {
    @extend %clearfix;
    background-color: $mlh-blue-gray;
    box-shadow: 0 1px 1px rgba($black, .15);
    box-sizing: border-box;
    padding: rhythm(1);
    margin: rhythm(1.5) 2%;
    width: 96%;

    .spotlight__rec-image {
        display: block;
        text-align: center;
        margin: 0 auto;
    }

    .spotlight__content {
        margin-top: rhythm(1);
    }

    @include media($x-small-min) {
        // @include grid-span(6, 1, $output-style: 'float');
        width: 46%;

        .spotlight__rec-image {
            margin: rhythm(-1) rhythm(-1) 0;

            img {
                width: 100%;
                height: auto;
            }
        }

        .spotlight__content {
            display: block;
            margin: rhythm(1) 0 0;
        }
    }

    @include media($spotlight-bp-not-tablet) {
        //@include grid-span(4, 1, $output-style: 'float');
        width: 29%;
    }
}
