//////////////////////////////////////////
// Google Map Tooltip
//////////////////////////////////////////

.gmap-info {
	color: $text-color;
	min-width: 160px;
	padding-bottom: 1.25em;
	
	h3 {
		color: $text-color;
		margin: 0.25em 0;
	}

	p {
		line-height: 1.25em;
	}

	a {
		color: $link-color;
		font-weight: normal;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	&__links {
		display:flex;
		justify-content: space-between;
		position:absolute;
		width:100%;
	}
}