/**
 * @file
 * Doctor Compare
 */

//////////////////////////////////////////
// Doctor Compare: Default
//////////////////////////////////////////

.compare-doctor td {
  padding-top: rhythm(3);
}

.compare-item {
  @extend %no-bullets;
  font-size: .9em;
  margin-bottom: rhythm(1);

  &:last-child {
    margin-bottom: 0;
  }

  li {
    line-height: 1.2;
    margin-bottom: rhythm(.5);
  }
}

.compare-img {
  float: left;
  max-width: 120px;
}

.compare-content {
  margin-left: 135px;
}

.compare-title {
  @extend %gray-link;
  @include adjust-font-size-to($h3-font-size, 2);
}

.compare-action td {
  background-color: $off-white;

  .compare-remove {
    float: right;
    margin-top: rhythm(.9);
  }
}
