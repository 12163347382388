/* style for the Web Forms for Marketers form elements */

form[data-wffm] {
	.control-label {
		margin-bottom:0;
	}

	.form-group {
		margin-bottom: 1em;
	}

	.field-validation-error {
		color: $input-invalid-text;
		font-size: 0.8em;
	}

	.has-error .form-control {
	    border-color: $input-invalid-text;
	    -webkit-box-shadow: inset 0 1px 1px rgba($input-invalid-text,.075);
	    box-shadow: inset 0 1px 1px rgba($input-invalid-text,.075);
	}

	.has-error.has-feedback .validation-summary-errors {
		color: $input-invalid-text;
		font-size: 0.8em;
	}

	legend {
		margin:0;
		padding-top: 1em;
	}
}
