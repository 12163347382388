﻿/**
 * @file
 * Privacy Pop up design
 */

//////////////////////////////////////////
// Popup: Default
//////////////////////////////////////////
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 3000; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 60%;
    position:absolute;
    bottom:12px;
    left:0;
    right:0;
}

/* The Close Button */
.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.accept {
    font-size: 14px;
    padding:15px;
    float:left;
    margin:0 20px 0 0;
}