/**
 * @file
 * Centralize your imports here.
 * Styles are organized using the SMACSS technique.
 * @see http://smacss.com/book/
 */

/* Import Sass mixins, variables, Compass modules, etc. */
@import 'base/init';
@import 'base/breakpoints';
@import 'base/colors';


/* Now we add our custom helpers. */
@import 'utils/mixins';
@import 'utils/helpers';


/* HTML element (SMACSS base) rules */
@import 'base/normalize';
@import 'base/typography';


/* Vendors rules */
@import 'vendors/l-singularity-base';
@import 'vendors/l-1up';
@import 'vendors/l-66-33';
@import 'vendors/l-33-66';
@import 'vendors/l-2up';
@import 'vendors/l-3up';
@import 'vendors/l-4up';
@import 'vendors/l-6up';
@import 'vendors/select2';
@import 'vendors/pikaday';


/* Layout rules */
@import 'layouts/bg-overlay';
@import 'layouts/footer';
@import 'layouts/grid';
@import 'layouts/main';
@import 'layouts/printProfile';
@import 'layouts/sidebar';
@import 'layouts/site-header';
@import 'layouts/site-header__dropdowns';
@import 'layouts/privacyPopUp';
// @import 'layouts/webfont-loader';  // removed to prevent 3rd party from blocking page load - Jonathan


/* Component (SMACSS module) rules */
@import 'components/accordion';
@import 'components/aside';
@import 'components/buttons';
@import 'components/calendar';
@import 'components/callouts';
@import 'components/checkbox-mlhc';
@import 'components/contact-component';
@import 'components/coveo';
@import 'components/doctor-bio';
@import 'components/dropdownButton';
@import 'components/erexpress';
@import 'components/expansion';
@import 'components/featured';
@import 'components/filters';
@import 'components/filterHTMLTable';
@import 'components/filter-tags';
@import 'components/fitvids';
@import 'components/forms';
@import 'components/getcarenow';
@import 'components/gmap-info';
@import 'components/googleCharts';
@import 'components/healthbot';
@import 'components/health-system';
@import 'components/htmlblock';
@import 'components/iframes';
@import 'components/jq-dropdownButton';
@import 'components/list';
@import 'components/location-contact';
@import 'components/maps';
@import 'components/meta';
@import 'components/mlhpp';
@import 'components/_notification-banner.scss';
@import 'components/_new-branding';
@import 'components/_nrc';
@import 'components/pager';
@import 'components/page-content';
@import 'components/patient-spotlight';
@import 'components/preloader';
@import 'components/related-box';
@import 'components/related-components';
@import 'components/results';
@import 'components/search';
@import 'components/select2-overrides';
@import 'components/share';
@import 'components/sidebar__prox-nav';
@import 'components/site-wrapper';
@import 'components/sitecore-form';
@import 'components/spinner';
@import 'components/sidebar__ads';
@import 'components/sidebar__fieldgroup';
@import 'components/socialMediaIcons';
@import 'components/splash';
@import 'components/splash--home';
@import 'components/splash--location';
@import 'components/splash--small';
@import 'components/splash--home__nav';
@import 'components/spotlight';
@import 'components/spotlight--slides';
@import 'components/star-ratings';
@import 'components/stepperbar';
@import 'components/top-specialties';
@import 'components/wffm';
@import 'components/dynamic-listing';
@import 'components/epic--openScheduler';
@import 'components/universal--column-view';
@import 'components/_body-related-events.scss';


/* Section Specific rules */
@import 'section/appointment';
@import 'section/directions';
@import 'section/doctor-profile';
@import 'section/doctor-compare';
@import 'section/events';
@import 'section/location';
@import 'section/search-results';
@import 'section/researcher';
@import 'section/clinicaltrial';


/* Styleguide Specific rules */
// @import 'styleguide/styleguide';


/* print rules */
@import 'utils/print';

@import 'limr/styles';

/* ie only */
@import 'utils/ieonly';
