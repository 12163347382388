/**
 * @file
 * Select2 styles
 */


.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;

  .form-item & {
    width: 100% !important;
  }

  .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 28px;
    user-select: none;
    -webkit-user-select: none;

    .select2-selection__rendered {
      display: block;
      padding-left: 8px;
      padding-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &[dir="rtl"] .select2-selection--single.select2-selection__rendered {
    padding-right: 8px;
    padding-left: 20px;
  }

  .select2-selection--multiple {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 32px;
    user-select: none;
    -webkit-user-select: none;

    .select2-selection__rendered {
      display: inline-block;
      overflow: hidden;
      padding-left: 8px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .select2-search--inline {
    float: left;

    .select2-search__field {
      box-sizing: border-box;
      border: none;
      font-size: 100%;
      margin-top: 5px;

      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
      }
    }
  }
}

.select2-dropdown {
  background-color: $white;
  border: 1px solid darken($gray-light, 20%);
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;

  &[aria-selected] {
    cursor: pointer;
  }
}

.select2-container--open {

  .select2-dropdown {
    left: 0;
  }

  .select2-dropdown--above {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .select2-dropdown--below {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.select2-search--dropdown {
  display: block;
  padding: 4px;

  .select2-search__field {
    padding: 4px;
    width: 100%;
    box-sizing: border-box;

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }

  &.select2-search--hide {
    display: none;
  }
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: $white;
  filter: alpha(opacity = 0);
}

.select2-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

////////////////////////////////////////
// Default
////////////////////////////////////////
.select2-container--default {

  .select2-selection--single {
    @include trans(.1s, ease-out);
    background-color: $select-background;
    border: 1px solid $input-border-color;
    box-shadow: inset 0 1px 3px rgba($black, .1);
    border-radius: $global-radius;
    height: 2.5em;

    &:hover {
      border: 1px solid $input-border-hover;
    }

    .select2-selection__rendered {
      color: $form-text;
      line-height: 2.5em;
    }

    .select2-selection__clear {
      cursor: pointer;
      float: right;
      font-weight: bold;
    }

    .select2-selection__placeholder {
      color: $gray-medium;
    }

    .select2-selection__arrow {
      height: 2.5em;
      position: absolute;
      top: 1px;
      right: 1px;
      width: 20px;

      b {
        border-color: $gray-medium transparent transparent transparent;
        border-style: solid;
        border-width: 5px 4px 0 4px;
        height: 0;
        left: 50%;
        margin-left: -4px;
        margin-top: -2px;
        position: absolute;
        top: 50%;
        width: 0;
      }
    }
  }

  &[dir="rtl"] .select2-selection--single {

    .select2-selection__clear {
      float: left;
    }

    .select2-selection__arrow {
      left: 1px;
      right: auto;
    }
  }

  &.select2-container--disabled .select2-selection--single {
    background-color: lighten($gray-light, 2%);
    cursor: default;
    .select2-selection__clear {
      display: none;
    }
  }

  &.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent $gray-medium transparent;
    border-width: 0 4px 5px 4px;
  }

  .select2-selection--multiple {
    background-color: $white;
    border: 1px solid $input-border-color;
    border-radius: $global-radius;
    box-shadow: inset 0 1px 3px rgba($black, .1);
    cursor: text;
    min-height: 2.5em;

    .select2-search--inline .select2-search__field {
      height: auto;
      box-shadow: none;
    }

    .select2-selection__rendered {
      box-sizing: border-box;
      list-style: none;
      margin: 0;
      padding: 0 5px;
      width: 100%;
    }

    .select2-selection__placeholder {
      color: $gray-medium;
      margin-top: 5px;
      float: left;
    }

    .select2-selection__clear {
      cursor: pointer;
      float: right;
      font-weight: bold;
      margin-top: 5px;
      margin-right: 10px;
    }

    .select2-selection__choice {
      background-color: lighten($gray-light, 2%);
      border: 1px solid darken($gray-light, 20%);
      border-radius: 4px;
      cursor: default;
      float: left;
      margin-right: 5px;
      margin-top: 5px;
      padding: 0 5px;
    }

    .select2-selection__choice__remove {
      color: $gray-medium;
      cursor: pointer;
      display: inline-block;
      font-weight: bold;
      margin-right: 2px;
      &:hover {
        color: darken($gray-dark, 6%);
      }
    }
  }

  &[dir="rtl"] .select2-selection--multiple {

    .select2-selection__choice, .select2-selection__placeholder {
      float: right;
    }

    .select2-selection__choice {
      margin-left: 5px;
      margin-right: auto;
    }

    .select2-selection__choice__remove {
      margin-left: 2px;
      margin-right: auto;
    }
  }

  &.select2-container--focus .select2-selection--multiple {
    border: 1px solid $input-border-hover;
    outline: 0;
  }

  &.select2-container--disabled {

    .select2-selection--multiple {
      background-color: lighten($gray-light, 2%);
      cursor: default;
    }

    .select2-selection__choice__remove {
      display: none;
    }
  }

  &.select2-container--open {

    &.select2-container--above {

      .select2-selection--single, .select2-selection--multiple {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    &.select2-container--below {

      .select2-selection--single, .select2-selection--multiple {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .select2-search--dropdown .select2-search__field {
    border: 1px solid darken($gray-light, 20%);
  }

  .select2-search--inline .select2-search__field {
    background: transparent;
    border: none;
    outline: 0;
  }

  .select2-results > .select2-results__options {
    max-height: 200px;
    overflow-y: auto;
  }

  .select2-results__option {

    &[role=group] {
      padding: 0;
    }

    &[aria-disabled=true] {
      color: $gray-medium;
    }

    &[aria-selected=true] {
      background-color: $off-white;
      color: $mlh-blue;
    }

    .select2-results__option {
      padding-left: 1em;

      .select2-results__group {
        padding-left: 0;
      }

      .select2-results__option {
        margin-left: -1em;
        padding-left: 2em;

        .select2-results__option {
          margin-left: -2em;
          padding-left: 3em;

          .select2-results__option {
            margin-left: -3em;
            padding-left: 4em;

            .select2-results__option {
              margin-left: -4em;
              padding-left: 5em;

              .select2-results__option {
                margin-left: -5em;
                padding-left: 6em;
              }
            }
          }
        }
      }
    }
  }

  .select2-results__option--highlighted[aria-selected] {
    background-color: $mlh-blue;
    color: $white;
  }

  .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
  }
}

.select2-container--classic {

  .select2-selection--single {
    background-color: darken($off-white, 1%);
    border: 1px solid darken($gray-light, 20%);
    border-radius: 4px;
    outline: 0;
    background-image: linear-gradient(to bottom, $white 50%, lighten($gray-light, 2%) 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$white', endColorstr='lighten($gray-light, 2%)', GradientType=0);

    &:focus {
      border: 1px solid $mlh-blue;
    }

    .select2-selection__rendered {
      color: $gray-dark;
      line-height: 28px;
    }

    .select2-selection__clear {
      cursor: pointer;
      float: right;
      font-weight: bold;
      margin-right: 10px;
    }

    .select2-selection__placeholder {
      color: $gray-medium;
    }

    .select2-selection__arrow {
      background-color: $gray-light;
      border: none;
      border-left: 1px solid darken($gray-light, 20%);
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      height: 26px;
      position: absolute;
      top: 1px;
      right: 1px;
      width: 20px;
      background-image: linear-gradient(to bottom, lighten($gray-light, 2%) 50%, darken($gray-light, 6%) 100%);
      background-repeat: repeat-x;
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='lighten($gray-light, 2%)', endColorstr='darken($gray-light, 6%)', GradientType=0);

      b {
        border-color: $gray-medium transparent transparent transparent;
        border-style: solid;
        border-width: 5px 4px 0 4px;
        height: 0;
        left: 50%;
        margin-left: -4px;
        margin-top: -2px;
        position: absolute;
        top: 50%;
        width: 0;
      }
    }
  }

  &[dir="rtl"] .select2-selection--single {

    .select2-selection__clear {
      float: left;
    }

    .select2-selection__arrow {
      border: none;
      border-right: 1px solid darken($gray-light, 20%);
      border-radius: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      left: 1px;
      right: auto;
    }
  }

  &.select2-container--open {

    .select2-selection--single {
      border: 1px solid $mlh-blue;

      .select2-selection__arrow {
        background: transparent;
        border: none;

        b {
          border-color: transparent transparent $gray-medium transparent;
          border-width: 0 4px 5px 4px;
        }
      }
    }

    &.select2-container--above .select2-selection--single {
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      background-image: linear-gradient(to bottom, $white 0%, lighten($gray-light, 2%) 50%);
      background-repeat: repeat-x;
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$white', endColorstr='lighten($gray-light, 2%)', GradientType=0);
    }

    &.select2-container--below .select2-selection--single {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background-image: linear-gradient(to bottom, lighten($gray-light, 2%) 50%, $white 100%);
      background-repeat: repeat-x;
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='lighten($gray-light, 2%)', endColorstr='$white', GradientType=0);
    }
  }

  .select2-selection--multiple {
    background-color: $white;
    border: 1px solid darken($gray-light, 20%);
    border-radius: 4px;
    cursor: text;
    outline: 0;

    &:focus {
      border: 1px solid $mlh-blue;
    }

    .select2-selection__rendered {
      list-style: none;
      margin: 0;
      padding: 0 5px;
    }

    .select2-selection__clear {
      display: none;
    }

    .select2-selection__choice {
      background-color: lighten($gray-light, 2%);
      border: 1px solid darken($gray-light, 20%);
      border-radius: 4px;
      cursor: default;
      float: left;
      margin-right: 5px;
      margin-top: 5px;
      padding: 0 5px;
    }

    .select2-selection__choice__remove {
      color: $gray-medium;
      cursor: pointer;
      display: inline-block;
      font-weight: bold;
      margin-right: 2px;

      &:hover {
        color: lighten($gray-dark, 6%);
      }
    }
  }

  &[dir="rtl"] .select2-selection--multiple {
    .select2-selection__choice {
      float: right;
      margin-left: 5px;
      margin-right: auto;
    }

    .select2-selection__choice__remove {
      margin-left: 2px;
      margin-right: auto;
    }
  }

  &.select2-container--open {
    .select2-selection--multiple {
      border: 1px solid $mlh-blue;
    }

    &.select2-container--above .select2-selection--multiple {
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &.select2-container--below .select2-selection--multiple {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .select2-search--dropdown .select2-search__field {
    border: 1px solid darken($gray-light, 20%);
    outline: 0;
  }

  .select2-search--inline .select2-search__field {
    outline: 0;
  }

  .select2-dropdown {
    background-color: $white;
    border: 1px solid transparent;
  }

  .select2-dropdown--above {
    border-bottom: none;
  }

  .select2-dropdown--below {
    border-top: none;
  }

  .select2-results > .select2-results__options {
    max-height: 200px;
    overflow-y: auto;
  }

  .select2-results__option {

    &[role=group] {
      padding: 0;
    }

    &[aria-disabled=true] {
      color: $gray-medium;
    }
  }

  .select2-results__option--highlighted[aria-selected] {
    background-color: $mlh-blue;
    color: $white;
  }

  .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
  }

  &.select2-container--open .select2-dropdown {
    border-color: $mlh-blue;
  }
}
