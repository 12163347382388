﻿/*.iframe-container {
  overflow: hidden;
  padding-top: 50%;
  position: relative;
}

.iframe-container iframe {
   border: 0;
   height:500px;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
}

/* 4x3 Aspect Ratio */
/*
.iframe-container-4x3 {
  padding-top: 75%;
}*/

.iframe-container
{
    width: 100%;

    iframe {
        width: 100%;
        min-width: 100%;
        height: 1370px;
        border: none;

        @include media($x-small-min)
        {
            height: 1100px;
        }

        @include media($small-min)
        {
            height: 1275px;
        }

        @include media($medium-min)
        {
            height: 1150px;
        }

        @include media($large-min)
        {
            height: 1075px;
        }
    }
}