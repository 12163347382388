/**
 * @file
 * Layout rules for 2-up content
 */

// =============================================================================
// Ruleset for content going from 1-column at mobile,
// to 2-column as horizontal space allows
// $omega-gutter: "false" = will wrap an item
// $omega-gutter: "true"  = will not wrap an item
// =============================================================================

// .l-2up {
//   float: left;
//   @include zen-grid-item-base();
//   @include zen-grid-flow-item(12, 12, $omega-gutter: false);
//   @include media($medium-min){
//     @include zen-grid-flow-item(6, 12, $omega-gutter: true);
//   }
// }
// // make every 2nd list item wrap
// .l-2up:nth-of-type(2n) {
//   @include media($medium-min){
//     @include zen-grid-flow-item(6, 12, $omega-gutter: false);
//   }
// }
// // clears a new row from the tallest element in the row above
// .l-2up:nth-of-type(2n+1) {
//   @include media($medium-min){
//     @include zen-clear();
//   }
// }

// // =============================================================================
// // Basic 2-up layouts
// // =============================================================================

.l-2up--1 {
  clear: both;

  @include media($x-small-min) {
    @include isolation-span(6, 1, 'both');
  }
}

.l-2up--2 {
  clear: both;

  @include media($x-small-min) {
    @include isolation-span(6, 7);
  }
}
