/**
 * @file
 * Callouts
 */

//////////////////////////////////////////
// Callout: Item styles
//////////////////////////////////////////

.callout {
	border-bottom: 1px solid $gray-light;
	text-align: center;
	padding-bottom: rhythm(2);
	margin-bottom: rhythm(2);


	p {
		// Reduce size of font at breakpoint
		@include media($x-small-min) {
			font-size: .9em;
		}
	}

	// Remove border styles on last item
	&.callout--last {
		border: 0;
		padding-bottom: 0;
		margin-bottom: 0;
	}

	// Swich to 12 column grid at bp2
	@include media($x-small-min) {
		@include grid-span(4, 1, $gutter: 0, $output-style: 'float');
		border: 0;
		border-right: 1px solid $gray-light;
		padding: 0 rhythm(2);
		margin-bottom: 0;

		// Remove border for last child
		&.callout--last {
			border-right: 0;
		}
	}
}

//////////////////////////////////////////
// Callouts: Tab notch style with bar
//////////////////////////////////////////

.home {
	.callouts-component {
		border-bottom: 1px solid darken($off-white, 10%);

		.container {
			@include media($x-small-min) {
				@include branding-bar;
				position: relative;
				background-color: $off-white;
				padding-top: rhythm(2);
				margin-top: rhythm(-5);
				z-index: 1;

				@include media($medium-min) {
					padding-top: rhythm(4);
					margin-top: rhythm(-7);
				}
			}
		}
	}
}




//////////////////////////////////////////
// Callout: Icon
//////////////////////////////////////////

.callout__icon {
	width: 42px;
	height: 42px;
	display: inline-block;
	margin: 0 rhythm(.3);
}




//////////////////////////////////////////
// Callout: Title style overrides
//////////////////////////////////////////

.callout__title {
	@include adjust-font-size-to($h3-font-size, 2);
	@include leader(1, $h3-font-size);
	@include trailer(1, $h3-font-size);
	@include sans-cond();
}





