﻿
.nobg-modal {
    display: none;
    background-color: #fefefe;
    margin: auto;
    padding: 5px;
    border: 1px solid #888;
    width: 100%;
    height: 90%;
    position: fixed;
    z-index: 2000;
    overflow: hidden;
    bottom: 0px;

    #btnClose {
        margin-bottom: 15px;
    }
}

#healthbotIframe {
    width: 100%;
    height: 83vh;
    display: block;
}


@media (min-width: 640px) {
    .nobg-modal {
        display: none;
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width:320px;
        height: 45vh;
        position: fixed;
        z-index: 2000;
        overflow: hidden;
        bottom: 20px;
        right: 20px;
        #btnClose {
            margin-bottom: 15px;
        }
    }
    #healthbotIframe {
        width: 100%;
        height: 45vh;
        display: block;
    }
}

@media (min-width: 1000px) {
    .nobg-modal {
        display: none;
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 620px;
        height: 70vh;
        position: fixed;
        z-index: 2000;
        overflow: hidden;
        bottom: 20px;
        right: 20px;

        #btnClose {
            margin-bottom: 15px;
        }
    }

    #healthbotIframe {
        width: 100%;
        height: 63vh;
        display: block;
    }
}

