// /**
//  * @file
//  * Filters
//  */

//////////////////////////////////////////
// Filters: Browser by letter
//////////////////////////////////////////

.filter__browse {
    @extend %no-bullets;

    
    // Inline letter list
    li {
        display: inline-block;
        width: 1.5em;
        font-size: 1.1em;
        font-weight: 700;
        text-align: center;
        margin-bottom: rhythm(.25);
        margin-right: rhythm(.25);
    }

    a {
        display: block;
        background-color: $off-white;
        border-radius: 3px;

        &.is-active,
        &:hover {
            background-color: $mlh-blue;
            color: $white;
        }
    }
}

.form-view-filters {
  text-align: center;
}
