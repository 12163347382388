﻿/* new ui - tabs */
.epic--openScheduler-toggle-formType li span.fa {
    color: #a6ce39;
}
.epic--openScheduler-toggle-formType li:not(.current) span.fa {
    display: none;
    color: #a6ce39;
}

.epic--openscheduler_newpatient_wrapper
{
    @extend %clearfix;
}

.epic--openScheduler_form{
    
    .step-item
    {
        padding: 10px 10px 10px 20px;
       
        span
        {
            vertical-align:middle;
            min-height: 30px;

            &.step-icon
            {
                color: $gray-light;

                 &.isActive
                 {
                     color: $mlh-orange;
                 }

                 &.isComplete
                 {
                     color: $mlh-green;
                 }
            }

            &.step-text
            {
                 font-weight: bold;
                 cursor:pointer;
            }
        }
    }

    .step-item-nav
    {
        min-height: 30px;
        vertical-align: middle;
        margin-top: 15px;

    }

    .step-item-data
    {
        &.panel
        {
            padding: 20px;
            display:none;

            &.isActive
            {
                display:block;
            }
        }

        .step-item-ui
        {
            width: 100%;
            padding: 15px;
        }

    }

    .js-nextStepEpicForm
    {
        span
        {
            vertical-align: middle;
        }
    }

}


.calendarNavWrapper
{
    padding: 10px 0px;

    > div
    {
        display: inline-block;
        width: auto;

        button.button--white[disabled="disabled"]
        {
            background-color: #dfdfdf !important;

            &:hover
            {
                background-color: #dfdfdf !important;
            }
        }
    }
}

.dateRange
{
    font-weight: bold;
    padding: 0px 10px;
}

.calendarContentWrapper {
    width: 100%;
    background-color: #fff;
    padding: 15px 15px 30px 15px;
    vertical-align: middle;

    .calendarContent {
        display: block;

        .appointmentCalendar {
            display: table;
            width: 100%;

            .row {
                display: flex;
                padding: 12px 0px;

                .dateColumn, .timeColumn {
                }

                .dateColumn {
                    width: 100px;
                    min-width: 100px;

                    .dayOfWeek {
                        font-weight: bold;
                    }
                }

                .timeColumn {
                    display: flex;

                    .bx-wrapper {
                        max-width: 100% !important;
                    }

                    .bx-viewport {
                        padding: 0px 10px;
                        max-width: 100% !important;
                        border-left: solid 1px #808080;
                        border-right: solid 1px #808080;
                    }

                    .slots {
                        border-left: solid 1px #808080;
                        list-style-type: none;
                        margin: 0px;
                        padding: 0px;

                        .slot {
                            display: inline-block;
                            font-size: 0.85em;
                            font-weight: bold;

                            .switch {
                                min-width: 80px;

                                .cmn-toggle {
                                    position: absolute;
                                    margin-left: -9999px;
                                    visibility: hidden;
                                }

                                .cmn-toggle + label {
                                    display: block;
                                    cursor: pointer;
                                    outline: none;
                                    -webkit-user-select: none;
                                    -moz-user-select: none;
                                    -ms-user-select: none;
                                    user-select: none;
                                }

                                input.cmn-toggle-yes-no + label {
                                    padding: 10px;
                                }

                                input.cmn-toggle-yes-no + label:before, input.cmn-toggle-yes-no + label:after {
                                    display: block;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    bottom: 0;
                                    right: 0;
                                    color: #fff;
                                    vertical-align: middle;
                                    text-align: center;
                                    font-weight: bold;
                                    line-height: 45px;
                                    border-radius: 7px;
                                }

                                input.cmn-toggle-yes-no + label:before {
                                    background-color: $mlh-blue;
                                    content: attr(data-off);
                                    -webkit-transition: -webkit-transform 0.5s;
                                    -moz-transition: -moz-transform 0.5s;
                                    -o-transition: -o-transform 0.5s;
                                    transition: transform 0.5s;
                                    -webkit-backface-visibility: hidden;
                                    -moz-backface-visibility: hidden;
                                    -ms-backface-visibility: hidden;
                                    -o-backface-visibility: hidden;
                                    backface-visibility: hidden;
                                }

                                input.cmn-toggle-yes-no + label:after {
                                    background-color: $mlh-green;
                                    content: attr(data-on);
                                    -webkit-transition: -webkit-transform 0.5s;
                                    -moz-transition: -moz-transform 0.5s;
                                    -o-transition: -o-transform 0.5s;
                                    transition: transform 0.5s;
                                    -webkit-transform: rotateY(180deg);
                                    -moz-transform: rotateY(180deg);
                                    -ms-transform: rotateY(180deg);
                                    -o-transform: rotateY(180deg);
                                    transform: rotateY(180deg);
                                    -webkit-backface-visibility: hidden;
                                    -moz-backface-visibility: hidden;
                                    -ms-backface-visibility: hidden;
                                    -o-backface-visibility: hidden;
                                    backface-visibility: hidden;
                                }

                                input.cmn-toggle-yes-no:checked + label:before {
                                    -webkit-transform: rotateY(180deg);
                                    -moz-transform: rotateY(180deg);
                                    -ms-transform: rotateY(180deg);
                                    -o-transform: rotateY(180deg);
                                    transform: rotateY(180deg);
                                }

                                input.cmn-toggle-yes-no:checked + label:after {
                                    -webkit-transform: rotateY(0);
                                    -moz-transform: rotateY(0);
                                    -ms-transform: rotateY(0);
                                    -o-transform: rotateY(0);
                                    transform: rotateY(0);
                                }
                            }
                        }
                    }

                    .noneAvailable {
                        margin-left: 30px;
                    }
                }
            }
        }

        .form-group {
            selectBox {
                font-size: 1em;
            }

            .combodate {
                .combodate-day {
                    width: 75px !important;
                }

                .combodate-month {
                    width: 75px !important;
                }

                .combodate-year {
                    width: 100px !important;
                }
            }

            .radio-group.pillStyle input[type="radio"] {
                position: static !important;
                display: none !important;
            }

            .form-inline_group50 {
                width: 49%;
                display: inline-block;
            }

            &.form-inline {
                border-bottom: none;
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
    }
   
    .xhrStatusWindow {
        background-color: white;
        text-align: center;
        min-height: 170px;
        padding: 40px;

        .xhrStatus--passed, .xhrStatus--failed {
            margin: 50px;
        }
    }
}

td.confirmationTableCol1 {
    width: 35%;
}

td.confirmationTableCol2 {
    width: 65%;
}


.NextAvailableForm {
    .buttonArea {
        display: flex;
        margin: 10px 0px;

        .section1 {
            margin-right: 1.5em;
        }

        .section2 {
            font-size: 0.8em;
            margin: 0px 1.5em;
        }
    }
}
