﻿.l-block--e7eff2
{
    background-color: #e7eff2;
}

.expansion
{
    padding: 0em;
}

/* feature list */
/****************/

.feature-list
{
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    align-items: flex-start;
}

.feature-item
{
    width: 100%;
    padding: 1em;
    flex-grow: 1;

    @include media($small-min) {
        width: 49%;
    }
    @include media($large-min) {
        width: 32%;
    }
}



/****************/

.feature-thumb, .feature-details
{
    display: inline-block;
}

.feature-thumb
{
    width: 90px;
    vertical-align: top;
    padding-top: 20px;
    padding-right: 10px;
}

.feature-thumb > img
{
    width: 100%;
}

.feature-details
{
    width: calc(100% - 100px);
}

.feature-title > h4
{
    font-size: 1.25em;
    line-height: 1.3em;
    margin-top: 0.65em;
    margin-bottom: 0.65em;
    font-family: "Roboto", "Arial Narrow", Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-style: normal;
}

.feature-summary > p
{
    font-size: 0.9em;
    line-height: 1.3em;
}