﻿//////////////////////////////////////////
// Notification Bar
//////////////////////////////////////////

.notification-banner {
    margin-top: 0px !important;
    width: 100%;
    padding:12px 40px 12px 12px;
}


.notification-banner__close {
    background: rgba(255,255,255,0.1);
    color: #FFFFFF;
    cursor: pointer;
    font-size: 30px;
    font-weight: normal;
    height: 22px;
    line-height: 24px;
    position: absolute;
    right: 11px;
    text-align: center;
    top: 9px;
    -webkit-transition: color 0.2s ease-in-out;
    -moz-transition: color 0.2s ease-in-out;
    -o-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
}
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 3000; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
.modal-notification-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 60%;
    position: absolute;
    bottom: 12px;
    left: 0;
    right: 0;
}
