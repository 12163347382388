/**
 * @file
 * Define print styles here.
 */

@media print {
  html {
    font-size: 12px !important;
    text-align: left !important;
  }

  header[role="banner"],
  footer[role="contentinfo"],
  iframe,
  //.splash__image,
  .fluid-width-video-wrapper,
  .list__hero-image,
  .health-system,
  .spotlight,
  .location--nav,
  .share,
  .splash__content__button,
  .view-offices {
    display: none !important;
  }

  .tab-container,
  .profile-offices__all {
    display: block !important;
  }

  .splash,
  .splash__content, 
  .list__item {
    min-height: 0 !important;
    height: auto !important;
    padding: 0 !important;
  }

  .profile-offices {
    padding: 0;
  }

  /* format doctor's profile page for print */

  /* map */
  
  html > body:not(.doctor) {
      aside[role="complementary"] {
        display: none !important;
      }
  }

  html > body.doctor {

      width: 1000px;
      margin: 0 auto;

      main {
          float:left;
          width: 650px;
      }

      aside[role="complementary"] {
          display: block !important;
          float:right;
          margin-bottom: 20px;
          width: 320px;
      }

      div.profile-image .button {
          display: none;
      }

      .profile-image {
          float:left;
      }

      .profile-intro {
       margin-left: 320px;
      }

      div.profile-intro:nth-child(0) {
          display: none !important;
      }

      .splash__title
      {
          font-size: 4em !important;
          padding: 20px 0px;
      }

      .tab-left {
          float: left;
           width: 650px;
      }

      .tab-right {
          width: 320px;
          float: right;
      }

      .profile-office__item
      {
          padding: 10px;
      }

  }
  
}
