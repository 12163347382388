﻿/**
 * @file
 * ClinicalTrial Profile
 */

.clinicaltrial
{
	.l-block--main
    {
		.l-content 
        {
			width:100%;
			margin-bottom: 0;
		}
	}
}



