﻿//////////////////////////////////////////
// Body Related Events
//////////////////////////////////////////

.event-component {
    background-color: #00739d;
    /*display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    justify-content:space-around;*/
}

.event-component__title {
    color: white;
    font-size: 1.1em;
    padding: 20px;
    display: block;
}

.event-component__listItems {
    list-style-type: none;
    display: flex;
    /*flex-basis:200px;*/
    flex-wrap:wrap;
    justify-content: space-around;
    padding: 10px;
    font-size:0.9em;

}
//setting up styles for mobile view
// set max-width to mobile breakpoint variable
@media screen and (max-width: 600px) {
    .event-component__listItem {
        margin: 20px 0;
    }
}
.event-component__listItem {
    padding: 10px;
    display: flex;
    flex-basis: 270px;
    flex-direction: row;
    background-color: white;
}

.event-component__listItemsDate {
    padding: 10px;
    display: flex;
    flex-direction: row;
    font-weight:bold;
}

.event-component__listItemTitle {
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    color: #00739d;
    font-weight: bold;
}
.event-component__listItemDetails {
    position:relative;
    display: flex;
    padding: 5px;
    flex-direction: column;
}



.event-component__RegisterLink {
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 0.7em;
    position: absolute;
    bottom:0;
    background: #00739d;
    border: 1px solid #00739d;
    border-radius: 6px;
    display: inline-block;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    -webkit-transition: all .3s;
    transition: all .3s;
    vertical-align: middle;
    padding: 0.8125em 1.21875em 0.65em;
    margin-top: 0;
   
}


.event-component__ViewAllLink {
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 0.9em;
    position: relative;
    color: #ffffff;
    vertical-align: middle;
    margin: 25px;

    &:hover {
        color: black;
    }

}



