/**
 * @file
 * Related box
 */

//////////////////////////////////////////
// Related Box: Default
//////////////////////////////////////////

%related-box,
.related-box {
  background-color: $off-white;
  box-shadow: 0 1px 1px rgba($black, .15);
  margin: rhythm(2) auto;
  max-width: 1300px;
  padding: rhythm(2) rhythm(1);
  opacity: 1;
  transition: opacity .8s;

  @include media($x-small-min) {
    padding: rhythm(3);
  }

  &:first-child {
	margin-top:0;
  }

  &:last-child {
	margin-bottom:0;
  }

  .l-sidebar & {
	  @include media($x-small-min) {
		  background-color: transparent;
		  box-shadow: none;
		  padding: 0;
	  }
  }
  .block__button {
    text-align: center;
    margin-top: 1.625em;

    .button {
        margin-bottom: 0;
    }

    @include media($x-small-min) {
        .l-sidebar & {
            display: none;
        }
    }
  }

  .is-hidden & {
    opacity: 0;
  }
}

.related-box--white {
  @extend %related-box;
  background-color: $white;
}


//////////////////////////////////////////
// Related Box: Header and title
//////////////////////////////////////////

.related-box__header {
  position: relative;
  text-align: center;

  .link {
    display: none;
  }

  @include media($x-small-min) {
    .l-sidebar & {
      margin-bottom: rhythm(1.5);
      text-align: left;

      .link {
        display:block;
        position: absolute;
        top: .25em;
        right: 0;
      }
    }
  }
}

%related-box__title,
.related-box__title {
  @include adjust-font-size-to($text, 2);
  @include leader(0, $text);
  @include trailer(1, $text);
  display: inline-block;
  background: url('/assets/images/svg/ico--related.svg') no-repeat left center;
  text-transform: uppercase;
  text-align: center;
  padding-left: rhythm(2);

  .no-svg & {
    background: url('/assets/images/png/ico--related.png') no-repeat left center;
  }

  @include media($x-small-min) {
    .l-sidebar & {
      @include adjust-font-size-to($text, 1.4);
      @include leader(0, $text);
      @include trailer(0, $text);
      text-transform: uppercase;
      color: $mlh-orange;
      padding-right: 4.5em;
      background: none;
      padding-left: 0;
      text-align: left;
    }
  }
}

.related-box__title--no-icon {
  @extend %related-box__title;
  background: none;

  .no-svg & {
    background: none;
  }
}



//////////////////////////////////////////
// Related Box: List styles
//////////////////////////////////////////

%related-box__list,
.related-box__list {
  @extend %no-bullets;
  font-size: .9em;
  line-height: 1.3em;

  @include media($x-small-min) {
    column-count: 2;

    .l-sidebar & {
      column-count: 1;
      font-size: 1em;
    }
  }
  @include media($medium-min) {
    column-count: 1;
  }

  @include media($large-min) {
    column-count: 2;
  }

  li {
    break-inside: avoid-column;
    break-after: auto;
    border-bottom: 1px dashed $gray-light;

    @include media($x-small-max) {
      &:last-child {
        border-bottom:none;
      }
    }

    @include media($x-small-min) {
      .l-sidebar & {
        position: relative;
        border-bottom: 1px solid $gray-light;
        padding-bottom: rhythm(.75);
        margin-bottom: rhythm(.75);

        &:nth-child(4) {
          border-bottom:none;
        }

        &:nth-child(n+5) {
          display:none;
        }
      }
    }

  }

  a {
    @include sans-gotham();
    display: block;
    padding: rhythm(1) rhythm(.5);

    &:hover {
      background-color: $white;
    }

    @include media($x-small-min) {
      .l-sidebar & {
        @include adjust-font-size-to($h5-font-size, 1.5);
        color: $mlh-gray-dark;
        background-color: transparent;
        font-weight: 700;
        padding: 0;

        &:hover {
          color: $mlh-blue;
        }
      }
    }
  }
}

.related-box__list[data-itemCount="1"]
{
    column-count: 1 !important;
}


.related-box__list--full {
  @extend %related-box__list;

  @include media($x-small-min) {
    column-count: 1;
  }

  @include media($small-max) {
    li:last-child,
    li:nth-child(8n) {
      border-bottom: none;
    }
  }

  @include media($small-min) {
    column-count: 2;
  }

  @include media($large-max) {
    & li:nth-child(n+9){
      display:none;
    }
  }

  @include media($large-min) {
    column-count: 3;
  }
}
