// /**
//  * @file
//  * Appointment Form
//  */

.appointment-form {
    &__page {
        padding: 1px 0; // allow space for error outlines.
    }
    &__fail-msg,
    &__success-msg {
        display:none;
        margin-bottom: 1.625em;
    }
    &__fail-msg {
        color: $input-invalid-text;
    }
    &__success-msg {
		text-align: center;
		
		.page-content {
            margin-bottom: 1em;
			text-align: left;
        }
    }

}
//////////////////////////////////////////
// Forms: Appointment styles
//////////////////////////////////////////

.appointment-top {

  @include media($x-small-min) {
    background-color: $off-white;
    border-bottom: 1px solid darken($off-white, 10%);
  }
}

// Intro copy
.appointment-intro {
  @include media($medium-min) {
    @include grid-span(8, 1);
  }
}

// Appointment contact block
.appointment-contact {
  @include sans-gotham();
  text-align: center;

  @include media($medium-min) {
    @include grid-span(4, 9);
  }

  span {
    display: block;
  }

  .phone-num {
    @include adjust-font-size-to($h2-font-size, 2);
    @include leader(1, $h2-font-size);
    @include trailer(0, $h2-font-size);
  }
}




//////////////////////////////////////////
// Forms: Appointment step nav
//////////////////////////////////////////

.appointment-nav {
  text-align: center;
  margin-bottom: rhythm(3);
  clear: both;

  @include media($medium-min) {
    margin-bottom: rhythm(5);
  }
}




//////////////////////////////////////////
// Forms: Appointment form styles
//////////////////////////////////////////

.appointment__nav__steps {
  @extend %no-bullets;
  display: inline-block;
  text-align: center;

  &:before {
    display: block;
    content: '';
    position: relative;
    top: 31px;
    border-top: 1px solid $gray-light;
    margin: 0 30px;
    z-index: 0;
  }

  li {
    @include slab();
    cursor: pointer;
    display: inline-block;
    font-size: .8em;
    line-height: 1.2;
    margin: 0 rhythm(1);
    position: relative;
    text-align: center;
    transition: all .3s ease;
    vertical-align: top;
    width: 80px;

    &:first-child,
    &:last-child {
      margin: 0;
    }

    &:hover {

      .step {
        background-color: darken($mlh-blue-gray, 5%);
      }
    }

    &.is-active {

      .step {
        background-color: $mlh-blue;
        color: $white;
      }
    }


    @include media($x-small-min) {
      font-size: 1em;
      width: 100px;
      margin: 0 rhythm(8);
    }

    @include media($medium-min) {
      margin: 0 rhythm(14);
    }

    @include media($large-min) {
      margin: 0 rhythm(20);
    }
  }

  .step {
    @include sans-gotham();
    transition: all .3s ease;
    display: inline-block;
    background-color: $mlh-blue-gray;
    border: 3px solid $white;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    line-height: 54px;
    font-size: 1.5em;
  }
}




//////////////////////////////////////////
// Forms: Appointment button nav
//////////////////////////////////////////

.appointment__buttons {
  @extend %clearfix;

  .button {
    display: block;
    margin: rhythm(1) 0;

    @include media($xx-small-min) {
      display: inline-block;
      margin: 0;
    }
  }

  .appointment__buttons-prev {

    @include media($xx-small-min) {
      float: left;
    }
  }

  .appointment__buttons-next,
  .appointment__buttons-submit {
    position: relative;

    @include media($xx-small-min) {
      float: right;
    }
  }

  .appointment__buttons-submit {
    display: none;

    &.is-active {
      display: block;
    }
    .spinner {
        display: none;
    }
  }
}

//////////////////////////////////////////
// Forms: Epic Doctor Appointment Form
//////////////////////////////////////////

.epic-toggle-a-tag{
    font-size: 1.2em;
    color: #000000;
}


.js-multipage-form-js-fail-msg {
    display: none;
}

.appointment-form__js-fail-msg {
    color: $input-invalid-text;
}

//////////////////////////////////////////
// Forms: Vaccination Form
//////////////////////////////////////////

section, .form-section {
    font-family: "Montserrat",Arial,Helvetica,sans-serif;

    &.hidden {
        display: none;
    }

    &.shown {
        display: block;
    }
}

#landingPage {
    .landingPageImage {
        display: none;

        @include media($small-min) {
            text-align: center;
            margin-top: 30px;
            display: block;
        }
    }
}

#vaccinationAppointmentForm {

    #personal-information {
        label span.required {
            color: red;
        }
    }

    .form-section {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .slots-row-header {
        font-family: "Montserrat",Arial,Helvetica,sans-serif;
        font-size: 1.2em;
        font-weight: bold;
        margin: 10px 0px;
    }

    .slots-row {
        display: block;
    }

    @include media($xx-small-min) {
        .slots-row {
            display: flex;
        }
    }

    .slots-item {
        margin-right: 5px;
    }

    .appt-toggleButton {
        background-color: #00739d;
        color: #fff;
        font-weight: bold;
        font-size: 0.8em;
        cursor: pointer;

        > input {
            position: absolute;
            visibility: hidden;

            + span {
                padding: 7px;
            }

            &:checked + span {
                background-color: #a6ce39;
                color: #333;
            }
        }
    }

    @include media($small-min) {
        .appt-toggleButton {
            font-size: 1.1em;
        }

        .slots-item {
            margin-right: 15px;
        }
    }

    .timeSlotArea.scrollable {
        height: 300px;
        overflow-y: auto;
        box-shadow: 0 3px 8px -6px rgba(0,0,0,.50);
    }

    .dateCols {
        display: flex;
        justify-content: space-between;

        > div {
            order: 1;
        }

        div.date-col {
            width: 99%;
        }

        div.month-col-prev, div.month-col-next {
            white-space: nowrap;
            width: 190px;
        }

        div.month-col-next {
            text-align: right;
        }
    }

    .js-btn-month-nav {
        width: 90px;
        height: 43.594px;
    }
}

.appt-result-date {
    font-weight: bold;
}

.appt-result-arriveTime {
    font-weight: bold;
}

span.required
{
    color:red;
}