﻿.component-filterHTMLTable {
    /* filter UI*/
    .filterBox {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        .filterLabel {
            width: 20%;
        }

        .filterForm {
            width: 80%;

            #selectBox0 {
                display: flex;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;

                #inputSearchBox {
                    background-image: url('/assets/images/htmlTables/searchicon.png');
                    background-position: 10px 10px;
                    background-repeat: no-repeat;
                    padding: 12px 20px 12px 40px;
                }

                .selectBoxArrow {
                    margin-left: 10px;
                    cursor: pointer;
                }
                /* editable select */
                .selectBoxOptionContainer {
                    background-color: white;
                    border: solid 1px black;
                    position: absolute;
                    z-index: 90;
                    padding: 10px;
                    top: 45px;
                }
            }
        }
    }

    #descriptionBox {
        .descriptionText {
            padding: 1em;
            border-radius: 10px;
            background-color: #009fd926;
            margin-bottom: 1em;
        }

        .hide {
            display: none;
        }
    }

    table#resultsTable {
        border: 1px solid #ccc;
        border-collapse: collapse;
        margin: 0;
        padding: 0;
        width: 100%;
        /*table-layout: fixed;*/
    }

    table#resultsTable tr {
        background-color: #f8f8f8;
        border: none;
        padding: .35em;
    }

    table#resultsTable th,
    table#resultsTable td {
        padding: .625em;
    }

    table#resultsTable th {
        font-size: .85em;
        letter-spacing: .1em;
        text-transform: uppercase;
        background-color: #009fd9;
    }

    @media screen and (max-width: 1230px) {
        tbody td {
            border: none;
        }

        table#resultsTable thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        table#resultsTable tr {
            border-bottom: 3px solid #ddd;
            display: block;
        }

        table#resultsTable td {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: .8em;
            text-align: left;
        }

        table#resultsTable td::before {
            /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
            content: attr(data-label);
            margin-right: 10px;
            font-weight: bold;
            text-transform: uppercase;
        }

        table#resultsTable td:last-child {
            border-bottom: 0;
        }
    }
}
