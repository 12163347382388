// Grid breakpoints

$grid-breakpoints: ( xs: 0, sm: 480px, md: 640px, lg: 960px, xl: 1440px );
// spacing
$spacing-x-1: 0.625rem;
$spacing-x-2: 1.25rem;
$spacing-x-3: 3 * $spacing-x-1;
$spacing-x-4: 4 * $spacing-x-1;
$spacing-x-5: 5 * $spacing-x-1;
$spacing-x-6: 6 * $spacing-x-1;
$spacing-x-7: 7 * $spacing-x-1;
$spacing-x-8: 8 * $spacing-x-1;

$spacing-y-1: 0.625rem;
$spacing-y-2: 1.25rem;
$spacing-y-3: 3 * $spacing-y-1;
$spacing-y-4: 4 * $spacing-y-1;
$spacing-y-5: 5 * $spacing-y-1;
$spacing-y-6: 6 * $spacing-y-1;
$spacing-y-7: 7 * $spacing-y-1;
$spacing-y-8: 8 * $spacing-y-1;


.list__item--doctor {
    .nrcRating {

        .starsArea {
            padding-top: $spacing-y-1;
            padding-bottom: $spacing-y-1;
            padding-right: $spacing-y-1;
            display: inline-flex;
        }

        .ds-stars {
            $size: 0.75rem;

            width: ($size * 5);
            height: $size;
            background-image: url("../../assets/theme2/assets/img/star-ratings.svg");
            background-position: 0 0;
            background-repeat: repeat-x;
            background-size: ($size * 5) ($size * 11);

            @for $i from 1 through 5 {
                &#{$i - 1}5 {
                    background-position: 0 ($size * ($i * -2 + 1));
                }

                &#{$i} {
                    background-position: 0 ($size * ($i * -2));
                }
            }
        }

        .nrc-xofy,
        .nrc-ratings,
        .nrc-comments {
            display: inline;
            font-size: 0.875rem;
        }

        .nrc-xofy {
            &:after {
                content: " ";
            }
        }

        .nrc-ratings {
            &:before {
                content: "(";
            }

            &:after {
                content: ", ";
            }

            &:last-child {
                &:after {
                    content: ")";
                }
            }
        }

        .nrc-comments {
            &:after {
                content: ")";
            }
        }

        .nrc-average-max {
            margin: 0;
            color: inherit;
            font-weight: inherit;

            &:before {
                content: " ";
            }
        }
    }
}


