@import "bourbon";
@import "neat";
@import "vendors/font-awesome";

//
// Initialization partial
//
// To make it easier to use all variables and mixins in any Sass file in this
// theme, each .scss file has a @import "init" declaration. And this _init.scss
// file is in charge of importing all the other partials needed for the theme.
//
// This initialization partial is organized in this way:
// - First we set any shared Sass variables.
// - Next we import Compass plug-ins (Sass mixin libraries).
// - Last we define our custom mixins for this theme.
//


// =============================================================================
// Variables
// =============================================================================

//
// Browser support
//
// Based on caniuse.com's browser usage stats, Compass will support any browser
// that is used by at least 1 user in 10,000. These variables are used to
// specify which browsers are supported by Compass. "Unsupported" browsers will
// still see the generated CSS, of course, but will not get special treatment
// for its bugs or CSS3 vendor prefixes.
//

// The browser usage threshold for features that cannot degrade gracefully.
// A value of 100 means 100% of users would have to use the browser before it is
// supported. A value of .01 (the default) means 1 user in 10,000.
$critical-usage-threshold: 100 !default;

// Set this to true to generate comments that will explain why a prefix was
// included or omitted.
$debug-browser-support: false;

// Optional map of minimum browser versions to support. If a specific browser
// has usage stats below the $critical-usage-threshold, you can still require
// support for it using this variable. For example, to require support for IE 6
// and later, set $browser-minimum-versions['ie'] to '6'.
$browser-minimum-versions: (
  'chrome':  '39',
  'firefox': '34',
  'ie':      '9',
  'safari':  '8',
  'opera':   '24'
) !default;


//
// Font faces, stacks and sizes.
//

// Compass' vertical_rhythm extension is a powerful tool to set up a vertical
// rhythm for your entire page. You can see some of its mixins and functions in
// use in the normalize.scss file.
// @see http://compass-style.org/reference/compass/typography/vertical_rhythm/

$base-font-size: 16px; // The font size set on the root html element.
$base-line-height: 13px; // This line-height determines the basic unit of vertical rhythm.

// Modular Scale
// Major Third: 16px @ 1:1.25
$xxxl-heading: 3.4 * $base-font-size;
$xxl-heading:  3.05 * $base-font-size;
$xl-heading:  2.44 * $base-font-size;
$lg-heading:  1.95 * $base-font-size;
$md-heading:  1.56 * $base-font-size;
$sm-heading:  1.25 * $base-font-size;
$xsm-heading: 1.15 * $base-font-size;
$lede:        1.1 * $base-font-size;
$text:        1 * $base-font-size;
$label:       .9 * $base-font-size;
$nav:         .8 * $base-font-size;
$meta:        .725 * $base-font-size;
$meta-sm:     .65 * $base-font-size;
$button:      .7 * $base-font-size;
$crumb:       .6 * $base-font-size;

$h1-font-size: $lg-heading;
$h2-font-size: $md-heading;
$h3-font-size: $sm-heading;
$h4-font-size: $xsm-heading;
$h5-font-size: $text;
$h6-font-size: $label;

// The following font family declarations are based on the Microsoft core web
// fonts which are common fonts available on most computer systems. The DejaVu
// and Nimbus Sans fonts are commonly available on Linux systems where the MS
// fonts are less common. Tahoma and Helvetica are also widely available.
//
// A user's web browser will look at the comma-separated list and will
// attempt to use each font in turn until it finds one that is available
// on the user's computer. The final "generic" font (sans-serif, serif or
// monospace) hints at what type of font to use if the web browser doesn't
// find any of the fonts in the list.

// First, let's create some font stacks.
$times-new-roman: 'Times New Roman', Times, Georgia, 'DejaVu Serif', serif;
$times:           Times, 'Times New Roman', Georgia, 'DejaVu Serif', serif;
$georgia:         Georgia, 'Times New Roman', 'DejaVu Serif', serif;
$verdana:         Verdana, Tahoma, 'DejaVu Sans', sans-serif;
$tahoma:          Tahoma, Verdana, 'DejaVu Sans', sans-serif;
$helvetica:       Helvetica, Arial, 'Nimbus Sans L', sans-serif;
$arial:           Arial, Helvetica, 'Nimbus Sans L', sans-serif;
$arvo:            'Arvo', 'Times New Roman', Times, Georgia, 'DejaVu Serif', serif;
$montserrat:      'Montserrat', Arial, Helvetica, sans-serif;
$roboto:          'Roboto', 'Arial Narrow', Arial, Helvetica, sans-serif;

// For an explanation of why "sans-serif" is at the end of this list, see
// http://meyerweb.com/eric/thoughts/2010/02/12/fixed-monospace-sizing/
$courier:         'Courier New', 'DejaVu Sans Mono', monospace, sans-serif;

// Now create some variables for the font stacks we want to use on this site.
$base-font-family: $arial; // The font family set on the html element.
$font-monospace:   $courier;

// Vertical spacing unit
$slug: rhythm(8);

// The amount lists, blockquotes and comments are indented.
$indent-amount: rhythm(2);

// Hover state effects
$timing: .3s;
$easing: ease;


// =============================================================================
// Partials to be shared with all .scss files.
// =============================================================================

// Add Compass' IE and vendor prefix support variables.
@import 'vendors/compass/support';
// Better than Drupal's clearfix.
@import 'vendors/compass/utilities/general/clearfix';
// See http://compass-style.org/help/tutorials/spriting/
@import 'vendors/compass/utilities/sprites';
// Use one CSS3 mixin instead of multiple vendor prefixes.
@import 'vendors/compass/css3';
// Helps set up a vertical rhythm.
@import 'vendors/compass/typography/vertical_rhythm';
// Add Toolkit: Swiss army knife for modern design and dev.
@import 'vendors/toolkit';
@import 'vendors/toolkit/kickstart';
// Add the Breakpoint mixin.
@import 'vendors/breakpoint';
// Add the Singularity gs responsive layout mixins.
@import 'vendors/singularitygs';

// Add Singularity extras
@import 'vendors/singularity-extras';
@import 'vendors/singularity-extras/outputs';
