// adds specificity
#coveod42951cc {
    &.CoveoSearchInterface {
        font-size: unset;
        font-weight: unset;
        line-height: unset;
    }

    .coveo-main-section {
        max-width: none;
    }

    .coveo-search-section {
        max-width: 1200px;
        box-sizing: content-box;
    }
    // override rules because our markup is different

    .list .list__item {
        margin-bottom: 0;
        padding-top: 0.8125em;
    }

    .list.list--lg .list__item {
        padding-top: 2.4375em;
    }

    .coveo-list-layout.CoveoResult {
        padding: 0 2.4375em;
        background-color: white;
        border-bottom: 0;
        // &::after instead of border bottom so we can have left/right padding on this element
        &::after {
            display: block;
            padding: 0 2.4375em;
            border-bottom: 1px solid #ddd;
            content: "";
        }

        &:last-child {
            &::after {
                display: none;
            }
        }

        a {
            color: #00739d;
        }

        .list__title a {
            color: unset;
        }
    }

    .CoveoResultList {
        max-width: 1200px;
        margin: 0 auto;
    }

    .coveo-tab-section {
        min-height: 0;
        background-color: transparent;
    }
    // tabs
    .CoveoTab {
        height: auto; // override .CoveoTab
        margin-bottom: 1px;
        padding: 6px 12px;
        color: #3e4345;
        font-family: $montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: bold;
        line-height: 1.25;
        text-transform: none;
        background-color: #e7eff3;
        border-bottom: 1px solid #d2dce1;
        border-color: #d2dce1;
        border-radius: 8px 8px 0 0;
        border-style: solid;
        border-width: 1px 1px 0;
        transition: all 300ms ease;

        &:hover {
            color: #009fda;
            background-color: white;
        }

        &.coveo-selected {
            color: #fff;
            background-color: #009fda;

            p {
                border-bottom: 0;
            }
        }

        p {
            display: block;
            height: auto;
            margin: 0;
            color: inherit;
            font-size: inherit;
            line-height: inherit;
        }
    }

    .coveo-tab-list-container {
        li {
            margin: 0;
        }

        .coveo-accessible-button {
            border-radius: 0;
        }
    }

    .CoveoSearchbox {
        border: 1px solid #b3c4cd;
        border-radius: 8px;
        box-shadow: inset 0 1px 3px rgb(17 17 17 / 10%);

        .magic-box {
            border: 0;

            .magic-box-input {
                height: 40px;
                background-color: transparent;
            }

            .magic-box-underlay {
                height: inherit;
            }

            .magic-box-clear {
                height: 40px;
                width: 40px;
                line-height: 40px;
                background-color: transparent;

                .magic-box-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: inherit;
                }

                svg {
                    height: 12px;
                    width: 12px;
                    color: #009fda;
                }
            }

            input {
                height: inherit;
                padding: 0px 12px;
                background-color: transparent;
                box-shadow: none;
            }
        }
    }

    .CoveoSearchButton {
        height: auto;
        background-color: transparent;
        border: 0;

        &:hover .coveo-magnifier-circle-svg {
            fill: #009fda;
        }

        .coveo-search-button-svg {
            color: #009fda;
        }
    }

    .coveo-results-column {
        padding-top: 28px;
        background-color: #f9f9f9;
        border-top: 1px solid #e0e0e0;
    }

    .CoveoLogo {
        position: relative;
        right: auto;
        bottom: auto;
        display: flex;
        max-width: 1200px;
        float: none;
        margin: 0 auto;
        padding: 28px 0;
        text-align: right;

        .coveo-powered-by {
            display: block;
            margin-left: auto;

            > svg {
                display: block; // no whitespace
            }
        }
    }

    .coveo-pager-container {
        display: block;
        max-width: 1200px;
        margin: 0 auto 40px;

        > * {
            flex: none;
        }
    }

    @media screen and (min-width: 640px) {
        .coveo-pager-container {
            display: flex;
        }

        .CoveoQuerySummary {
            display: unset;
            text-align: left;
        }
    }

    .coveo-pager-list {
        .coveo-pager-list-item {
            display: inline-block;
            margin: 0;
            padding: 4px 11px;
            text-decoration: none;
            background-color: #fff;
            border: 1px solid #ddd;
            border-radius: 8px;
            cursor: pointer;

            &.coveo-active {
                color: #595959;
                text-decoration: inherit;
                background-color: transparent;
                border: 0;
            }

            a {
                color: inherit;
                text-decoration: inherit;
            }

            &:hover {
                color: #fff;
                text-decoration: inherit;
                background-color: #009fda;
                border-color: #009fda;
            }
        }

        .coveo-pager-next-icon-svg,
        .coveo-pager-previous-icon-svg {
            height: auto;
            width: 6px;
            color: inherit;
        }
    }

    .CoveoQuerySummary {
        margin-right: auto;
        font-family: "Montserrat", Arial, Helvetica, sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 1.8;
        text-transform: uppercase;
        display: block;
        text-align: center;
    }
}

.CoveoResultLink {
    color: #313a45 !important;
}