.patient-spotlight {

	&__controls {
		padding-top: 0;
		
		.l-block & {
			padding-top: 0;
		}
	}

	&__select-block {

		label {
			margin-top: 0;
		}
	}
}