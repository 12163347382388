/**
 * @file
 * Home splash nav
 */

//////////////////////////////////////////
// Splash Nav: Default
//////////////////////////////////////////

.splash__nav {
  @extend %clearfix;
  font-size: 1.0em;

  @include media($x-small-min) {
    display: inline-block;
    background-color: rgba($white, .85);
    border: 1px solid $white;
    border-radius: 8px;
    box-shadow: 0 0 40px rgba($black, .4);
    font-size: 1em;
    padding: rhythm(.75) rhythm(1.25);

    .no-rgba & {
      background-color: $white;
    }
  }

  @include media($medium-min) {
    font-size: 1.5em;
  }

  .button {
    display: block;
    width: 100%;
    margin: rhythm(1) 0 0;

    @include media($x-small-min) {
      display: inline-block;
      width: auto;
      margin: 0;
    }
  }
}




//////////////////////////////////////////
// Splash Nav: Text
//////////////////////////////////////////

.splash__nav__text {
  display: block;
  line-height: 1.9em;
  color: $white;

  @include media($x-small-min) {
    display: inline-block;
    color: $text-color;
  }
}

.splash__nav__select,
.splash__nav__menu {
  @extend %no-bullets;

  a {
    display: block;
  }
}

.splash__nav__select {
  display: block;
  margin-bottom: rhythm(1);
  position: relative;
  text-transform: lowercase;

  @include media($x-small-min) {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
}

.selected-item {

  > a {
    background-color: $white;
    border: 1px solid $input-border-color;
    border-radius: $global-radius;
    font-weight: 700;
    line-height: 1.9em;

    &:hover,
    &:focus {
      border-color: $mlh-blue;
    }

    @include media($x-small-min) {
      padding: 0 1.3em 0 .5em;
    }

    &:after {
      display: block;
      position: absolute;
      right: .15em;
      top: 0.05em;
      content: '\25BE';
      font-size: 1.5em;
      color: $mlh-orange;


    }
  }
}




//////////////////////////////////////////
// Splash Nav: Menu
//////////////////////////////////////////

.splash__nav__menu {
  transition: all .3s ease;
  transform: translateY(-1em);
  display: block;
  position: absolute;
  left: 0;
  top: 2.5em;
  width: 100%;
  background-color: $white;
  border-radius: $global-radius;
  border: 1px solid $mlh-blue-gray-dark;
  box-shadow: 0 5px 15px rgba($black, .15);
  color: $mlh-gray-dark;
  cursor: pointer;
  overflow: show;
  opacity: 0;
  visibility: hidden;
  padding: 0;
  margin: 0;
  z-index: 99999;

  @include media($x-small-min) {
    width: 15em;
  }

  &.active {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }

  &:before,
  &:after {
    position: absolute;
    pointer-events: none;
    bottom: 100%;
    left: 1.5em;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
  }

  &:after {
    border-color: rgba($white, 0);
    border-bottom-color: $white;
    border-width: 10px;
    margin-left: -10px;
  }

  &:before {
    border-color: rgba($mlh-blue-gray-dark, 0);
    border-bottom-color: $mlh-blue-gray-dark;
    border-width: 11px;
    margin-left: -11px;
  }

  li {
    transition: all .3s ease;
    border-bottom: 1px solid $mlh-blue-gray-dark;
    font-size: .9em;
    list-style: none;
    text-align: left;
    opacity: 1;
    padding: rhythm(.75);

    a {
      color: $mlh-gray-medium;

      &:hover {
        color: $mlh-blue;
      }
    }

    &:first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    &:last-child {
      border: 0;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
}




//////////////////////////////////////////
// Splash Nav: Continue button
//////////////////////////////////////////

.splash_continue {

  @include media($x-small-min) {
    display: none;
  }
}
