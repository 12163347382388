.list__item--doctor .nrcRating .starsArea {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-right: 0.625rem;
  display: inline-flex;
}
.list__item--doctor .nrcRating .ds-stars {
  width: 3.75rem;
  height: 0.75rem;
  background-image: url("../../assets/theme2/assets/img/star-ratings.svg");
  background-position: 0 0;
  background-repeat: repeat-x;
  background-size: 3.75rem 8.25rem;
}
.list__item--doctor .nrcRating .ds-stars05 {
  background-position: 0 -0.75rem;
}
.list__item--doctor .nrcRating .ds-stars1 {
  background-position: 0 -1.5rem;
}
.list__item--doctor .nrcRating .ds-stars15 {
  background-position: 0 -2.25rem;
}
.list__item--doctor .nrcRating .ds-stars2 {
  background-position: 0 -3rem;
}
.list__item--doctor .nrcRating .ds-stars25 {
  background-position: 0 -3.75rem;
}
.list__item--doctor .nrcRating .ds-stars3 {
  background-position: 0 -4.5rem;
}
.list__item--doctor .nrcRating .ds-stars35 {
  background-position: 0 -5.25rem;
}
.list__item--doctor .nrcRating .ds-stars4 {
  background-position: 0 -6rem;
}
.list__item--doctor .nrcRating .ds-stars45 {
  background-position: 0 -6.75rem;
}
.list__item--doctor .nrcRating .ds-stars5 {
  background-position: 0 -7.5rem;
}
.list__item--doctor .nrcRating .nrc-xofy,
.list__item--doctor .nrcRating .nrc-ratings,
.list__item--doctor .nrcRating .nrc-comments {
  display: inline;
  font-size: 0.875rem;
}
.list__item--doctor .nrcRating .nrc-xofy:after {
  content: " ";
}
.list__item--doctor .nrcRating .nrc-ratings:before {
  content: "(";
}
.list__item--doctor .nrcRating .nrc-ratings:after {
  content: ", ";
}
.list__item--doctor .nrcRating .nrc-ratings:last-child:after {
  content: ")";
}
.list__item--doctor .nrcRating .nrc-comments:after {
  content: ")";
}
.list__item--doctor .nrcRating .nrc-average-max {
  margin: 0;
  color: inherit;
  font-weight: inherit;
}
.list__item--doctor .nrcRating .nrc-average-max:before {
  content: " ";
}