/**
 * @file
 * Doctor Results
 */

//////////////////////////////////////////
// Doctors: Default
//////////////////////////////////////////
.results {

    &__empty {
        background-color: $white;
        border-bottom: 1px solid $gray-light;
        padding: rhythm(2);
    }

    &__item {
        @extend %clearfix;
        background-color: $white;
        border-bottom: 1px solid $gray-light;
        margin: 0 rhythm(-1);

        @include media($x-small-min) {
            margin: 0 rhythm(-1.5);
        }

        @include media($medium-min) {
            border-bottom: 0;
            box-shadow: 0 1px 1px rgba($black, .1);
            padding: 0;
            margin: 0 0 rhythm(2);

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__bio {
        padding: rhythm(2) rhythm(1);

        @include media($medium-min) {
            float: left;
            padding: 0;
        }

        @include media($medium-max) {
            transition: padding-bottom 1s;

            .is-expanded & {
                padding-bottom: .5em;
            }
        }

        img {
            max-height: 160px;
        }
    }

    &__more-info {
        display: none;
        background: $off-white;
        clear: both;
        padding: rhythm(1.5) rhythm(1);
        margin: 0 rhythm(-1) rhythm(-2);

        @include media($x-small-min) {
            padding: rhythm(1.5);
            margin: 0 rhythm(-1.5) rhythm(-2);
        }

        @include media($medium-min) {
            display: none !important;
        }

        .more-info__locations,
        .more-info__item__compare {
            border-bottom: 1px solid $gray-light;
            padding-bottom: rhythm(1);
            margin-bottom: rhythm(1);
        }
    }

    &__content {
        // @include clearfix;  - this breaks the float right on the contact element
        @include media($medium-max) {
            background-color: #f9f9f9;
            max-height: 0;
            overflow: hidden;
            padding: 0 rhythm(1.5);
            transition: max-height 1s, padding-top 1s;

            .is-expanded & {
                max-height: 350px;
                padding: rhythm(2) rhythm(1.5) rhythm(2);
            }
        }
    }

    &__content &__contact {
        display: block;

        @include media($medium-min) {
            display: none;
        }
    }

    &__locations,
    &__compare {
        border-bottom: 1px solid #dddddd;
        margin-bottom: 0.8125em;
        padding-bottom: 0.8125em;

        @include media($medium-min) {
            border-bottom: none;
            float: left;
            margin: rhythm(1.5) 0 0 0;
            padding: 0;

            &:after {
                content: none;
            }
        }
    }

    &__locations {
        @include media($medium-min) {
            width: 200px;
            margin: rhythm(1.5) rhythm(1.5) 0 0;
        }
    }

    &__compare {
        @include media($medium-min) {
            //margin-right: 0.8125em;
        }

        .button {
            margin-right: .3em;
        }
    }

    &__MLHSEmployee {
        clear: both;
        display: block;
        position: relative;

        img {
            width: 90%;
        }

        figcaption {
            background-color: #e7eff3;
            border-radius: 3px;
            font-size: .75rem;
            position: absolute;
            bottom: calc(100% + 10px);
            left: 0;
            transition: all .5s;
            transition-delay: .5s;
            visibility: hidden;
            width: 100%;
            padding: 10px;
            z-index: 100;
        }

        &:hover {
            figcaption {
                visibility: visible;
            }
        }
    }

    &__label {
        @extend %track-out;
        @include sans-gotham($weight: 700);
        //@include adjust-font-size-to($label, 2);
        font-size: .8em;
        @include leader(0, $label);
        @include trailer(.25, $label);
        color: $mlh-gray-medium;
        text-transform: uppercase;
    }

    &__locations__list {
        @extend %no-bullets;
        font-size: .9em;
        line-height: 1.4;
    }

    &__contact {
        background-color: #f9f9f9;
        clear: both;
        display: none;
        width: 100%;

        &.inactive {
            @include sans-cond($style: italic);
            color: $text-color;
        }

        @include media($medium-min) {
            background-color: $white;
            border-top: 1px solid $gray-light;
            display: block;
            padding: rhythm(.5) rhythm(1);
            text-align: right;
        }



        @include media($large-min) {
            background-color: $mlh-blue-wcag2;
            float: right;
            width: 235px;
            min-height: 160px;
            color: $white;
            text-align: center;
            clear: right;
            padding: rhythm(3) rhythm(1) 0;

            &.inactive {
                background-color: lighten($gray-light, 5%);
                color: $text-color;
            }
        }

        @media (min-width: 1280px) {
            width: 255px;
        }

        .button {

            @include media($medium-min) {
                background: $white;
                border-color: $white;
                color: $mlh-blue;

                &:hover,
                &:focus {
                    background: $mlh-blue-gray;
                    border-color: $mlh-blue-gray;
                }
            }
        }

        .tel {
            display: block;
            margin-top: 0.40625em;
            margin-left: rhythm(.25);

            @include media($medium-min) {
                display: inline;
                margin-top: 0;
            }

            @include media($large-min) {
                display: block;
            }

            @media (min-width: 1280px) {
                margin: rhythm(1) 0 0;
            }

            a {
                font-weight: 700;

                @include media($large-min) {
                    color: $white;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .more-info__item__contact {

        .tel {
            display: block;
            margin-top: rhythm(.5);

            a {
                font-weight: 700;
            }
        }
    }
}
