/**
 * @file
 * Layout rules for two thirds / one third (66/33) content
 */

.l-66-33--1 {
  @include media($x-small-min) {
    @include isolation-span(8, 1, 'both');
  }
}

.l-66-33--2 {
  @include media($x-small-min) {
    @include isolation-span(4, 9);
  }
}
