﻿.printProfile {
    .profileWrapper {
        width: 100%;
        background-color: #808080;


        .printNavBar {
            background-color: #ffffff;
            height: 75px;
            width: 100%;
            position: fixed;

            .printNavBar-content {
                display: flex;
                justify-content: space-between;
                padding: 20px;
            }
        }

        .printNavBar:after {
            position: absolute;
            top: 75px;
            left: 0;
            display: block;
            content: '';
            height: 4px;
            width: 100%;
            background-color: #ff7900;
            background: -webkit-gradient(linear, left top, right top, color-stop(35%, #009fda), color-stop(35%, #a6ce39), color-stop(65%, #a6ce39), color-stop(65%, #ff7900), color-stop(65%, #ff7900));
            background: linear-gradient(to right, #009fda 35%, #a6ce39 35%, #a6ce39 65%, #ff7900 65%, #ff7900 65%);
        }

        .page-layout {
            padding: 100px 0px 20px 0px;
        }
    }


    .printPreview {
        width: 794px;
        /* height: 1123px; */
        background-color: #fff;
        display: block;
        margin: 0px auto;

        .print-pageHeader, .print-pageContent, .print-pageFooter {
            max-width: 794px;
        }

        .print-pageHeader {
            width: 100%;
            height: 150px;
            background-color: #009fda;
            display: flex;
            justify-content: center;
            align-items: center;

            .print-pageHeaderContent {

                .print-pageHeaderText {
                    font-size: 2.0em;
                    font-weight: bold;
                    color: white;
                    display: block;
                    text-align: center;
                    margin-top: 10px;
                }

                .print-specialties {
                    font-size: 1.2em;
                    font-style: italic;
                    color: white;
                    display: block;
                    text-align: center;
                    padding: 10px 0px;

                    ul {
                        list-style-type: none;
                        margin: 0px;
                        padding: 5px;
                    }
                }
            }
        }

        .print-pageContent {
            width: 100%;
            min-height: 710px;
            display: flex;
            font-family: "Montserrat", Arial, Helvetica, sans-serif;
            line-height: 1.2em;

            .print-pageContent-col {
                width: 33%;
                padding: 10px;

                .print-profileImage {
                    padding-bottom: 20px;
                }

                .print-profileEducation, .print-profilePractice, .print-profilePlaces {

                    .print-sectionList {
                        font-size: .95em;
                        margin-bottom: 0.8125em;

                        .print-sectionTitle {
                            font-size: 1em;
                            font-family: "Montserrat", Arial, Helvetica, sans-serif;
                            font-weight: 700;
                            color: #00739d;
                            font-style: normal;
                            /*display: inline-block;*/
                            text-transform: uppercase;
                            letter-spacing: normal;
                            margin: 0px;
                            padding: 0px;
                        }

                        .print-sectionItems.showBullet {
                            list-style: none;
                            padding: 0 0 0 10px;
                            margin: 0;

                            li {
                                font-size: 0.75em;
                                line-height: 16px;
                                position: relative;

                                &::before {
                                    content: '\00b7';
                                    position: absolute;
                                    left: -0.25em;
                                    top: -0.08em;
                                    font-size: 50px;
                                }
                            }
                        }

                        .print-sectionItems.noBullet {
                            list-style: none;
                            padding: 0;
                            margin: 0;

                            li {
                                font-size: 0.75em;
                                line-height: 16px;
                            }
                        }
                    }

                    .print-profileOffice {
                        padding-bottom: 20px;
                        font-size: 0.85em;
                    }
                }

                .print-profilePhilosophy {
                    color: #fff;
                }
            }

            .print-pageContent-col:nth-child(2) {
                width: 34%;
                padding: 10px;
            }

            .print-pageContent-col:nth-child(3) {
                background-color: #909090;
                font-size: 0.85em;
                padding: 10px;

                .print-profilePhilosophy {
                    .print-sectionTitle {
                        font-size: 1em;
                        margin-top: 0em;
                        margin-bottom: 0.15em;
                        font-family: "Montserrat", Arial, Helvetica, sans-serif;
                        font-weight: 700;
                        color: #fff;
                        font-style: normal;
                        display: inline-block;
                        text-transform: uppercase;
                    }
                }
            }
        }

        .print-pageFooter {
            width: 100%;
            padding: 10px;
            background-color: #fff;

            .print-footerContent {
                width: 100%;
                display: flex;
                bottom: 10px;

                .print-footerCallAction {
                    display: block;
                    width: 70%;
                    font-size: 0.9em;
                    line-height: 1.3em;

                    div:nth-child(1) {
                        text-transform: uppercase;
                        font-family: "Montserrat", Arial, Helvetica, sans-serif;
                        font-weight: 700;
                    }

                    div:not(:nth-child(1)) {
                        text-transform: uppercase;
                        font-weight: bold;
                        color: #00739d;
                    }
                }

                .print-MLHClogo {
                    display: block;
                    width: 30%;
                    text-align: center;

                    img {
                        height: 100px;
                    }
                }
            }

            .disclaimerText {
                font-family: "Montserrat", Arial, Helvetica, sans-serif;
                font-size: 0.4em;
                font-weight: normal;
                text-transform: none;
                color: #595959;
                line-height: 1.0em;
                margin-top: 5px;
            }
        }
    }
    /*@media print {
        .printPreview {
            width: 794px;*/
    /*height: 1123px;*/
    /*background-color: #fff;
            display: block;
            font-size: 18px !important;

            .print-pageHeader, .print-pageContent, .print-pageFooter {
                max-width: 794px;
            }

            .print-pageHeader {
                width: 100%;
                height: 200px;
                background-color: #009fda;
                display: flex;
                justify-content: center;
                align-items: center;

                .print-pageHeaderContent {

                    .print-pageHeaderText {
                        font-family: "Montserrat", Arial, Helvetica, sans-serif;
                        font-size: 2.0em;
                        font-weight: bold;
                        color: white;
                        display: block;
                        text-align: center;
                    }

                    .print-specialties {
                        font-size: 1.2em;
                        font-style: italic;
                        color: white;
                        display: block;
                        text-align: center;
                        padding: 15px 0px;

                        ul {
                            list-style-type: none;
                            margin: 0px;
                            padding: 5px;
                        }
                    }
                }
            }

            .print-pageContent {
                width: 100%;
                height: 710px;
                display: flex;

                .print-pageContent-col {
                    width: 33%;

                    .print-profileEducation, .print-profilePractice, .print-profilePlaces {
                        margin: 10px 10px 0px 10px;

                        .print-sectionList {
                            font-size: .95em;
                            margin-bottom: 1.625em;

                            .print-sectionTitle {
                                font-size: 1em;
                                margin-top: 0em;
                                margin-bottom: 0.15em;
                                font-family: "Montserrat", Arial, Helvetica, sans-serif;
                                font-weight: 700;
                                color: #00739d;
                                font-style: normal;
                                display: inline-block;
                                text-transform: uppercase;
                                text-align: left;
                            }

                            .print-sectionItems {
                                list-style: none;
                                padding: 0;
                                margin: 0;

                                li {
                                    font-size: 0.9em;
                                    text-align: left;
                                }
                            }
                        }

                        .print-profileOffice {
                            padding-bottom: 20px;
                            font-size: 0.9em;
                        }
                    }

                    .print-profilePhilosophy {
                        margin: 10px 0px 0px 10px;
                        color: #fff;
                    }
                }

                .print-pageContent-col:nth-child(2) {
                    width: 34%;
                }

                .print-pageContent-col:nth-child(3) {
                    background-color: #909090;
                    font-size: 0.9em;
                    padding-right: 10px;
                    text-align: left;
                }
            }

            .print-pageFooter {
                width: 100%;
                padding: 10px;
                background-color: #fff;

                .print-footerContent {
                    width: 100%;
                    display: flex;
                    bottom: 10px;

                    .print-footerCallAction {
                        display: block;
                        width: 70%;


                        div:nth-child(1) {
                            text-transform: uppercase;
                            font-family: "Montserrat", Arial, Helvetica, sans-serif;
                            font-weight: 700;
                        }

                        div:not(:nth-child(1)) {
                            text-transform: uppercase;
                            font-weight: bold;
                            color: #00739d;
                        }
                    }

                    .print-MLHClogo {
                        display: block;
                        width: 30%;
                        text-align: center;
                    }
                }
            }
        }
    }*/
}
