/**
 * @file
 * Sidebar fieldgroup
 */

//////////////////////////////////////////
// Sidebar: Fieldgroup
//////////////////////////////////////////

.sidebar--fields {
  @extend %clearfix;
  background-color: $off-white;
  box-shadow: 0 1px 1px rgba($black, .15);
  padding: rhythm(2) rhythm(1);
}

.sidebar-fieldgroup {
  margin-bottom: rhythm(1.5);
}

.sidebar-fieldgroup--left {

  @include media($xx-small-min) {
    float: left;
    width: 47%;
  }
}

.sidebar-fieldgroup--right {

  @include media($xx-small-min) {
    float: right;
    width: 47%;
  }
}
