/**
 * @file
 * Doctor Profile
 */

//////////////////////////////////////////
// Profile: Layout
//////////////////////////////////////////
.doctor,
.therapist {
	.l-block--main {
		@include media($large-max) {
			.l-content {
				@include grid-span(12, 1);
				margin-bottom: 0;
			}

			.l-sidebar {
				@include grid-span(12, 1);
			}
		}
	}
}

.profile {
	@extend %clearfix;
	margin-bottom: rhythm(2);
}





//////////////////////////////////////////
// Profile: Image content
//////////////////////////////////////////

.profile-image {
	background-color: $off-white;
	border: 1px solid $gray-light;
	border-radius: 4px;
	text-align: center;
	padding: rhythm(1);
	margin-bottom: rhythm(2);

	@include media($x-small-min) {
		background-color: transparent;
		border: 0;
		border-radius: 0;
		float: left;
		width: 270px;
		padding: 0;
		margin-bottom: 0;
	}

	img {
		margin-bottom: rhythm(1);
		width:270px;
		height:341px;
	}

	.button {
		width: 100%;
		margin-bottom: rhythm(1);

		@include media($xx-small-to-x-small) {
			width: 80%;
		}
	}
}




//////////////////////////////////////////
// Profile: Intro copy
//////////////////////////////////////////

.profile-intro {

	@include media($x-small-min) {
		margin-left: 300px;
	}
}

.profile-intro__item {
    border-bottom: 1px solid $gray-light;
    padding-bottom: rhythm(1.5);
    margin-bottom: rhythm(1.5);

    ul {
        @extend %no-bullets;
    }

    li {
        display: inline;
    }

    .button {
        margin-bottom: 0;
    }

    &.noBottomMargin {
        margin-bottom: 0px !important;
    }

    .profile-practice_component {
        display: block;

        @include media($medium-min) {
            display: flex;
        }

        .profile-practice__logo {
            margin-left: 0em;
            text-align: center;


            @include media($medium-min) {
                margin-left: 1em;
                margin-top: -1em;
            }
        }
    }



    &:last-child {
        border-bottom: none;
        padding-bottom: rhythm(0);
        margin-bottom: rhythm(0);
    }
}

    .noBottomBorder {
        border-bottom: none !important;
    }

    .noTopBorder {
        border-top: none !important;
    }

    .therapist {
        .profile-intro__item {

            &:nth-last-child(2) {
                /* override the bottom border */
                border-bottom: 0;
                padding-bottom: 0;
            }
            /* hide language info for now*/
            .tab-personal {
                li:nth-last-child(2) {
                    display: none;
                }
            }
        }
    }

    .profile-intro__item--stacked {
        li {
            display: list-item;
        }
    }
    // Center appointment info at mobile viewport
    .profile-intro__item--appointment {
        text-align: center;

        @include media($x-small-min) {
            text-align: left;
        }
    }

    .profile-intro__item--last {
        border-bottom: 0;
        padding-bottom: 0;
    }

    .profile-intro__title {
        @include leader(0, $h5-font-size);
        @include trailer(.25, $h5-font-size);
        color: $mlh-gray-dark;
        text-transform: none;
    }

    .profile-intro__short-bio {
        margin-bottom: 0;

        .is-truncated & {
            max-height: 4.5em;
            overflow: hidden;
        }
    }

    .profile-intro__contact {
        display: inline-block;
        white-space: nowrap;
        margin-top: rhythm(.5);

        a {
            font-weight: 700;
        }
    }

    .profile-intro__mlhc-employed {
        clear: both;
        display: inline-block;
        position: relative;
        width: 100%;

        figcaption {
            background-color: #e7eff3;
            border-radius: 3px;
            font-size: .75rem;
            position: absolute;
            bottom: calc(100% + 10px);
            left: 0;
            transition: all .5s;
            transition-delay: .5s;
            visibility: hidden;
            width: 100%;
            padding: 10px;
        }

        &:hover {
            figcaption {
                visibility: visible;
            }
        }
    }

    .profile-intro__specialtieslist {
        list-style: disc !important;
        display: flex;
        flex-wrap: wrap;

        @include media($small-max) {
            display: block;
        }


        & > li {
            display: list-item;
            margin: 0px 20px;
            break-inside: avoid-column;
        }
    }
    //////////////////////////////////////////
    // Profile: Office info
    //////////////////////////////////////////
    .profile-offices {

        .link {
            display: block;
            margin: rhythm(2) 0 rhythm(1.5);
        }
    }

    .profile-offices {
        background-color: $off-white;
        box-shadow: 0 1px 1px rgba($black, .15);
        padding: rhythm(2);

        @include media($large-min) {
            font-size: .95em;
        }
    }


    .doctor .profile-office__map,
    .therapist .profile-office__map {
        width: 100%;
        height: 300px;
        margin-bottom: rhythm(1);
        /*
	@include media($large-max) {
		display: none;
	}
    */
    }

    .profile-office__item {
        border-bottom: 1px solid darken($gray-light, 10%);
        padding-bottom: rhythm(1);
        margin-bottom: rhythm(1);

        &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }

    .profile-office__address {
        font-size: .9em;
        font-style: normal;
        margin-bottom: rhythm(1);
    }

    .profile-office__address__title {
        @include leader(0, $h5-font-size);
        @include trailer(0, $h5-font-size);
    }

    .profile-office__address__contact {
        @extend %no-bullets;
        font-size: 1.1em;
        margin-top: rhythm(1);

        span {
            @include sans-gotham($weight: 700);
        }
    }

    .profile-offices__all {
        display: none;
        margin-top: rhythm(1);
    }

    .profile-showOtherDoctorsinPractice {
        font-size: 0.8em;
    }

    .profile-ZocDoc__wrapper {
        padding-top: rhythm(1);
        padding-bottom: rhythm(1);
    }
    //////////////////////////////////////////
    // Profile: EPIC Office styles
    //////////////////////////////////////////
    .profile-EPIC-offices {
        display: table;
        width: 100%;
        margin-left: 0px;

        @include media($large-min) {
            font-size: .95em;
        }

        @include media($medium-min) {
            margin-left: 300px;
            width: calc(100% - 300px);
        }
    }

    .profile-EPIC-offices__place_row {
        width: 100%;
    }


    .profile-EPIC-offices__item_divider {
        border-top: 2px solid #dddddd !important;
        width: 100%;
        vertical-align: middle;
    }

    .profile-EPIC-offices__postContent {
        margin-left: 0px;

        @include media($medium-min) {
            margin-left: 300px;
            width: calc(100% - 300px);
        }
    }

    .profile-EPIC-offices_place_addressBox {
        width: 100%;



        @include media($medium-min) {
            display: flex;
            flex-direction: row-reverse;

            > div {
                flex: 1;
            }
        }
    }

    .profile-EPIC-offices__messageBox {
        border-radius: 8px;
        background-color: #fff;
        padding: rhythm(1.25);

        :first-child {

            &.bold {
                font-weight: bold;
            }
        }

        .button {
            margin-top: rhythm(2);
        }

        .newAccountText {
            font-size: 0.7em;
            margin-top: rhythm(1.5);
        }
    }
    //////////////////////////////////////////
    // Profile: Tabs
    //////////////////////////////////////////
    .toggle-tabs {
        @extend %clearfix;
        @extend %no-bullets;
        @include sans-gotham();
        display: none;
        text-transform: uppercase;
        text-align: center;

        @include media($medium-min) {
            display: block;
        }

        i {
            display: none;
        }

        li {
            float: left;
            width: 33.333%;
            // two tabs
            &:first-child:nth-last-child(2),
            &:first-child:nth-last-child(2) ~ li {
                width: 50%;
            }
            // one tab
            &:first-child:last-child {
                width: 100%;
            }

            &.current {
                background-color: $off-white;

                a {
                    border-color: $mlh-blue;
                    color: $mlh-blue;
                }
            }
        }

        a {
            transition: all .3s ease;
            border-top: 3px solid transparent;
            display: block;
            color: $text-color;
            cursor: pointer;
            font-size: .8em;
            padding: rhythm(2) 0;

            @include media($large-min) {
                font-size: .9em;
            }

            &:hover {
                border-color: $gray-light;
                color: $mlh-blue;
            }
        }
    }

    .tab-container {
        display: none;
        border-bottom: 1px solid $gray-light;

        @include media($medium-min) {
            border-bottom: 0;
        }

        &.current {
            display: block;
        }
    }

    .tab {
        border-bottom: 1px solid $gray-light;

        @include media($medium-min) {
            display: none;
        }

        &:hover {
            color: $mlh-blue;
        }

        &.current {
            background-color: $mlh-blue;
            color: $white;

            i {
                transform: rotate(90deg);
            }

            a {
                color: $white;
            }
        }

        a {
            @include sans-gotham();
            color: $text-color;
            display: block;
            cursor: pointer;
            text-transform: uppercase;
            padding: rhythm(1);

            @include media($x-small-min) {
                padding-left: rhythm(1.5);
                padding-right: rhythm(1.5);
            }
        }
    }
    //////////////////////////////////////////
    // Profile: Tab content layouts
    //////////////////////////////////////////
    .tab-left {
        margin-bottom: rhythm(3);

        @include media($medium-min) {
            @include grid-span(6, 1);
        }

        @include media($large-min) {
            @include grid-span(7, 1);
            margin-bottom: 0;
        }
    }

    .tab-right {

        @include media($medium-min) {
            @include grid-span(6, 7);
            margin-top: rhythm(1);
        }

        @include media($large-min) {
            @include grid-span(5, 8);
        }
    }
    //////////////////////////////////////////
    // Profile: Personal info
    //////////////////////////////////////////
    .tab-personal {
        @extend %no-bullets;

        span {
            @include sans-gotham($weight: 700);
        }
    }
    //////////////////////////////////////////
    // Profile: List
    //////////////////////////////////////////
    .tab-list {
        font-size: .95em;
        margin-bottom: rhythm(2);
    }

    .tab-list__title {
        @include adjust-font-size-to($h5-font-size, 1.75);
        @include leader(0, $h5-font-size);
        @include trailer(.25, $h5-font-size);
        @include sans-gotham($weight: 700);
        display: inline-block;
        text-transform: uppercase;
    }

    .tab-list__title--right {
        float: right;
    }

    .tab-list__items {
        @extend %no-bullets;

        li {
            margin-bottom: rhythm(.5);
        }

        &.inline li {
            display: inline;
            margin-bottom: 0;
        }
    }

    .tab-list__item {
        position: relative;
        border-bottom: 1px solid $gray-light;
        padding-bottom: rhythm(.5);
        margin-bottom: rhythm(.5);

        &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
            margin-bottom: 0;
        }

        .tab-list__specialty {
            display: block;
        }

        .tab-list__certified {
            display: block;
            font-size: .9em;
            font-style: italic;
        }

        .tab-list__status {
            position: absolute;
            top: .5em;
            right: .5em;
            font-size: 1.2em;
            color: $gray-medium;

            &.accepting {
                color: $mlh-green;
            }
        }
    }
