﻿/* content */
.GetCareNow-row {
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;

    @include media($medium-min) {
        flex-wrap: nowrap;
        -webkit-flex-wrap: nowrap;
    }
}

.GetCareNow-col {
    margin: rhythm(1);
    flex-grow: 0;
    flex-basis: 0;
    flex: 1;

    @include media($medium-min) {
        
    }

    @include media($large-min) {
        
    }
}

.GetCareNow-Title {
    margin: rhythm(1);
    font-weight: bold;
    text-align: center;
    font-size: 0.8em;
    white-space: nowrap;
}

.GetCareNow-Content-section {
    margin-bottom: rhythm(1);
    font-size: 0.8em;
    line-height: 1.5em;


    &.section1 {
        height: 120px;
        /* 630px < */
        @include media($x-small-min) {
            height: 125px;
        }
        /* 750px < */
        @include media($small-min) {
            height: 165px;
        }
        /* 1060px < */
        @include media($medium-min) {
            height: 130px;
        }
        /* 1230px < */
        @include media($large-min) {
            height: 120px;
        }
    }

    &.section2 {
        height: 100px;
        /* 630px < */
        @include media($x-small-min) {
            height: 80px;
        }
        /* 750px < */
        @include media($small-min) {
            height: 100px;
        }
        /* 1060px < */
        @include media($medium-min) {
            height: 90px;
        }
        /* 1230px < */
        @include media($large-min) {
            height: 80px;
        }
    }

    button {
        width: 100%;
    }
}

/* front end button */
/* home - splash screen */
.splash__special-alert-button {
    position: absolute;
    z-index: 5;
    right: 25px;
    top: 10px;
    display: none;

    @include media($medium-min) {
        display: block;
    }
}

.btn-special-alert {
    background-color: #e5485b;
    color: #fff;
    display: flex;
    border-radius: 6px;

    .special-alert-button-icon {
        padding: 4px 8px;
        background-color: #fff;
        border-radius: 6px 0px 0px 6px;
        border: solid 1px #e5485b;

        .fa-heart-o, .fa-heart {
            color: #e5485b;
            padding-top: 4px;
        }

        .fa-heart-o {
            display: none;
        }

        .fa-heart {
            display: block;
        }
    }

    .special-alert-button-text {
        background-color: #e5485b;
        color: #fff;
        border-radius: 0px 6px 6px 0px;
        padding: 4px 12px;
        font-family: "Montserrat", Arial, Helvetica, sans-serif;
        font-weight: bold;
        font-size: 0.7em;
        text-transform: uppercase
    }

    &:hover {
        cursor: pointer;

        .special-alert-button-icon .fa-heart {
            display: none;
        }

        .special-alert-button-icon .fa-heart-o {
            display: block;
        }

        .special-alert-button-text {
            background-color: #eb7583;
        }
    }
}

/* small -  */
.small-header__special-alert-button {
    display: block;

    @include media($small-min) {
        display: none;
    }    
}

.button--get-care {
    background-color: #e5485b;
    border-color: #e5485b;
    color: #fff;

    &:hover {
        background-color: #fff;
        border-color: #fff;
        color: #e5485b;
    }
}