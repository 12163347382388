.spinner {
  display: block;
  margin: 10px auto;
}

._spinner {
  margin: 100px auto;
  height: 60px;
  text-align: center;
  font-size: 10px;
}

._spinner > div {
  //background-color: #333;
  height: 100%;
  width: 6px;
  display: inline-block;
  
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

._spinner .rect1 {
    background-color: $mlh-logo-color1;
}

._spinner .rect2 {
    background-color: $mlh-logo-color2;
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

._spinner .rect3 {
    background-color: $mlh-logo-color3;
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

._spinner .rect4 {
    background-color: $mlh-logo-color4;
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

._spinner .rect5 {
    background-color: $mlh-logo-color5;
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

._spinner .rect6 {
    background-color: $mlh-logo-color6;
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}
