/**
 * @file
 * Layout rules for 6-up content
 */

.l-6up--1 {
  @include media($x-small-min) {
    @include isolation-span(2, 1, 'both');
  }
}

.l-6up--2 {
  @include media($x-small-min) {
    @include isolation-span(2, 3);
  }
}

.l-6up--3 {
  @include media($x-small-min) {
    @include isolation-span(2, 5);
  }
}

.l-6up--4 {
  @include media($x-small-min) {
    @include isolation-span(2, 7);
  }
}

.l-6up--5 {
  @include media($x-small-min) {
    @include isolation-span(2, 9);
  }
}

.l-6up--6 {
  @include media($x-small-min) {
    @include isolation-span(2, 11);
  }
}
