﻿
.accordion-menu {
    width: 100%;
    margin: auto;

    h1, h2 {
        color: $mlh-blue-link;
        font-family: $montserrat;
    }

    h1 {
        text-transform: uppercase;
        font-size: 36px;
        line-height: 42px;
        letter-spacing: 3px;
        font-weight: 100;
        @extend .flipIn;
    }

    h2 {
        font-size: 26px;
        line-height: 34px;
        font-weight: 300;
        letter-spacing: 0.5px;
        display: block;
        background-color: $white;
        margin: 0;
        cursor: pointer;
        @extend .no-select;
    }

    > ul:first-child {
        list-style: none;
        perspective: 900;
        padding: 0;
        margin: 0;

        > li {
            position: relative;
            padding: 0;
            margin: 0;
            padding-bottom: 4px;
            padding-top: 18px;
            border-top: 1px solid $mlh-gray-medium;
            @extend .flipIn;

            &:nth-of-type(1) {
                animation-delay: 0.5s;
            }

            &:nth-of-type(2) {
                animation-delay: 0.75s;
            }

            &:nth-of-type(3) {
                animation-delay: 1.0s;
            }

            &:last-of-type {
                padding-bottom: 0;
            }

            > i, > em {
                position: absolute;
                transform: translate( -6px, 0 );
                margin-top: 8px;
                right: 0;

                &:before, &:after {
                    content: "";
                    /*@extend .transition;*/
                    position: absolute;
                    background-color: $mlh-blue-link;
                    width: 3px;
                    height: 9px;
                }

                &:before {
                    transform: translate( -2px, 0 ) rotate( 45deg );
                }

                &:after {
                    transform: translate( 2px, 0 ) rotate( -45deg );
                }
            }

            > input[type=checkbox] {
                position: absolute;
                cursor: pointer;
                width: 100%;
                height: 100%;
                z-index: 1;
                opacity: 0;

                &:checked {
                    & ~ div.menuContent {
                        margin-top: 0;
                        max-height: 0;
                        opacity: 0;
                        transform: translate( 0, 50% );
                    }

                    & ~ i, & ~ em {
                        &:before {
                            transform: translate( 2px, 0 ) rotate( 45deg );
                        }

                        &:after {
                            transform: translate( -2px, 0 ) rotate( -45deg );
                        }
                    }
                }
            }

            > div.menuContent {
                color: $text-color;
                font-weight: normal;
                line-height: 1.4625em;
                letter-spacing: normal;
                position: relative;
                overflow: hidden;
                /*max-height: 800px;*/
                /*@extend .transition;*/
                opacity: 1;
                transform: translate( 0, 0 );
                margin-top: 14px;
                z-index: 2;
            }
        }
    }

    @keyframes flipdown {
        0% {
            opacity: 0;
            transform-origin: top center;
            transform: rotateX(-90deg)
        }

        5% {
            opacity: 1;
        }

        80% {
            transform: rotateX(8deg);
        }

        83% {
            transform: rotateX(6deg);
        }

        92% {
            transform: rotateX(-3deg);
        }

        100% {
            transform-origin: top center;
            transform: rotateX(0deg);
        }
    }
}

.transition {
    transition: all 0.25s ease-in-out;
}

.flipIn {
    animation: flipdown 0.5s ease both;
}


.no-select {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}