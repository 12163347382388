/**
 * @file
 * Sidebar proximity navigation
 */

//////////////////////////////////////////
// Prox Nav: Default styles
//////////////////////////////////////////

.sidebar__prox-nav {

  ul {
    @extend %no-bullets;
  }

  a {
    @include sans-gotham($weight: 500);
    display: block;
    color: $mlh-gray-dark;
    padding: rhythm(1) rhythm(1.5);

    > span {
      transition: all .3s ease;
      transform: translateX(0);
      display: block;
    }

    &:hover,
    &.active {
      color: $mlh-blue-wcag2;
    }

    /*&:hover > span {
      transform: translateX(.5em);
    }*/
  }
  .is-active > a {
    background-color: #f1f1f1;
    color: $mlh-blue-wcag2;
    cursor: default;

    &:hover > span {
      transform: translateX(0);
    }
  }

  li {
    position: relative;
  }

  i {
    transition: all .3s ease;
    position: absolute;
    top: 0;
    right: 0;
    width: 3.2em;
    height: 3.2em;
    color: $mlh-orange;
    line-height: 3.2em;
    text-align: center;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &.opened {
      transform: rotate(180deg);
    }
  }
}




//////////////////////////////////////////
// Prox Nav: Parent Title
//////////////////////////////////////////

.prox-nav__parent-title {

  > a {
    @include adjust-font-size-to($h5-font-size, 2);
    @include leader(0, $h5-font-size);
    @include trailer(0, $h5-font-size);
    background: $mlh-blue-wcag2;
    font-weight: 700;
    color: $white;
    text-transform: uppercase;

    &:hover {
      
      color: $white;
    }

    &.is-active {
      /*cursor:default;*/
  
      &:hover {
        background-color: $mlh-blue;

        /*span {
          transform: translateX(0);
        }*/
      }
    }
    > i
      {
        display: none;

          &.isWhite
          {
              color: $white;
          }

          &.fa-2x
          {
            width: 1.6em;
            height: 1.6em;
            line-height: 1.6em;
          }
      }
  }

  &.js-isCollapsible
  {
      > a
      {
          cursor:pointer;

          > i
          {
            display: block;
          }
      }

      > .prox-nav__level2
      {
          display:none;
      }
  }

}




//////////////////////////////////////////
// Prox Nav: Second level
//////////////////////////////////////////

.prox-nav__level2 {
  background-color: $off-white;

  > li > a {
    border-bottom: 1px solid $gray-light;
    font-size: .9em;
    padding-right: 3.5em;

    &:hover {
      background-color: darken($off-white, 3%);
      > span {
        transform: translateX(.5em);
      }
    }
  }
}




//////////////////////////////////////////
// Prox Nav: Third level
//////////////////////////////////////////

.prox-nav__level3 {
  display: none;
  background-color: $white;
  border-bottom: 1px solid lighten($gray-light, 3%);

  > li:last-child {
    padding-bottom: rhythm(.5);
  }

  > li > a {
    font-size: .85em;
    padding: rhythm(.75) rhythm(.75) rhythm(.75) rhythm(2.5);

    &:hover > span {
      transform: translateX(.5em);
    }
  }
  .is-active > a {
    background:none;
  }

  
}
