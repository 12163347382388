﻿html,
body {
    height: 100%;
}

/*.site-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}


.site-wrapper__notification {
    flex: none;
}

.site-wrapper__content {
    flex: auto;
    overflow: auto;
}

*/
.site-wrapper__header{
	position: relative;
    position:sticky;
    top:0px;
    z-index:200;
}
.site-wrapper__header-ie-sticky {
	position: fixed;
	width:100%;
	top: 0px;
	z-index: 100;
}

