﻿.gmap-canvas
{
    overflow:hidden;
    position:relative;
}

.gmap-canvas iframe 
{
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}