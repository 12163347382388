.dynamic-listing {
}

.dynamic-listing__filter {
	overflow: hidden;
	margin-bottom: 1.25em;
	padding: 20px;
	background-color: $off-white;
	border: 1px solid $gray-light;
	border-radius: 14px;

	.select2-wrapper {
		width: 100%;
	}
	.select2-wrapper__select {
		position: relative;
		/*float: left;*/
		width: 100%;

		select {
			float: left;
			width: 100%;
		}

		.spinner {
			position: absolute;
			right: 20px;
			top: 6px;
			width: 30px;
			height: 30px;
			margin: 0;
			z-index: 100;
		}
				.spinner-label {
			position: absolute;
			top: 6px;
			height: 30px;
			z-index: 100;
			    display: block;
    padding-left: 8px;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
	color: #444444;
    line-height: 2em;
		}

		@include media($small-max) {
			float: none;
			width: 100%;
		}
	}
	.select2-wrapper__label {
		margin-right: 1.5625em;
		margin-top: .75em;

		@include media($small-max) {
			float: none;
			margin-top: 0;
		}
	}
}