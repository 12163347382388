/**
 * @file
 * Layout rules for full-width content
 */

.l-1up {
  clear: both;

  @include media($medium-min) {
    @include isolation-span(12, 1, 'both');
  }
}
