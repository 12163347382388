/**
 * @file
 * List component styles
 */

//////////////////////////////////////////
// List: Default
//////////////////////////////////////////

.list__items {
    @extend %no-bullets;
    @include clearfix;
}

.list__item {
    @extend %clearfix;
    position: relative;
    border-bottom: 1px solid $gray-light;
    padding-bottom: rhythm(1);
    margin-bottom: rhythm(1);

    &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: 0;
    }
    // Has image
    .list__img {
        display: block;
        float: left;
        width: 65px;
        height: auto;
        margin-right: 10px;
    }

    .list__content.has-img {
        margin-left: 80px;
    }

    .list__address {
        font-size: .85em;
        font-style: normal;
        line-height: 1.4;
        margin-bottom: rhythm(.5);

        h6 {
            color: inherit;
            font-size: .8em;
            margin-bottom: 0;
            margin-top: .5em;
            text-transform: none;
        }
    }
}

// List header
.list__header {
    position: relative;
    margin-bottom: rhythm(1.5);

    .link {
        position: absolute;
        top: .25em;
        right: 0;
    }
}

// List header title
%list__header__title,
.list__header__title {
  @include adjust-font-size-to($text, 1.4);
  @include leader(0, $text);
  @include trailer(0, $text);
  text-transform: uppercase;
  color: $list-header-title;
  padding-right: 4.5em;
}

.list__location--items {
    list-style: none;

    @include media($small-min) { // replace this with a breakpoint variable that is close
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .list__item.list__item--location {
            float: none;
            width: calc(50% - 15px);
            list-style: none;

            &:nth-child(odd):nth-last-child(2) {
                border-bottom: 0;
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
    }
}
// List meta
.list__meta {
    @extend %track-out;
    @include slab();
    @include adjust-font-size-to($meta-sm, 1.5);
    @include leader(0);
    @include trailer(.5, $meta-sm);
    text-transform: uppercase;

    > span {
        border-right: 1px solid $gray-light;
        padding-right: rhythm(1);
        margin-right: rhythm(1);

        &:last-child {
            border-right: 0;
            padding-right: 0;
            margin-right: 0;
        }
    }

    .list__date,
    .list__time {
        white-space: nowrap;
    }
}

// List content wrap
.list__content {
    position: relative;
    text-align: left;
}

.list__content-list {
    @include leader(0);
    @include trailer(.5, $text);

    > span {
        border-right: 1px solid $gray-light;
        padding-right: rhythm(1);
        margin-right: rhythm(1);

        &:last-child {
            border-right: 0;
            padding-right: 0;
            margin-right: 0;
        }
    }

    .list__email,
    .list__phone {
        white-space: nowrap;
    }

    .list__email > a > span {
        margin-right: 5px;
    }
}

.list__content-label {
    font-size: .85em;
}

// List title
.list__title {
    @extend %gray-link;
    @extend %track-in;
    @include adjust-font-size-to($h4-font-size, 2);
    @include leader(.5, $h4-font-size);
    @include trailer(0, $h4-font-size);
    font-weight: 700;
}

.list__specialties {
    > span {
        display: inline-block;
        white-space: nowrap;
    }

    .is-accepting,
    .is-not-accepting {
        display: inline-block;
        margin-left: .5em;
        transform-origin: center center;
        transform: rotate(45deg);
    }

    .is-accepting {
        border-radius: 2px;
        border-bottom: 5px solid $mlh-green;
        border-right: 5px solid $mlh-green;
        height: 1em;
        text-indent: -9999px;
        width: .6em;
    }

    .is-not-accepting {
        color: $gray-medium;
        font-size: 1.75em;
        font-weight: bold;
        margin-left: .1em;
        vertical-align: bottom;
    }
}

.list__locations {
    font-size: .85em;
    color: $gray-medium;
    line-height: 1.4;
}

// List bottom button
.list__button {
    text-align: center;
    margin-top: rhythm(2);
}

// List rss
.list__rss {
    text-align: right;
    margin-bottom: rhythm(.5);

    a {
        color: $orange;
        font-weight: 700;

        &:hover {
            color: darken($orange, 10%);
        }
    }
}

.list__view-more {
    &--50 {
        display: none;
    }
}




//////////////////////////////////////////
// List: Large text
//////////////////////////////////////////
.list--lg {

    .list__item {
        padding-bottom: rhythm(3);
        margin-bottom: rhythm(3);
    }

    .list__item--last {
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: 0;
    }

    .list__meta {
        @include trailer(1, $meta-sm);
    }

    .list__title {
        @include adjust-font-size-to($h2-font-size, 2);
        @include leader(0, $h2-font-size);
        @include trailer(1, $h2-font-size);
    }
}




//////////////////////////////////////////
// List: Smaller text
//////////////////////////////////////////
.list--sm {

    .list__title {
        @include adjust-font-size-to($h5-font-size, 1.5);
    }
}




//////////////////////////////////////////
// List: Simple list
//////////////////////////////////////////
.list__item--simple {
    @extend %gray-link;
    @extend %track-in;
    padding-bottom: rhythm(.75);
    margin-bottom: rhythm(.75);

    a {
        @include adjust-font-size-to($h5-font-size, 1.5);
        font-weight: 700;
    }

    .doctors__number {
        float: right;
    }
}




//////////////////////////////////////////
// List: Terms
//////////////////////////////////////////
.list__item--term {
    @extend %gray-link;
    @extend %track-in;
    padding-bottom: rhythm(.5);
    margin-bottom: rhythm(.5);

    a {
        font-size: .9em;
        font-weight: 700;
        outline: 0;

        &.active,
        &.active:focus {
            color: $mlh-blue;
        }


        &:focus {
            color: $black;
        }
    }
}




//////////////////////////////////////////
// List: Register
//////////////////////////////////////////
.list--register {

    i {
        width: 1.5em;
    }

    .list__calendar {
        @include sans-gotham();
        color: $text-color;
        font-size: .9em;

        &:hover {
            color: $mlh-blue;
        }
    }

    .reg-number {
        @include sans-gotham();
        display: block;
        text-align: center;
        font-size: .9em;
        margin-top: rhythm(1);

        a {
            font-weight: 700;
            font-size: 1.3em;
        }
    }
}




//////////////////////////////////////////
// List: Locations
//////////////////////////////////////////
.list__item--location {
    transition: background .3s ease;
    // margin-bottom: 0; // removed by MLHR-957
    &:hover {
        background-color: rgba($white, .75);
    }

    &.active {
        background-color: $white;
    }
    //margin-bottom: 0;
    // Location that has image
    .list__img {
        display: block;
        float: left;
        width: 150px;
        height: auto;

        @include media($xx-small-min) {
            width: 140px;
        }
    }

    .list__content.has-img {
        margin-left: 150px;

        @include media($xx-small-min) {
            margin-left: 155px;
        }
    }

    .list__title {
        margin-top: 0;
    }

    .list__contact {

        a {
            display: inline-block;
            white-space: nowrap;
            margin-right: rhythm(1);

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .list__hours {
        font-size: .85em;
        line-height: 1.25em;

        h6 {
            font-size: 1em;
            line-height: 1.25em;
            margin: 0;
            text-transform: capitalize;
        }
    }

    .list__hours-content {
        list-style: none;
    }
}




//////////////////////////////////////////
// List: Location w/ map
//////////////////////////////////////////

.list__number {
    position: absolute;
    top: rhythm(1);
    left: rhythm(1);
    width: 25px;
    height: 25px;
    background-color: $mlh-gray-medium;
    color: $white;
    line-height: 25px;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
}

.active .list__number {
    background-color: $mlh-blue;
}




//////////////////////////////////////////
// List: Events
//////////////////////////////////////////
.list__item--event {

    .list__content {
        padding-left: rhythm(4);
    }
}

.list__event-date {
    @include slab();
    display: block;
    float: left;
    min-width: 3.6em;
    border-right: 2px solid $mlh-orange;
    font-size: .7em;
    text-align: center;
    line-height: 1.1;
    text-transform: uppercase;
    padding-right: rhythm(1);
    margin: .2em 0 .5em;

    .list__event-date__day {
        display: block;
        font-size: 2em;
    }
}




//////////////////////////////////////////
// List: Featured Hero with Image
//////////////////////////////////////////

// List overrides
.list--featured-hero {

    .list__header {
        text-align: center;
        margin-bottom: rhythm(2);

        @media only screen and (max-width: 926px) {
            margin-top: rhythm(2);
        }
    }

    .list__header__title {
        @include slab();
        @include adjust-font-size-to($h4-font-size, 1.5);
        @include leader(0, $h4-font-size);
        @include trailer(0, $h4-font-size);
        position: relative;
        display: inline-block;
        color: $mlh-gray-dark;
        line-height: 50px;
        vertical-align: top;
        padding-right: 0;
        margin-left: rhythm(.5);

        @include media($small-min) {
            font-size: 1.0em;
        }
    }

    .list__item {
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: rhythm(2);

        @include media($medium-min) {
            border-bottom: 1px solid $gray-light;
            padding-bottom: rhythm(2);
            margin-bottom: rhythm(2);
        }
    }

    .list__meta {
        position: relative;
        display: inline-block;
        background: $white;
        text-align: center;
        padding: rhythm(2) rhythm(2) rhythm(1);
        margin-bottom: 0;
        z-index: 1;

        .featured--events & {

            @include media($xx-small-min) {
                margin-left: -4.1em;
            }
        }
    }

    .list__event-date {
        position: relative;
        top: 60px;
        min-width: 3.3em;
        font-size: .8em;

        @include media($xx-small-min) {
            top: 56px;
        }
    }

    .list__title {
        @include adjust-font-size-to($h2-font-size, 2.25);
        @include leader(1, $h2-font-size);
        @include trailer(1, $h2-font-size);
    }
}

// List featured wrap
.list--featured {
    background-color: $off-white;
    border-top: 1px solid lighten($gray-light, 7%);
    border-bottom: 1px solid lighten($gray-light, 7%);
    padding: rhythm(3) rhythm(1);
    margin: 0 rhythm(-1);

    @include media($x-small-min) {
        padding: rhythm(3) rhythm(1.5);
        margin: 0 rhythm(-1.5);
    }

    @include media($medium-min) {
        background-color: transparent;
        border: 0;
        padding: 0;
        margin: 0;
    }
    // Set min height for featured list items
    .list__item {

        @include media($medium-min) {
            min-height: 95px;
        }
    }
}

// List header icons
.list__header__icon {
    display: inline-block;
    background-color: $mlh-green;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    vertical-align: top;

    &--news {
        background: $mlh-green url('/assets/images/svg/ico--news.svg') no-repeat center;

        .no-svg & {
            background: $mlh-green url('/assets/images/png/ico--news.png') no-repeat center;
        }
    }

    &--events {
        background: $mlh-green url('/assets/images/svg/ico--events.svg') no-repeat center;

        .no-svg & {
            background: $mlh-green url('/assets/images/png/ico--events.png') no-repeat center;
        }
    }
}

// Hero image
.list__hero-image {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0 rhythm(-1);
    // Bottom gradient
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        content: '';
        background: linear-gradient(to bottom, rgba($black, 0) 0%, rgba($black, 0) 60%, rgba($black, .5) 80%, rgba($black, .8) 100%);
        z-index: 1;
    }
    // Background overlay
    &:after {
        transition: all .5s ease;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        content: '';
        background-color: rgba($black, .7);
        opacity: 0;
        z-index: 1;
    }
    // Triggers on image link hover
    &:hover {

        &:after {
            opacity: 1;

            .no-rgba & {
                background: url('/assets/images/bg--bg-overlay.png') repeat 0 0;
            }
        }

        .list__hero-image__src {
            transform: scale(1.1);
        }

        .list__hero-image__link {
            top: 35%;
            opacity: 1;
            visibility: visible;
        }
    }

    @include media($x-small-min) {
        margin: 0 rhythm(-1.5);
    }

    @include media($medium-min) {
        margin: 0;
    }
}

// Hero image link styles
.list__hero-image__link {
    transition: all .5s ease;
    position: absolute;
    top: 45%;
    left: 0;
    width: 100%;
    text-align: center;
    color: $white;
    opacity: 0;
    visibility: hidden;
    padding: 0 rhythm(3);
    z-index: 2;

    .link--text {
        margin-right: 0;
    }
}

// Hero image link icon
.list__hero-image__link__icon {
    display: inline-block;
    width: 66px;
    height: 66px;
    border-radius: 50%;

    .featured--news & {
        background: $mlh-blue url('/assets/images/svg/ico--news.svg') no-repeat center;

        .no-svg & {
            background: $mlh-blue url('/assets/images/png/ico--news.png') no-repeat center;
        }
    }

    .featured--events & {
        background: $mlh-blue url('/assets/images/svg/ico--events.svg') no-repeat center;

        .no-svg & {
            background: $mlh-blue url('/assets/images/png/ico--events.png') no-repeat center;
        }
    }
}

// Hero image height
.list__hero-image__src {
    transition: all .5s ease;
    height: 280px;
    background-size: cover;

    @include media($x-small-min) {
        height: 400px;
    }

    @include media($medium-min) {
        height: 370px;
    }
}

// Hero content wrap
.list__hero-content {
    text-align: center;
    padding: 0 rhythm(1);
    margin: rhythm(-1.5) rhythm(-1) 0;

    @include media($x-small-min) {
        padding: 0 rhythm(1.5);
        margin: rhythm(-1.5) rhythm(-1.5) 0;
    }

    @include media($medium-min) {
        border-color: $gray-light;
        padding: 0;
        margin: rhythm(-1.5) 0 0;
    }

    @include media($large-min) {
        // min-height: 225px;  // list_item--news
    }
}

// Hero body copy
.list__text {
    margin-bottom: rhythm(.5);
}




//////////////////////////////////////////
// List Block
//////////////////////////////////////////

.list--block {
    margin-bottom: rhythm(3);

    @include media($medium-min) {
        @include grid-span(6, 1, $output-style: 'float');
        margin-bottom: 0;
    }

    .list__header {
        text-align: center;
    }

    .list__header__title {
        @include adjust-font-size-to($h3-font-size, 2.5);
        @include leader(0, $h3-font-size);
        @include trailer(2, $h3-font-size);
        display: inline-block;
        color: $mlh-gray-dark;
        text-align: center;
    }

    .block__button {
        text-align: center;
        margin-top: rhythm(2);
    }
}

.list--block--last {
    @extend %clearfix;
    margin-bottom: 0;

    @include media($medium-min) {
        @include float-span(6, 'last');
    }
}




//////////////////////////////////////////
// List Block Full
//////////////////////////////////////////

.list--block--full {

    @include media($medium-min) {
        @include grid-span(12, 1);
    }

    .list__item {

        @include media($x-small-min) {
            float: left;
            width: 100%;
            clear: left;

            &:nth-child(even) {
                float: right;
                clear: none;
            }

            &:nth-last-child(1) {
                border-bottom: none;
            }
        }
    }
}




//////////////////////////////////////////
// List BLock: Location
//////////////////////////////////////////

.list--locations.list--block {

    .list__header {
        @include media($small-max) {
            margin-bottom: 0;
        }
    }

    .list__header__title {
        background: url('/assets/images/svg/ico--locations.svg') no-repeat left center;
        padding-left: rhythm(2);
        padding-right: rhythm(3);

        @include media($small-max) {
            margin-bottom: 35px;
        }

        .no-svg & {
            background: url('/assets/images/png/ico--locations.png') no-repeat left center;
        }
    }

    @include media($large-min) {
        .list__content {
            padding-right: 130px;

            .l-sidebar & {
                padding-right: inherit;
            }
        }

        .list__contact {
            position: absolute;
            top: 0;
            right: 0;
            width: 130px;

            .list__tel {
                margin-right: 0;
            }
        }
    }
}

.list--doctors.list--block {
    @include media($medium-min) {
        .list__content {
            padding-right: 140px;

            .l-sidebar & {
                padding-right: inherit;
            }
        }

        .list__contact {
            position: absolute;
            top: 0;
            right: 0;
            width: 140px;

            .list__tel {
                margin-right: 0;
            }
        }
    }
}

.l-block--blue {
    .list--locations {

        .list__header__title {
            background-image: url('/assets/images/svg/ico--locations--white.svg');
            color: #ffffff;
        }

        @include media($x-small-min) {
            .list__items {
            }
        }

        .list__item--location {
            @include media($small-max) {
                float: none;
                width: 100%;
            }

            &:hover {
                background-color: transparent;
            }
        }

        .list__title {
            a {
                text-decoration: none;

                &:hover {
                    color: inherit;
                }
            }
        }

        .list__address {
            margin-bottom: 0;
        }

        .list__contact {
            margin-bottom: 1em;
            position: static;
            width: 100%;

            a {
                font-weight: normal;
                text-decoration: none;
            }

            i {
                margin-right: .25em;
            }

            .list__tel {
                margin-right: 0.8125em;
            }
        }

        .list__direction {
            position: static;
            width: 100%;

            a {
                font-weight: normal;
                text-decoration: none;
            }

            i {
                margin-right: .25em;
            }

            .list__tel {
                margin-right: 0.8125em;
            }
        }
    }
}




//////////////////////////////////////////
// List BLock: Doctors
//////////////////////////////////////////

.list--doctors.list--block {

    .list__header__title {
        background: url('/assets/images/svg/ico--doctors.svg') no-repeat left center;
        padding-left: rhythm(2);
        padding-right: rhythm(2);

        .no-svg & {
            background: url('/assets/images/png/ico--doctors.png') no-repeat left center;
        }
    }
}

//////////////////////////////////////////
// List BLock: Dynamic Listing - Clinical Trials
//////////////////////////////////////////
.list__item--clinicaltrials {
    padding-bottom: rhythm(0) !important;
    margin-bottom: rhythm(0) !important;
}


/* initialize columns for mobile */
ul.two-col {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    -o-column-count: 1;
    column-count: 1;
}

ul.three-col {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    -o-column-count: 1;
    column-count: 1;
}

ul.four-col {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    -o-column-count: 1;
    column-count: 1;
}

@include media($medium-min) {
    ul.two-col {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        -o-column-count: 2;
        column-count: 2;
        column-gap: 30px;

        li {
            -moz-column-break-inside: avoid;
            -webkit-column-break-inside: avoid;
            -mx-column-break-inside: avoid;
            column-break-inside: avoid;
        }
    }

    ul.three-col {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        -o-column-count: 3;
        column-count: 3;
        column-gap: 30px;

        li {
            -moz-column-break-inside: avoid;
            -webkit-column-break-inside: avoid;
            -mx-column-break-inside: avoid;
            column-break-inside: avoid;
        }
    }

    ul.four-col {
        -webkit-column-count: 4;
        -moz-column-count: 4;
        -o-column-count: 4;
        column-count: 4;
        column-gap: 30px;

        li {
            -moz-column-break-inside: avoid;
            -webkit-column-break-inside: avoid;
            -mx-column-break-inside: avoid;
            column-break-inside: avoid;
        }
    }
}