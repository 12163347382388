// /**
//  * @file
//  * FitVids
//  */

//////////////////////////////////////////
// Forms: Default
//////////////////////////////////////////

.fluid-width-video-wrapper {
  margin: rhythm(2) 0;
}
