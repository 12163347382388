/**
 * @file
 * Footer global styles
 */

//////////////////////////////////////////
// Footer: Default
//////////////////////////////////////////

.footer {
    @include branding-bar;
    background: $mlh-gray-dark;
    color: $mlh-gray-light;
}




//////////////////////////////////////////
// Footer: Newsletter
//////////////////////////////////////////

.footer__newsletter__center {
    text-align: center;
}

.footer__newsletter {
    @extend %clearfix;
    background: darken($mlh-gray-dark, 5%);
    padding: rhythm(2) 0;

    @include media($x-small-min) {
        padding: rhythm(4) 0;
    }
}

.newsletter__text {
    @extend %track-in;
    display: block;
    color: darken($gray-light, 5%);
    line-height: 1.58em;
    margin-bottom: rhythm(1);

    @include media($x-small-min) {
        font-size: 1.6em;
    }

    @include media($medium-min) {
        display: inline-block;
        margin: 0 rhythm(1) 0 0;
        vertical-align: top;
    }
}

.newsletter__text--well-ahead {
    display: block;
    vertical-align: top;
    margin: 0 auto;
    width: 180px;


    @include media($x-small-min) {
        display: inline-block;
        margin: 6px 0 0 0;
    }
}

.newsletter__form {
    width: 100%;

    @include media($medium-min) {
        display: inline-block;
        width: 40%;
        vertical-align: top;
    }

    input {
        background-color: $mlh-gray-dark;
        border-color: lighten($mlh-gray-dark, 10%);
        color: #ffffff;
        padding-right: 3em;
        transition: width .8s;

        &[type="text"][disabled] {
            background-color: $mlh-gray-dark;
            color: #ffffff;
        }

        &::-ms-clear {
            display: none;
            width: 0;
            height: 0;
        }
    }

    &:after {
        content: "";
        font-family: FontAwesome;
        line-height: 1.25em;
        position: absolute;
        left: 100%;
        top: 0;
        text-align: center;
        transition: all .8s;
    }

    &.is-invalid,
    &.is-valid {
        margin-right: 50px;
        width: calc(100% - 50px);

        @include media($medium-min) {
            width: calc(40% - 50px);
        }

        &:after {
            font-size: 2em;
            width: 50px;
        }
    }

    &.is-invalid:after {
        color: $red;
        content: "\f00d";
    }

    &.is-valid:after {
        color: $mlh-green;
        content: "\f00c";
    }

    button {
        float: right;
    }
}




//////////////////////////////////////////
// Footer: Connect
//////////////////////////////////////////

.footer__connect {
    text-align: center;
    padding-top: rhythm(3);
}

.footer__number {
    color: $white;
    line-height: 1.2;
    margin: 1.5em 0;

    .num {
        color: $white;
        font-weight: 700;

        &:hover,
        &:focus {
            color: $mlh-blue;
        }
    }

    @include media($x-small-min) {
        span {
            display: block;

            &.call-us {
                font-size: .9em;
            }
        }
    }
}

.footer__social {
    @extend %no-bullets;
    display: flex;
    gap: 3px;
    justify-content: center;

    li {
        display: inline-block;
    }

    a {
        color: $mlh-gray-dark;
    }
}

// Map
$social: ('facebook', $facebook)
, ('twitter', $twitter), ('youtube', $youtube), ('linkedin', $linkedin), ('instagram', $instagram)
;

// Loop
@each $social-network, $color in $social {

    .footer__social .social__#{$social-network} {

        a:hover,
        a:focus {
            color: $color;
        }
    }
}




//////////////////////////////////////////
// Footer: Link list titles
//////////////////////////////////////////

.footer__title {
    @include adjust-font-size-to($meta, 1);
    @include leader(1, $meta);
    @include trailer(.5, $meta);
    border-bottom: 1px solid lighten($mlh-gray-dark, 10%);
    //color: lighten($mlh-gray-light, 10%);
    color: $mlh-gray-lighter;
    padding-bottom: rhythm(1);
}




//////////////////////////////////////////
// Footer: Links lists
//////////////////////////////////////////

.footer__link {
    padding: rhythm(2) 0 0;
}

.footer__list {
    margin-bottom: rhythm(2);

    ul {
        @extend %no-bullets;
        @include adjust-font-size-to($label, 1.3);
        column-count: 1;
        column-gap: 2em;
    }

    li {
        break-inside: avoid-column;
        padding-bottom: rhythm(.75);
    }

    a {
        color: $white;

        &:hover,
        &:focus {
            color: $mlh-blue;
        }
    }
}




//////////////////////////////////////////
// Footer: Credits
//////////////////////////////////////////

.footer__credits {
    display: block;
    font-size: .8em;
    text-align: center;
    clear: both;
    padding: rhythm(1) 0 rhythm(2);
    color: $mlh-gray-lighter;

    a {
        color: $white;

        &:hover,
        &:focus {
            color: $mlh-blue;
        }
    }
}




//////////////////////////////////////////
// Footer: Breakpoint layout changes
//////////////////////////////////////////

@include media($xx-small-min) {

    .footer__list {
        margin-bottom: rhythm(2);

        ul {
            column-count: 2;
        }
    }
}

$footer-connect_width: 250px;
$footer-connect_margin: 2em;

@include media($small-min) {
    .footer__connect {
        float: left;
        margin-right: $footer-connect_margin;
        width: $footer-connect_width;
    }

    .footer__link {
        float: left;
        width: calc(100% - #{$footer-connect_width} - #{$footer-connect_margin});
    }
}

@include media($medium-min) {
    .footer__link {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
    }

    .footer__list {
        box-sizing: border-box;
        margin-bottom: rhythm(1);
        padding: 0 1rem;
        width: 25%;

        ul {
            column-count: 1;
        }
    }

    .footer__list--col2 {
        width: 50%;

        ul {
            column-count: 2;
        }
    }

    .footer__list--col3 {
        width: 75%;

        ul {
            column-count: 3;
        }
    }

    .no-flexbox {
        .footer__list {
            float: left;
        }

        .footer__list--newrow {
            clear: left;
        }
    }
}




/////////////////////////////////////////////////////////
// Footer: Styles for browser that don't support columns
/////////////////////////////////////////////////////////

@include media($medium-min) {
    .no-csscolumns .footer__list--col2 ul {
        //@extend %clearfix;

        li {
            float: left;
            width: 50%;

            &:nth-child(2n+1) {
                clear: left;
            }
        }
    }

    .no-csscolumns .footer__list--col3 ul {
        li {
            float: left;
            width: 33%;

            &:nth-child(3n+1) {
                clear: left;
            }
        }
    }
}

//////////////////////////////////////////////
//Modal : Feedback Modal
/////////////////////////////////////////////

.mlh-fa-comment {
    color: #8dc63f;
}

.modal-split {
    border: 1px solid #8dc63f;
    margin: 15px 50px 15px 50px;
}

.button--feedback {
    margin-top: 20px !important;
    margin-bottom: 25px;
    border-radius: 30px !important;
    text-transform: none !important;
}

.modal-title {
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 37px;
    color: #414042;
    margin-left: 48px;
}

.feedback-button {
    Display: block !important;
    position: fixed;
    z-index: 2147483647;
    cursor: pointer;
    transition: none !important;
    border-radius: 4px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom: none;
    bottom: 8%;
    right: -60px;
    z-index: 9999;
    transform: rotate(270deg);
}

.feedback-button a {
    font-size: 75%;
    display: block;
    background-color: #fff;
    color: #444444;
    text-align: center;
    padding: 3px 20px 12px 20px;
    border-radius: 10px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    text-decoration: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}



/* Modal styles */
.feedback-modal {
    display: none;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.feedback-content {
    background-color: #fefefe;
    margin: 15% auto;
    border: 1px solid #888;
    width: 37%;
    text-align: center;
    border-radius: 5px;
}

.feedback-close {
    color: $mlh-blue-link;
    float: right;
    font-size: 1.995rem;
    border: 2px solid #8dc63f;
    border-radius: 100px;
    width: 30px;
    height: 30px;
}

.modal-body {
    display: inline-block;
    text-align: center;
    margin: 0px 50px 0px 50px;
}

.feedback-close:hover,
.feedback-close:focus {
    color: #aaaaaa;
    text-decoration: none;
    cursor: pointer;
}

.feedback-close-button {
    padding: 20px;
}

/* For mobile devices */
@media (max-width: 767px) {
    .feedback-content {
        width: 100%;
    }
}

/* For tablets */
@media (min-width: 768px) and (max-width: 1023px) {
    .feedback-content {
        width: 50%;
    }
}

.btn {
    max-width: 100%;
    display: inline-block;
    padding: 0.875rem 2.375rem 0.875rem;
    background-image: none;
    border: 0.125rem solid transparent;
    border-radius: 2.5rem;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.375rem;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    outline-offset: 0.25rem;
    white-space: normal;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.btn:hover, .btn:focus {
    text-decoration: none;
}

.btn:focus {
    outline-color: #005b80;
}

.btn:active {
    -webkit-transition: none;
    transition: none;
}

.btn.disabled, .btn:disabled {
    outline: 0;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.btn .icon {
    display: inline-block;
}

.btn .icon, .btn:hover .icon, .btn:focus .icon {
    color: inherit;
}

.btn-primary {
    background-color: #005b80;
    border-color: #005b80;
    color: #fff;
}

.btn-primary:hover, .btn-outline-primary:hover {
    background-color: #003247;
    border-color: #003247;
    color: #fff;
}
/*
.swal2-container {
    display: grid;
    position: fixed;
    z-index: 1060;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    grid-template-areas: "top-start top top-end" "center-start center center-end" "bottom-start bottom-center bottom-end";
    grid-template-rows: minmax(-webkit-min-content, auto) minmax(-webkit-min-content, auto) minmax(-webkit-min-content, auto);
    grid-template-rows: minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, auto);
    height: 100%;
    padding: 0.625em;
    overflow-x: hidden;
    -webkit-transition: background-color 0.1s;
    transition: background-color 0.1s;
    -webkit-overflow-scrolling: touch;
}

.swal2-container.swal2-top, .swal2-container.swal2-center, .swal2-container.swal2-bottom {
    grid-template-columns: auto minmax(0, 1fr) auto;
}

.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
    background: rgba(0, 0, 0, 0.4);
}

.swal2-popup {
    display: none;
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    grid-template-columns: minmax(0, 100%);
    border: none;
    border-radius: 5px;
    background: #fff;
    color: #545454;
    font-family: inherit;
    font-size: 1rem;
    width: 75rem;
    max-height: 100vh;
    max-width: 50%;
    padding: 1.25rem 0.625rem;
    overflow: auto;
}

.swal2-container.swal2-center > .swal2-popup {
    grid-column: 2;
    grid-row: 2;
    -ms-flex-item-align: center;
    align-self: center;
    justify-self: center;
}

.swal2-close {
    z-index: 2;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 0;
    margin-bottom: -1.2em;
    padding: 0;
    overflow: hidden;
    -webkit-transition: color 0.1s, -webkit-box-shadow 0.1s;
    transition: color 0.1s, -webkit-box-shadow 0.1s;
    transition: color 0.1s, box-shadow 0.1s;
    transition: color 0.1s, box-shadow 0.1s, -webkit-box-shadow 0.1s;
    border: none;
    background: transparent;
    color: #ccc;
    font-family: serif;
    font-family: monospace;
    font-size: 2.5em;
    cursor: pointer;
    justify-self: end;
    width: 2.25rem;
    height: 2.25rem;
    margin-right: 0.625rem;
    border-radius: 50%;
    outline-offset: 0.125rem;
}


.swal2-close:hover {
    -webkit-transform: none;
    transform: none;
    background: transparent;
    color: #f27474;
}

.swal2-close:focus {
    outline: none;
    -webkit-box-shadow: inset 0 0 0 3px rgba(100, 150, 200, 0.5);
    box-shadow: inset 0 0 0 3px rgba(100, 150, 200, 0.5);
}

.swal2-close::-moz-focus-inner {
    border: 0;
}

.swal2-html-container {
    z-index: 1;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 1em 1.6em 0.3em;
    padding: 0;
    overflow: auto;
    color: inherit;
    font-size: 1.125em;
    font-weight: normal;
    line-height: normal;
    text-align: center;
    word-wrap: break-word;
    word-break: break-word;
}
*/