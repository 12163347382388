html {
  @if mixin-exists('box-sizing') {
    @include box-sizing('border-box');
  }
  @else {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

*, *:before, *:after {
  box-sizing: inherit;
}

embed,
img,
object,
video {
  max-width: 100%;
  height: auto;
}
