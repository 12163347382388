// /**
//  * @file
//  * Search
//  */

//////////////////////////////////////////
// Search: Default
//////////////////////////////////////////


.custom-searchIcon
{
    display: inline-block;
    background: url('/assets/images/png/search-icon.png') no-repeat center center;
    width: 20px;
    height: 20px;
    position: relative;
    top: 3px;
}

.custom-searchIcon:hover{
  background: url('/assets/images/png/search-icon-hover.png') no-repeat center center;
}

.search-wrap {
  position: relative;
  margin-bottom: .5em;
}




//////////////////////////////////////////
// Search: Content type filters
//////////////////////////////////////////

.search-content-type {
  @extend %no-bullets;
  display: none;
  padding-bottom: rhythm(2);

  li {
    display: block;
  }

  a,button {
    @include adjust-font-size-to($nav, 1.2);
    @include sans-gotham();
    display: block;
    border-bottom: 1px solid $mlh-blue-gray-dark;
    text-transform: uppercase;
    font-weight: 700;
    padding-right: 3em;
    color: $mlh-gray-dark;
    padding: rhythm(1.25) rhythm(1.5);

    &.active,
    &:hover,
    &:focus {
      color: $mlh-blue;
    }
  }

  @include media($medium-min) {
    display: block !important;
    border-bottom: 1px solid $gray-light;
    text-align: center;
    padding-bottom: 0;

    li {
      display: inline-block;
      border-bottom: 0;
    }

    a,button {
      background-color: $mlh-blue-gray;
      border-radius: $global-radius $global-radius 0 0;
      border-style: solid;
      border-color: $mlh-blue-gray-dark;
      border-width: 1px 1px 0;
      text-transform: none;
      padding: rhythm(.5) rhythm(1);
      margin-bottom: 1px;

      &:hover {
        background-color: $white;
      }

      &.active {
        background-color: $mlh-blue;
        color: $white;

        &:hover {
          background-color: $mlh-blue-dark;
        }
      }
    }
  }
}
