﻿.limr {
    /* global*/
    .bodyRightRailContainer:empty, main:empty, aside:empty {
        display: none;
    }
    /* splash */
    .splash {
        margin-top: 34px;
    }
    //upper thin crust nav
    .site-header--extra-site {
        width: 100%;
        background-color: #3e4345;
        text-align: center;
    }

    @media (min-width: 600px) {
        .site-header--extra-site {
            text-align: right;
        }
    }

    .site-header--extra-site .site-header--extra-site-nav {
        margin: 0 1.21875em;
    }

    .site-header--extra-site .site-header--extra-site-nav a {
        font-size: 0.7em;
        line-height: 2.90179em;
        font-family: "Montserrat", Arial, Helvetica, sans-serif;
        font-weight: 400;
        font-style: normal;
        color: #dddddd;
    }

    @media (min-width: 1230px) {
        .site-header--extra-site .site-header--extra-site-nav a {
            font-size: 0.8em;
            line-height: 2.53906em;
        }
    }

    .site-header--extra-site .site-header--extra-site-nav a:hover,
    .site-header--extra-site .site-header--extra-site-nav a:focus {
        color: #009fda;
    }

    .site-header--extra-site .site-header--extra-site-nav a.active {
        color: #009fda;
    }
    /* Site header large*/
    .site-header--large {
        display: none;
        box-shadow: 0 5px 15px rgba(17, 17, 17, 0.15);
    }

    .site-header--logo {
        position: absolute;
        top: 0;
        left: 1.21875em;
        background: #ffffff;
        border-radius: 0 0 4px 4px;
        box-shadow: 0 -10px 60px rgba(17, 17, 17, 0.15);
        z-index: 100;
        max-width: 175px;
    }

    .no-boxshadow .site-header--logo {
        border-bottom: 1px solid #dddddd;
    }

    .site-header--logo h2 {
        margin: 0;
    }

    .site-header--logo a {
        display: block;
        padding: 0.40625em 0.8125em;
    }
    /* LIMR Top Navigation */
    .site-header--navs {
        float: right;
        padding-left: 0px;
    }

    .site-header--nav {
        float: left;
        margin-top: 0;
    }

    @media (min-width: 1230px) {
        .site-header--nav {
            margin-top: 0;
        }
    }

    @media screen and (min-width: 1500px) {
        .site-header--nav {
            border-right: 0;
            margin-top: 0;
        }
    }

    .site-header--nav ul {
        padding: 0;
        margin: 0;
    }

    .site-header--nav li {
        display: inline-block;
        margin-right: 0.65em;
    }

    @media (min-width: 1300px) {
        .site-header--nav li {
            margin-right: 1em;
        }
    }

    .site-header--nav li:last-child {
        margin-right: 0;
    }

    @media (min-width: 1230px) {
        .site-header--nav li:last-child {
            margin-right: 0.8125em;
        }
    }

    .site-header--nav .site-header--nav__has-children {
        margin-right: 1.625em;
    }

    .site-header--nav .site-header--nav__has-children i {
        line-height: 2.1em;
    }

    .site-header--nav a {
        font-size: 0.67em;
        line-height: 2.90179em;
        font-family: "Montserrat", Arial, Helvetica, sans-serif;
        font-weight: 700;
        font-style: normal;
        color: #3e4345;
        text-transform: uppercase;
    }

    @media (min-width: 1230px) {
        .site-header--nav a {
            font-size: 0.8em;
            line-height: 2.53906em;
        }
    }

    .site-header--nav a:hover,
    .site-header--nav a:focus {
        color: #009fda;
    }

    .site-header--nav a.active {
        color: #009fda;
    }
    /* nav */
    @media (min-width: 1060px) {
        .nav__has-children--site-header--nav a:focus .nav__level2, .nav__has-children--location--nav a:focus .nav__level2 {
            transform: translateY(0);
            opacity: 1;
            visibility: visible;
            margin-top: -30px;
        }

        .nav__has-children--site-header--nav a.active,
        .nav__has-children--location--nav a.active {
            border-color: #009fda;
        }
    }

    .nav__has-children--site-header--nav i {
        top: .6em;
        right: -.8em;
        color: $orange;
    }

    @media (min-width: 1230px) {
        .nav__has-children--site-header--nav i {
            top: .5em;
        }
    }

    .nav__has-children--location--nav i {
        right: 0;
        top: 0;
        line-height: 2.6em;
        width: 2.3em;
        height: 2.6em;
    }

    @media (min-width: 1060px) {
        .nav__has-children--location--nav i {
            right: -1.8em;
            line-height: 1em;
        }
    }

    .nav__level2 {
        display: none;
        background-color: #f9f9f9;
        border-bottom: 1px solid #d2dce1;
    }

    @media (min-width: 1060px) {
        .nav__level2 {
            display: block !important;
            transition: all .3s ease;
            transform: translateY(-1em);
            position: absolute;
            /*top: 100%;*/
            /*left: -1em;*/
            background-color: #ffffff;
            border-radius: 8px;
            border: 1px solid #d2dce1;
            box-shadow: 0 5px 15px rgba(17, 17, 17, 0.15);
            /*width: 15em;*/
            text-align: left;
            opacity: 0;
            visibility: hidden;
            z-index: 100;

            &.nav-active {
                transform: translateY(0);
                opacity: 1;
                visibility: visible;
            }
        }

        .nav__level2:before,
        .nav__level2:after {
            position: absolute;
            pointer-events: none;
            bottom: 100%;
            left: 1.5em;
            border: solid transparent;
            content: '';
            height: 0;
            width: 0;
        }

        .nav__level2:after {
            border-color: rgba(255, 255, 255, 0);
            border-bottom-color: #ffffff;
            border-width: 10px;
            margin-left: -10px;
        }

        .nav__level2:before {
            border-color: rgba(210, 220, 225, 0);
            border-bottom-color: #d2dce1;
            border-width: 11px;
            margin-left: -11px;
        }
    }

    .nav__level2 li {
        display: block;
        position: relative;
        margin: 0;
    }

    @media (min-width: 1060px) {
        .nav__level2 li {
            border-bottom: 1px solid #d2dce1;
        }
    }

    .nav__level2 a {
        font-size: 0.8em;
        line-height: 1.21875em;
        font-weight: 400;
        display: block;
        padding: 1.01563em 1.21875em;
        text-transform: none;
        width: 100%;
    }

    .site-header--buttons-small {
        float: right;
        margin-top: 1.42188em;
    }

    .site-header--buttons-small li {
        display: inline-block;
    }

    .site-header--buttons-small .button--like-to__text {
        display: none;
    }

    @media (min-width: 400px) {
        .site-header--buttons-small .button--like-to__text {
            display: inline;
        }
    }

    .like-to {
        transition: all .3s ease;
        transform: translateY(-100px);
        position: absolute;
        left: 0;
        background: #ffffff;
        width: 100%;
        visibility: hidden;
        opacity: 0;
        z-index: 99;
        top: 6.8em;
    }

    @media (orientation: landscape) {
        .like-to {
            height: 240px;
            overflow-y: scroll;
        }
    }

    .like-to.active {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }

    .like-to i {
        float: right;
        color: #818a8f;
        line-height: 1.4;
    }

    @media (min-width: 1060px) {
        .like-to {
            display: none !important;
        }
    }

    .like-to li {
        border-bottom: 1px solid #e7eff3;
    }

    .like-to li:last-child {
        border-bottom: 0;
    }

    .like-to a {
        display: block;
        padding: 0.8125em 5%;
    }

    @media (min-width: 600px) {
        .like-to a {
            padding: 0.8125em 3%;
        }
    }

    .like-to a:hover, .like-to a:focus {
        background: #009fda;
        color: #ffffff;
    }

    .like-to a:hover i, .like-to a:focus i {
        color: #ffffff;
    }

    .search--small,
    .search-limr--small {
        transition: all 0.3s ease;
        transform: translateY(-100px);
        position: absolute;
        top: 6.8em;
        left: 0;
        background: #ffffff;
        width: 100%;
        visibility: hidden;
        opacity: 0;
        padding: 1.625em 0;
        z-index: 99;
    }

    .search--small.active,
    .active.search-limr--small {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }

    @media (min-width: 1060px) {
        .search--small,
        .search-limr--small {
            display: none !important;
        }
    }

    .search-limr--small {
        top: 6.7em;
    }

    .search--small__wrap {
        position: relative;
    }

    .site-header--large {
        display: none;
        box-shadow: 0 5px 15px rgba(17, 17, 17, 0.15);
    }

    @media (min-width: 1060px) {
        .site-header--large {
            display: block;
        }
    }
    /*********************Researcher Detail Overview Start */
    &.researcher {
        aside {
            display: none;
        }

        .l-block--main .l-content {
            width: 100%;
        }

        .profile-intro__short-bio {
            margin: 1.01563em 0 0;
        }
    }

    .profile {
        margin-bottom: 1.625em;
    }

    @media (min-width: 1230px) {
        .profile {
            width: 65.21739%;
            float: left;
            margin-right: -100%;
            margin-left: 0;
            clear: none;
            margin-bottom: 0;
        }
    }

    .fullWidth {
        width: 100% !important;
    }

    .profile-offices {
        background-color: #f9f9f9;
        box-shadow: 0 1px 1px rgba(17, 17, 17, 0.15);
        padding: 1.625em;
    }

    @media (min-width: 1230px) {
        .profile-offices {
            width: 30.43478%;
            float: right;
            margin-left: 0;
            margin-right: 0;
            clear: none;
            font-size: .95em;
        }
    }

    .profile-image {
        background-color: #f9f9f9;
        border: 1px solid #dddddd;
        border-radius: 4px;
        text-align: center;
        padding: 0.8125em;
        margin-bottom: 1.625em;
    }

    @media (min-width: 600px) {
        .profile-image {
            background-color: transparent;
            border: 0;
            border-radius: 0;
            float: left;
            width: 270px;
            padding: 0;
            margin-bottom: 0;
        }
    }

    .profile-image img {
        margin-bottom: 0.8125em;
    }

    .profile-image .button {
        width: 100%;
        margin-bottom: 0.8125em;
    }

    @media (min-width: 400px) and (max-width: 600px) {
        .profile-image .button {
            width: 80%;
        }
    }

    @media (min-width: 600px) {
        .profile-intro {
            margin-left: 300px;
        }
    }

    .profile-intro__item {
        border-bottom: 1px solid #dddddd;
        padding-bottom: 1.21875em;
        margin-bottom: 1.21875em;
    }

    .profile-intro__item li {
        display: block;
    }

    .profile-intro__item .button {
        margin-bottom: 0;
    }

    .profile-intro__item--appointment {
        text-align: center;
    }

    @media (min-width: 600px) {
        .profile-intro__item--appointment {
            text-align: left;
        }
    }

    .profile-intro__item--last {
        border-bottom: 0;
        padding-bottom: 0;
    }

    .profile-intro__item__title {
        margin-top: 0em;
        margin-bottom: 0.20313em;
        color: #3e4345;
    }

    .profile-intro__contact {
        display: inline-block;
        white-space: nowrap;
        margin-top: 0.40625em;
    }

    .profile-intro__contact a {
        font-weight: 700;
    }

    .profile-offices .link {
        display: block;
        margin: 1.625em 0 1.21875em;
    }

    .profile-office__map {
        display: none;
        width: 100%;
        height: 200px;
        margin-bottom: 0.8125em;
    }

    @media (min-width: 1230px) {
        .profile-office__map {
            display: block;
        }
    }

    .profile-office__item {
        border-bottom: 1px solid #c4c4c4;
        padding-bottom: 0.8125em;
        margin-bottom: 0.8125em;
    }

    .profile-office__item:last-child {
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: 0;
    }

    .profile-office__address {
        font-size: .9em;
        font-style: normal;
        margin-bottom: 0.8125em;
    }

    .profile-office__address__title {
        margin-top: 0em;
        margin-bottom: 0em;
    }

    .profile-office__address__contact {
        font-size: .9em;
    }

    .profile-office__address__contact span {
        font-family: "Montserrat", Arial, Helvetica, sans-serif;
        font-weight: 700;
        font-style: normal;
    }

    .profile-offices__all {
        display: none;
        margin-top: 0.8125em;
    }
    /********************Researcher Detail Overview End    */
    /*******************Researcher Detail Profile Begin */
    .toggle-tabs {
        font-family: "Montserrat", Arial, Helvetica, sans-serif;
        font-weight: 400;
        font-style: normal;
        display: none;
        text-transform: uppercase;
        text-align: center;
    }

    @media (min-width: 1060px) {
        .toggle-tabs {
            display: block;
        }
    }

    .toggle-tabs i {
        display: none;
    }

    .toggle-tabs li {
        float: left;
        width: 33.333%;
    }

    .toggle-tabs li.current {
        background-color: #f9f9f9;
    }

    .toggle-tabs li.current a {
        border-color: #009fda;
        color: #009fda;
    }

    .toggle-tabs a {
        transition: all .3s ease;
        border-top: 3px solid transparent;
        display: block;
        color: #595959;
        cursor: pointer;
        font-size: .8em;
        padding: 1.625em 0;
    }

    @media (min-width: 1230px) {
        .toggle-tabs a {
            font-size: .9em;
        }
    }

    .toggle-tabs a:hover {
        border-color: #dddddd;
        color: #009fda;
    }

    .tab-container {
        display: none;
        border-bottom: 1px solid #dddddd;
    }

    @media (min-width: 1060px) {
        .tab-container {
            border-bottom: 0;
        }
    }

    .tab-container.current {
        display: block;
    }

    .tab {
        border-bottom: 1px solid #dddddd;
    }

    @media (min-width: 1060px) {
        .tab {
            display: none;
        }
    }

    .tab:hover {
        color: #009fda;
    }

    .tab.current {
        background-color: #009fda;
        color: #ffffff;
    }

    .tab.current i {
        transform: rotate(90deg);
    }

    .tab.current a {
        color: #ffffff;
    }

    .tab a {
        font-family: "Montserrat", Arial, Helvetica, sans-serif;
        font-weight: 400;
        font-style: normal;
        color: #595959;
        display: block;
        cursor: pointer;
        text-transform: uppercase;
        padding: 0.8125em;
    }

    @media (min-width: 600px) {
        .tab a {
            padding-left: 1.21875em;
            padding-right: 1.21875em;
        }
    }

    .tab-left {
        margin-bottom: 2.4375em;
    }

    @media (min-width: 1060px) {
        .tab-left {
            width: 47.82609%;
            float: left;
            margin-right: -100%;
            margin-left: 0;
            clear: none;
        }
    }

    @media (min-width: 1230px) {
        .tab-left {
            width: 56.52174%;
            float: left;
            margin-right: -100%;
            margin-left: 0;
            clear: none;
            margin-bottom: 0;
        }
    }

    @media (min-width: 1060px) {
        .tab-right {
            width: 47.82609%;
            float: right;
            margin-left: 0;
            margin-right: 0;
            clear: none;
            margin-top: 0.8125em;
        }
    }

    @media (min-width: 1230px) {
        .tab-right {
            width: 39.13043%;
            float: right;
            margin-left: 0;
            margin-right: 0;
            clear: none;
        }
    }

    .tab-personal span {
        font-family: "Montserrat", Arial, Helvetica, sans-serif;
        font-weight: 700;
        font-style: normal;
    }

    .tab-list {
        font-size: .95em;
        margin-bottom: 1.625em;
    }

    .nested-title,
    .tab-list__title {
        font-size: 1em;
        line-height: 1.42188em;
        margin-top: 0em;
        margin-bottom: 0.20313em;
        font-family: "Montserrat", Arial, Helvetica, sans-serif;
        font-weight: 700;
        font-style: normal;
        display: inline-block;
        text-transform: uppercase;
    }

    .tab-list__title--right {
        float: right;
    }

    .tab-list__items li {
        margin-bottom: 0.40625em;
    }

    .tab-list__items.inline li {
        display: inline-block;
        margin-bottom: 0;
    }

    .tab-list__item {
        position: relative;
        border-bottom: 1px solid #dddddd;
        padding-bottom: 0.40625em;
        margin-bottom: 0.40625em;
    }

    .tab-list__item:last-child {
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: 0;
    }

    .tab-list__item .tab-list__specialty {
        display: block;
    }

    .tab-list__item .tab-list__certified {
        display: block;
        font-size: .9em;
        font-style: italic;
    }

    .tab-list__item .tab-list__status {
        position: absolute;
        top: .5em;
        right: .5em;
        font-size: 1.2em;
        color: #888888;
    }

    .tab-list__item .tab-list__status.accepting {
        color: #a6ce39;
    }

    .profile-intro__item__title {
        font-weight: bold;
    }
    /****************** Researcher Detail Profile End*/
    /******************* Clinical Trial Begin*/
    @media (min-width: 1060px) {
        .l-nested--50 {
            width: 45%;
            float: left;
        }

        .l-nested--50:nth-child(odd) {
            margin-right: 10%;
        }
    }

    .nested-title {
        margin-top: 0.8125em;
    }

    .trials__contact {
        font-size: 0.725em;
        line-height: 1.68103em;
    }

    .nested-container {
        width: 100%;
        display: block;
    }

    .trials__related__block {
        margin: 0;
        padding: 0;
        display: table;
        /*border: solid 1px gray;*/
        border-spacing: 0px;

        .trials__related__block__row {
            display: table-row;

            .trials__list__header {
                display: table-cell;
                white-space: nowrap;
                font-weight: bold;
                width: 190px;
            }

            .trials__list__horizontal_itemList {
                display: table-cell;
                border-spacing: 0px;
                margin-top: 0;

                ul {
                    list-style-type: none;
                    padding-left: 0;

                    li {
                        display: inline;
                    }
                }
            }
        }
    }
    /********************Clinical Trial End***/
    /* featured list */
    .list--block .list__header {
        text-align: center;
    }



    .list__header__title {
        font-size: 1.25em;
        line-height: 1.625em;
        margin-top: 0em;
        margin-bottom: 1.3em;
        color: #3e4345;
        text-align: center;
        padding-right: 0px;
    }

    .list--block--full {
        float: none;
    }

    .list--block {
        float: none;
    }


    .list--block__button {
        text-align: center !important;
        margin-top: 1.625em;
        display: block;
    }
    /* end featured list */
    /*featured researcher component*/
    .featured-researcher {
        padding: 4em 0;
        margin-top: -3.25em;
    }

    .featured-researcher-name {
        margin: 0;
        font-size: 20px;
    }

    .featured-researcher-credentials {
        font-size: 16px;
        display: block;
        margin-top: 0;
    }

    .bicolor-right {
        display: none;
        overflow: hidden;
    }

    @media (min-width: 1060px) {
        .bicolor-right {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            width: 47%;
            height: 100%;
        }

        .bicolor-left {
            width: 47.82609%;
            float: left;
            margin-right: -100%;
            margin-left: 0;
            clear: none;
            min-height: 15em;
        }
    }

    .bicolor-right img {
        max-width: none;
        height: 100%;
    }
    /* end featured researcher */
    /* callouts */
    .limr-research-callouts {
        margin-top: 3.75em;
        padding: 4em 0;
    }
}

/* clinical trial listing*/
.list__item--related-conditions , .list__item--View-additional-details{
    margin-top: 1em;
}



/* clinical trial detail*/

.clinicaltrial {
    @media (min-width: 1060px) {
        .l-nested--50 {
            width: 45%;
            float: left;
        }

        .l-nested--50:nth-child(odd) {
            margin-right: 10%;
        }
    }

    .nested-title {
        margin-top: 0.8125em;
    }

    .trials__contact {
        font-size: 0.725em;
        line-height: 1.68103em;
    }

    .nested-container {
        width: 100%;
        display: block;
    }

    .trials__related__block {
        margin: 0;
        padding: 0;
        display: table;
        /*border: solid 1px gray;*/
        border-spacing: 0px;

        .trials__related__block__row {
            display: table-row;

            .trials__list__header {
                display: table-cell;
                white-space: nowrap;
                font-weight: bold;
                width: 190px;
            }

            .trials__list__horizontal_itemList {
                display: table-cell;
                border-spacing: 0px;
                margin-top: 0;

                ul {
                    list-style-type: none;
                    padding-left: 0;

                    li {
                        display: inline;
                    }
                }
            }
        }
    }
}
