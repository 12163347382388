﻿@keyframes ldio-dcxi2q17gng {
    0% {
        transform: translate(12px,80px) scale(0);
    }

    25% {
        transform: translate(12px,80px) scale(0);
    }

    50% {
        transform: translate(12px,80px) scale(1);
    }

    75% {
        transform: translate(80px,80px) scale(1);
    }

    100% {
        transform: translate(148px,80px) scale(1);
    }
}

@keyframes ldio-dcxi2q17gng-r {
    0% {
        transform: translate(148px,80px) scale(1);
    }

    100% {
        transform: translate(148px,80px) scale(0);
    }
}

@keyframes ldio-dcxi2q17gng-c {
    0% {
        background: #009fda
    }

    25% {
        background: #595959
    }

    50% {
        background: #a6ce39
    }

    75% {
        background: #a6ce39
    }

    100% {
        background: #009fda
    }
}

.ldio-dcxi2q17gng div {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transform: translate(80px,80px) scale(1);
    background-color: transparent;
    animation: ldio-dcxi2q17gng 1s infinite cubic-bezier(0,0.5,0.5,1);
}

.ldio-dcxi2q17gng div:nth-child(1) {
    background: #a6ce39;
    transform: translate(148px,80px) scale(1);
    animation: ldio-dcxi2q17gng-r 0.25s infinite cubic-bezier(0,0.5,0.5,1), ldio-dcxi2q17gng-c 1s infinite step-start;
}

.ldio-dcxi2q17gng div:nth-child(2) {
    animation-delay: -0.25s;
    background: #009fda;
}

.ldio-dcxi2q17gng div:nth-child(3) {
    animation-delay: -0.5s;
    background: #a6ce39;
}

.ldio-dcxi2q17gng div:nth-child(4) {
    animation-delay: -0.75s;
    background: #a6ce39;
}

.ldio-dcxi2q17gng div:nth-child(5) {
    animation-delay: -1s;
    background: #595959;
}

.loadingio-spinner-ellipsis-azlavb4cbo {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background-color: transparent;
}

.ldio-dcxi2q17gng {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}

.ldio-dcxi2q17gng div {
    box-sizing: content-box;
}


#preloaderAnimation {
    position: fixed;
    z-index: 999999;
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: -100px;
}
