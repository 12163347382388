/**
 * @file
 * Default splash
 */

//////////////////////////////////////////
// Splash: Default wrapper styles
//////////////////////////////////////////

.splash {
  position: relative;
  width: 100%;
  //min-height: 10em;
  text-align: center;
}



//////////////////////////////////////////
// Splash: Spanning image
//////////////////////////////////////////

.splash__image {
  position: relative;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;

  .no-flexbox & {
    display:table;
  }

  @include media($x-small-min) {
    // Set before and after dimensions
    &:before,
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      content: '';
    }

    // Overlay defuse
    &:before {
      background-color: rgba($mlh-gray-dark, .2);
    }

    // Overlay bottom gradient
    &:after {
      background: linear-gradient(to bottom, rgba($black, 0) 0%, rgba($black, 0) 50%, rgba($black, .8) 100%);

      .no-cssgradients & {
        background: url('/assets/images/bg--splash__image.png') repeat 0 0;
      }
    }
  }
  @include media($x-small-max){
    background-image: none !important;
  }
}




//////////////////////////////////////////
// Splash: Content wrapper
//////////////////////////////////////////

.splash__content {
  @include branding-bar();
  background-color: $off-white;
  border-bottom: 1px solid lighten($gray-light, 7%);
  color: $mlh-gray-dark;
  padding: rhythm(2) 0;
  position: relative;

  .no-flexbox & {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }
  @include media($x-small-max) {
    min-height: 0 !important;
  }

  @include media($x-small-min) {
    background-color: transparent;
    border-bottom: 0;
    padding: 0;

    // flexbox vertical centering
    align-items: center;
    display:flex;
    flex-direction: row;
    height:10px;

    // Remove the branding bar
    &:before {
      display: none;
    }

    // Animate the content wrapper in on page load
    .js & {
      opacity: 0;
      transition: all 1s .5s ease;
      transform: translateY(1em);
      visibility: hidden;
    }

    .is-dom-ready & {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
      z-index: 2;
    }
  }
}




//////////////////////////////////////////
// Splash: Title styles
//////////////////////////////////////////

.splash__title {
  @include adjust-font-size-to($h1-font-size, 3);
  @include leader(0, $h1-font-size);
  @include trailer(0, $h1-font-size);
  @include sans-cond($weight: 700);

  @include media($x-small-min) {
    @include adjust-font-size-to($xxl-heading, 4);
    @include leader(0, $xxl-heading);
    @include trailer(0, $xxl-heading);
    color: $white;
    text-shadow: 0 4px 7px rgba($black, .3);
  }

  @include media($medium-min) {
    @include adjust-font-size-to($xxxl-heading, 5);
    @include leader(0, $xxxl-heading);
    @include trailer(0, $xxxl-heading);
  }
}

.splash__subtitle {
  @include media($x-small-min) {
    color: $white;
    font-size: 1.6em;
    margin: 0 0 .5em 0;
    text-shadow: 0 4px 7px rgba($black, .3);
  }
}


//////////////////////////////////////////
// Splash: Intro text
//////////////////////////////////////////

.splash__intro {
  margin: rhythm(1) 0 0;

  @include media($x-small-min) {
    color: $white;
    text-shadow: 0 4px 7px rgba($black, .3);
    margin: rhythm(1) 5% 0;
  }

  @include media($medium-min) {
    margin: rhythm(1) 15% 0;
  }
}




//////////////////////////////////////////
// Splash: Button
//////////////////////////////////////////

.splash__content__button {
  margin-top: rhythm(1);

  // @include media($x-small-min) {
  //   margin-top: rhythm(2);
  // }
}




//////////////////////////////////////////
// Splash: Meta
//////////////////////////////////////////

.splash-meta {
  width: 100%;
  margin-top: rhythm(1);

  @include media($small-min) {
    position: absolute;
    bottom: .5em;
    left: 0;
    color: $white;
    text-align: left;
    margin-top: 0;
    z-index: 10;
  }

  @include media($medium-min) {
    .location & {
      bottom: 2.5em;
    }
  }
}




//////////////////////////////////////////
// Splash: Rss button
//////////////////////////////////////////

.rss {
  float: right;
}

.rss-button {
  background-color: $white;
  border-radius: 3px;
  display: inline-block;
  font-weight: 700;
  text-transform: uppercase;
  font-size: .75em;
  color: $mlh-orange;
  padding: 0 rhythm(1);

  &:hover {
    background-color: $mlh-orange;
    color: $white;
  }
}
