/**
 * @file
 * Grid layout options
 */

//////////////////////////////////////////
// Block: General styles
//////////////////////////////////////////
.landing-page {
   
  .l-block--main {
    padding: 0;

    @include media($x-small-min) {
      padding: 0;
    }
  }
}

.landing-page.news,
.landing-page.blog
{
    .l-block--main {
        padding: rhythm(2) 0;

        @include media($x-small-min) {
          padding: rhythm(2) 0;
        }
      }
}



.l-block {
  @extend %clearfix;
  position: relative;
  padding: rhythm(2) 0;

  @include media($x-small-min) {
    padding: rhythm(4) 0;

	.l-block {
		padding: rhythm(2) 0;
	}
  }

  .l-2up--1 {
    margin-bottom: rhythm(3);

    @include media($x-small-min) {
      margin-bottom: 0;
    }
  }
}

// Block map
$block: ('gray', $off-white),
        ('blue-gray', $mlh-blue-gray),
        ('blue', $mlh-blue);

// Block loop
@each $name, $color in $block {

  .l-block--#{$name} {
    background-color: $color;

    &.l-block--border {
      border: 1px solid darken($color, 10%);
      border-width: 1px 0;
    }

    &.l-block--border-top {
      border-top: 1px solid darken($color, 10%);
    }

    &.l-block--border-bottom {
      border-bottom: 1px solid darken($color, 10%);
    }
  }
}

.l-block--mobile {
    @include media($x-small-min) {
        display:none;
    }

}
.l-block--desktop {
    @include media($x-small-max) {
        display:none;
    }
}


//////////////////////////////////////////
// Block: Blue block styles
//////////////////////////////////////////

.l-block--blue {
    color: $white;
    // Change all headers to use white color
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $white;
    }
    // Change link color to white for blue bg
    a {
        color: $white;
        font-weight: 700;
        text-decoration: underline;
        // Remove underline for links with class of button
        &.button {
            text-decoration: none;
        }
        // Remove underline on hover
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
}




//////////////////////////////////////////
// Block: Top with branding Bar
//////////////////////////////////////////

.l-block-top {

    background-color:white;
  @include media($x-small-min) {
    @include branding-bar();
  }
}




//////////////////////////////////////////
// Block: Main with sidebar
//////////////////////////////////////////

.l-block--main {

  .home & {
    padding-top: 4px;  // height of multi-color border
  }

  .l-content {
    position: relative;
    margin-bottom: rhythm(3);
  }

  @include media($x-small-min) {
    .l-content {
      @include grid-span(7, 1);
      margin-bottom: 0;
    }

    .l-sidebar {
      @include grid-span(5, 8);
    }
  }

  @include media($large-min) {
    .l-content {
      @include grid-span(8, 1);
    }

    .l-sidebar {
      @include grid-span(4, 9);
    }
  }
}




//////////////////////////////////////////
// Block: Flipped
//////////////////////////////////////////

.l-block--main--flip {

  @include media($medium-min) {
    .l-content {
      @include grid-span(8, 5);
    }

    .l-sidebar {
      @include grid-span(4, 1);
    }
  }
}




//////////////////////////////////////////
// Block: Centered
//////////////////////////////////////////

.l-block--main {

  .l-content--centered {

    @include media($medium-min) {
      @include grid-span(10, 2);
    }

    @include media($large-min) {
      @include grid-span(8, 3);
    }
  }
}
//////////////////////////////////////////
// Block: 50/50 split of two sub items
//////////////////////////////////////////

.l-block--50 {
    padding: 0;  // no padding due to dynamic components within not showing

    .l-block & {
      padding: 0;
    }

    @include media($small-min) {
        // 1 of 2 elements
        & > :first-child:nth-last-child(2) {
            @include grid-span(6, 1, $gutter: .25);
            //margin: 10px;
        }
        // 2 of 2 elements
        & > :nth-child(2):last-child {
            @include grid-span(6, 7, $gutter: .25);
             //margin: 10px;
        }

        .container {
            margin: 0;
            width: 100%;
        }

    }
}
//////////////////////////////////////////
// Block: Post Content Placeholder
//////////////////////////////////////////
.l-block--postcontent {
	padding:0;
    background-color: white;
}

//////////////////////////////////////////
// Block: postbody
//////////////////////////////////////////

.l-block__noBottomPadding
{
    padding-bottom: 0px !important;
}
.l-block--postbody
{
    background-color: #e7eff2;
}

/** col grid**/

.row {
    width: 100%;
}

.col {
    display: block;

    .col-sm-6 {
        width: 100%;
    }
}

.lg-right {
    text-align: left;
}

@include media($small-min) {
    .col {
        display: flex;

        .col-sm-6 {
            width: 49%;
        }
    }

    .lg-right {
        text-align: right;
    }
}