/**
 * @file
 * Events
 */

//////////////////////////////////////////
// Events: Default
//////////////////////////////////////////

.detail__event-date ~ .page-content {
  padding-left: 3.5em;
  width: auto;

  @include media($xx-large-min) {
    padding-left: 0;
  }
      padding-top: 0.4em;
}

.event-map {
  display: none;

  @include media($medium-min) {
    display: block;
    height:350px;
    overflow:hidden;
  }
}

.events .profile-office__map {
	width: 100%;
	height: 100%;
	margin-bottom: rhythm(1);

	@include media($large-max) {
		display: none;
	}
}



//////////////////////////////////////////
// Event: Grouped
//////////////////////////////////////////

.event-group {
  background-color: $off-white;
  box-shadow: 0 1px 1px rgba($black, .15);
  padding: rhythm(2) rhythm(1);
  margin: rhythm(2) 0;

  @include media($x-small-min) {
    padding: rhythm(3);
  }
}
