// /**
//  * @file
//  * Forms
//  */

//////////////////////////////////////////
// Forms: Default
//////////////////////////////////////////

// Center forms at larger viewport
.form-center {
  @include media($medium-min) {
    //@include grid-span(10, 2);
  }
}

.form-inline {
  @extend %clearfix;
  border-bottom: 1px solid $gray-light;
  padding-bottom: rhythm(1);
  margin-bottom: rhythm(2);
}

.form-inline--last {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.form-inline--no-border {
  border-bottom: 0;
}

.form-inline__label {

  // Float label to the left
  @include media($medium-min) {
    float: left;
    width: 20%;
    margin-top: rhythm(.75);
  }
}

.form-inline__group {
  @extend %clearfix;

  // Float field group to right
  @include media($medium-min) {
    float: right;
    width: 75%;
  }

  .form-item {
    margin-bottom: rhythm(1);

    @include media($medium-min) {
      float: left;
      margin-right: rhythm(1);
    }
  }
}

// Float form items
.form-item--left {

  @include media($x-small-min) {
    float: left;
    width: 48%;
  }
}

.form-item--right {
  @include media($x-small-min) {
    float: right;
    width: 48%;
  }
}

.form-item--checkbox-info {
  position: relative;

  input,
  label,
  figure {
    display: inline-block;
    margin: 0 .5em 0 0;
    vertical-align: middle;

    &:hover {
      figcaption {
        visibility: visible;
      }
    }
  }

  img {
    margin-top:-4px;
    vertical-align: middle;
  }

  input:hover + figure,
  label:hover + figure,
  figure:hover {
    figcaption {
      visibility: visible;
      opacity: 1;
    }
  }

  input {
    float: none;
    top: 0;
  }

  figcaption {
  	background-color: #e7eff3;
    border-radius: 3px;
    border: 1px solid #b3c4cd;
    font-size: .75rem;
    font-style: normal;
    max-width: 300px;
    padding: 10px;
    position: absolute;
    left: 40px;
    opacity: 0;
    bottom: calc(100% + 10px);
    transition: all 500ms ease-in-out 500ms;
    visibility: hidden;
    width: 100%;
    z-index: 99;

    a {
        transition: none;
    }
  }
  /*&:hover {
  figcaption {
  visibility: visible;
  }
  }*/
}

// Set large fields
.field-lg {
  width: 100%;

  @include media($x-small-min) {
    width: 95%;
  }
}

// Set medium fields
.field-med-ext{
    width: 350px;
}

.field-med {
  width: 250px;
}

// Set small fields
.field-sm {
  width: 150px;
}

select.form-item--invalid ,
select.form-item--invalid:hover,
select.form-item--invalid:focus,
.form-item--invalid input,
.form-item--invalid input:hover,
.form-item--invalid input:focus,
input.form-item--invalid:focus,
input.form-item--invalid:hover,
input.form-item--invalid {
    color: $input-invalid-text;
  border-color: $input-invalid-border;
}
fieldset.form-item--invalid {
    border: 1px solid $input-invalid-border;
    border-radius: 8px;
}


//////////////////////////////////////////
// Forms: Floating
//////////////////////////////////////////

.form-flex {
  display: flex;
	@include media($x-small-max) {
		flex-direction: column;
	}
}

.form-flex .form-left,
.form-flex .form-middle,
.form-flex .form-right {
  flex: 1;
  -ms-flex: 0 0 auto;
  width: auto;
  padding-top: 0px;
  padding-right: 40px;
  text-transform: none;
  font-size: 16px;
  text-align: left;
  &::before { display: none; }
	@include media($x-small-max) {
    padding-right: 0px;
    padding-bottom: 10px;
	}
}

.form-flex .form-right {
  padding-right: 0;
}

.form-left,
.form-middle,
.form-right,
.form-third {
  @extend %clearfix;
}

.form-left {
  @include media($x-small-min) {
    float: left;
    width: 45%;
  }
}

.form-middle {
  position: relative;
  font-size: .9em;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  margin: rhythm(1) 0;

  &:before {
    display: block;
    content: '';
    border-top: 1px solid $gray-light;
    width: 100%;
    position: absolute;
    top: 10px;
  }

  span {
    position: relative;
    display: inline-block;
    background-color: $white;
    color: $gray-medium;
    text-align: center;
    padding: 0 rhythm(.5);
  }

  @include media($x-small-min) {
    float: left;
    width: 10%;
    padding-top: rhythm(3.5);
    margin: 0;

    &:before {
      display: none;
    }
  }
}

.form-right {
  @include media($x-small-min) {
    float: right;
    width: 45%;
  }
}

.form-third {
  @include media($x-small-min) {
    float: left;
    width: 30%;
    margin-right: 3%;
  }
}



//////////////////////////////////////////
// Forms: Radio Group
//////////////////////////////////////////

.radio-group {
  @extend %clearfix;

  // Hide the browser default radio button
  input[type="radio"] {
    @extend %element-invisible;

    // Style the label
    + label {
      @include sans();
      position: relative;
      display: block;
      float: left;
      background-color: $mlh-blue-gray;
      border: 1px solid $mlh-blue-gray-dark;
      border-width: 1px 1px 1px 0;
      text-transform: none;
      letter-spacing: 0;
      color: $text-color;
      vertical-align: middle;
      cursor: pointer;
      padding: .64em .94em;
      margin: 0;

      // Add rounded corners to the first label
      &:first-of-type {
        border-radius: $global-radius 0 0 $global-radius;
        border-left: 1px solid $mlh-blue-gray-dark;
      }

      // Add rounded corners to the last label
      &:last-of-type {
        border-radius: 0 $global-radius $global-radius 0;
      }
    }
  }

  // Style the checked label
  input[type="radio"]:checked + label {
    background-color: $mlh-blue;
    color: $white;
  }

  // Checked transition
  input[type="radio"] + label,
  input[type="radio"]:checked + label {
    transition: all .3s ease;
  }
}




//////////////////////////////////////////
// Forms: Browse by
//////////////////////////////////////////

.form-inline__browse {

    @include media($medium-min) {
        margin-top: rhythm(1);
    }

    &.filter__browse
    {
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            /* IE10+ CSS styles go here */
            width: 400px;
        }
    }
}

.form-more-options {
  display: none;
  background-color: $off-white;
  border: 1px solid $gray-light;
  border-radius: $global-radius;
  padding: rhythm(2);
  margin-bottom: rhythm(2);
}


//////////////////////////////////////////
// Select2 overrides
//////////////////////////////////////////

.select2-container {
  z-index:99;
}

// Overriding the inline styles of Select2.
// There were positioning issues when changing the parent
// of the Select2 dropdown. But still, I'm sorry for the !importants
.select2-wrapper > .select2-container {
  top: auto !important;
  left: auto !important;
}

.select2-container--open .select2-dropdown--above {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, 
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

//////////////////////////////////////////
// Epic doctor appointment form
//////////////////////////////////////////

.out > div{
    display:inline-block;
}


textarea.form-item--invalid:hover,
textarea.form-item--invalid:focus,
textarea.form-item--invalid{
    color: $input-invalid-text;
  border-color: $input-invalid-border;
}


/* appointemtn prototype overrides*/

.appointment-form__concise {

    .form-inline__concise {

        @extend %clearfix;
        border-bottom: 1px solid $gray-light;
        padding-bottom: rhythm(0);
        margin-bottom: rhythm(0);

        > .form-inline__label, > .form-inline__group {
            width: 100%;
        }

        > .form-inline__column50 {
            width: 100%;

            > .form-inline__label {
                width: 25% !important;
            }

            > .form-inline__group {
                width: 75% !important;
            }
        }

        @include media($medium-min) {
            > .form-inline__column50 {
                width: 49.5%;
                display: inline-block;

                > .form-inline__label {
                    width: 25% !important;
                }

                > .form-inline__group {
                    width: 75% !important;
                    margin-top: rhythm(1);
                }
            }

            > .form-inline__label {
                width: 22%;
            }

            > .form-inline__group {
                width: 78%;
                margin-top: rhythm(1);
            }

            input[type='text']{
                width: 100%;
            }
        }
    }
}