// /**
//  * @file
//  * Featured content
//  */

//////////////////////////////////////////
// Featured: Outer wrapper
//////////////////////////////////////////

.featured {
	margin: rhythm(3) 0 0;

	@include media($medium-min) {
		margin: rhythm(4) 0;
	}

	.list--featured-hero {
		.list__type {
			display: none;
		}
	}
}

.home {
	.featured--news,
	.featured--events {
		margin-top:0;
	}
}


.l-block--50 > :first-child:last-child,
.l-block--50 > :first-child:nth-last-child(n+3),
.l-block--50 > :first-child:nth-last-child(n+3) ~ * { // not two elements which renders 50/50
	&.featured,
	& .featured {
		.list--featured-hero .list__header {
			text-align: left;
			margin-bottom: rhythm(2);
		}
		.list__hero-image {
			display:none;
		}
		.list--featured-hero {
			.list__meta {
				margin-bottom: inherit;
				padding: 0;
				text-align: left;
				width: 100%;
			}

			.list__type {
				display: inline;
			}
		}
	}
}

//////////////////////////////////////////
// Featured: Text block with image
//////////////////////////////////////////
.image-block {
	@extend %clearfix;
	margin: 2.4375em 0 0.8125em;

	.image-block__image-container {
		text-align: center;

		img {
			margin: 0 auto rhythm(1);
			max-width: 170px;
			width: 100%;
		}

		@include media($xx-small-min) {
			float: left;
			margin: 0 rhythm(2) 0 0;
		}
	}

	.image-block__text-container {

		@include media($xx-small-min) {
			margin: 0 0 0 rhythm(15);
		}
	}

	.image-block__title {
		margin-top: 0.8125em;

		@include media($xx-small-min) {
			margin-top: 0;
		}
	}

	.image-block__cta {
		margin: 0 0 rhythm(1);

		a {
			font-size: .9em;
			font-weight: bold;
			text-transform: uppercase;
		}
	}
}
