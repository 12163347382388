﻿body.limr {
    .followUsIcons
    {
        display: inline-block;

        ul
        {
            list-style-type: none;
            padding: 0px;
            margin: 0px;

            li
            {
                width: 25px;
                display: inline-block;
                margin: 3px 7px 3px 3px;
            }
        }
    }
}
