// Sass color variables, etc.
$black:               #111111;
$gray-dark:           #444444;
$gray-medium:         #888888;
$gray-light:          #dddddd;
$off-white:           #f9f9f9;
$white:               #ffffff;
$white-60:            rgba($white, .6);
$white-30:            rgba($white, .3);
$yellow:              #f5c023;
$orange:              #ff6600;
$red:                 #ff0000;
$red-dark:            #a20909;

// MLH branding colors
$mlh-blue:            #0098d1;
$mlh-blue-wcag2:      #00739d;
$mlh-blue-link:       #00739d;
$mlh-blue-dark:       #0082c1;
$mlh-green:           #a6ce39;
$mlh-green-dark:      #89b60d;
$mlh-orange:          #ff7900;
$mlh-gray-light:      #818a8f;
$mlh-gray-medium:     #6f767a;
$mlh-gray-dark:       #3e4345;
$mlh-gray-darker:     #1e1e1e;
$mlh-blue-gray:       #e7eff3;
$mlh-blue-gray-dark:  #d2dce1;
$mlh-tan:             #fbf9f5;
$mlh-tan-dark:        #c5ba9e;
$mlh-gray-lighter:    #D9D9D9;

// MLH logo colors
$mlh-logo-color1: #60c4e5;
$mlh-logo-color2:     #00b4e0;
$mlh-logo-color3:     #00a3db;
$mlh-logo-color4:     #84bf41;
$mlh-logo-color5:     #a2ca56;
$mlh-logo-color6:     #c4d978;

// Zoc Doc
$zocdoc-color1:       #fff04b;

// Social branding
$facebook:          #3b5998;
$twitter:           #55acee;
$youtube:           #cd201f;
$linkedin:          #0e76a8;
$instagram:         #8a3ab9;
// Use above sass variables to define defaults in normalize.scss
/* body */
$text-color: #595959;
$background-color: $white;
/* Link (a:) */
$link-color: $mlh-blue-link;
$link-hover: $mlh-blue-dark;
/* Blockquote*/
$blockquote-color: $text-color;
/* Figcaption */
$figcaption-color: $gray-medium;
/* Global Radius */
$global-radius: 8px;
/* Forms */
$form-text: $gray-dark;
$input-border-color: darken($mlh-blue-gray-dark, 10%);
$input-border-hover: darken($mlh-blue-gray-dark, 20%);
$input-border-focus: $mlh-blue;
$input-not: $gray-medium;
$input-not-background: $gray-light;
$input-not-border: $gray-medium;
$input-not-hover: $gray-dark;
$input-focus-outline: $gray-dark;
$input-disabled-background: $gray-light;
$input-disabled-text: $gray-medium;
$input-disabled-not-background: $gray-light;
$input-disabled-not-text: $gray-medium;
$input-readonly-text: $gray-medium;
$input-readonly-background: $gray-light;
$input-readonly-border: $gray-medium;
$input-invalid-text: #b94a48;
$input-invalid-border: #ee5f5b;
$input-invalid-border-focus: #e9322d;
$input-check-outline: #e9322d;
$select-background: $mlh-blue-gray;
$select-border: darken($mlh-blue-gray-dark, 10%);
$select-border-hover: darken($mlh-blue-gray-dark, 20%);
$placeholder-text: #999999;
/* Buttons */
$button-background: $gray-medium;
$button-text: $white;
$button-hover-background: $gray-dark;
$button-hover-text: $white;
$button-focus-outline: $gray-dark;
$button-reset-text: $gray-medium;
$button-reset-text-hover: $gray-dark;
///////////////////////////////////////////////////////////

/* list header title*/
$list-header-title:#B25600;