/**
 * @file
 * Header dropdowns
 */

//////////////////////////////////////////
// Header Dropdown: Container position
//////////////////////////////////////////
.site-header__dropdowns {
    @extend %clearfix;
    position: relative;

    #tab2 .nrc_overall_reviews_stat, #tab3 .nrc_overall_reviews_stat, #tab4 .nrc_overall_reviews_stat {
        display: none;
    }
}

//////////////////////////////////////////
// Header Dropdown: Default styles
//////////////////////////////////////////
.site-header__dropdowns__item {
  transition: all .3s ease;
  transform: translateY(-50px);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $white;
  box-shadow: 0 15px 20px rgba($black, .2);
  opacity: 0;
  visibility: hidden;
  padding: rhythm(3) 0;
  &.active {
    transition: all .3s .5s ease;
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
}

//////////////////////////////////////////
// Header Dropdown: Set grid
//////////////////////////////////////////
@include media($x-small-min) {
  @include layout($gutter: 2) {
    .dropdown__text {
      @include grid-span(5, 1);
    }
    .dropdown__filters, .topMenu {
      @include grid-span(7, 6);
    }
  }
}

//////////////////////////////////////////
// Header Dropdown: Styles
//////////////////////////////////////////
.dropdown__wrap {
  position: relative;
  margin-bottom: rhythm(3);
  &:last-child {
    margin-bottom: 0;
  }
}

.dropdown__wrap--search {
  margin-bottom: rhythm(3);
}

.dropdown__text,
.dropdown__filters {
  padding: rhythm(2) 0;
}

// Add border between text and filters
.dropdown__filters, .topMenu {
  border-left: 1px solid $mlh-blue-gray-dark;
  padding-left: rhythm(4);
}

// Dropdown title styles
.dropdown__text__title {
  @include adjust-font-size-to($h2-font-size);
  @include leader(0, $h2-font-size);
  @include trailer(1, $h2-font-size);
  @include sans-cond();
}

// Custom SubMenu Styles
.topMenu {
    &.menuGroup {
        display: flex;

        .menuColumn {
            padding: 0 1em;

            &.col1 {
                padding-right: rhythm(4);
            }

            &.col2 {
                border-left: 1px solid $mlh-blue-gray-dark;
                padding-left: rhythm(4);
            }

            .colTitle {
                text-transform: uppercase;
                font-weight: bold;
                padding-bottom: 0.5em;
            }

            .menuList {
                padding: 0px;
                list-style: none;

                li {
                    padding: 1em 0em;
                }
            }
        }
    }
}

// Text search styles
.dropdown__search {
  @include clearfix;
}

// Browse by styles
// .dropdown__browse {
//   @extend %no-bullets;
//   // Inline letter list
//   li {
//     display: inline-block;
//     width: 20px;
//     font-size: 1.2em;
//     font-weight: 700;
//     text-align: center;
//   }
// }
// OR text
.dropdown__or {
  position: absolute;
  bottom: -36px;
  width: 100%;
  display: block;
  font-size: .9em;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  span {
    position: relative;
    display: inline-block;
    background-color: $white;
    color: $gray-medium;
    text-align: center;
    padding: 0 rhythm(.5);
  }
  &:before {
    display: block;
    content: "";
    border-top: 1px solid $gray-light;
    width: 100%;
    position: absolute;
    top: 12px;
  }
}

// Top view list styles
.dropdown__list {
  ul {
    @extend %no-bullets;
    column-count: 2;
    font-size: .9em;
    margin-bottom: rhythm(1);
  }
  li {
    break-inside: avoid-column;
  }
  a {
    display: block;
    border-bottom: 1px dashed $mlh-blue-gray-dark;
    padding: rhythm(.3) rhythm(.5);
    &:hover {
      background-color: lighten($mlh-blue-gray, 5%);
    }
  }
}

.dropdown__typeahead {
  position: relative;
  z-index: 1;
}

.dropdown__typeahead__btn {
  width: 100%;
  text-align: left;
  background-color: $mlh-blue-gray;
  border-color: $mlh-blue-gray-dark;
  color: $mlh-gray-dark;
  position: relative;
  font-weight: 400;
  text-transform: none;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;

  &:hover {
    color: $mlh-gray-dark;
  }

  &:after {
    content: '\25BE';
    position: absolute;
    display: block;
    right: 10px;
    color: $mlh-gray-dark;
    top: 50%;
    transform: translateY(-50%);
  }
  &.is-active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    &:after {
      content: '\25B4';
    }
  }
}

.dropdown__typeahead__menu {
  display: none;
  border: 1px solid $gray-light;
  background-color: $off-white;
  padding: 10px 10px 0px;
  position: absolute;
  width: 100%;

  .dropdown--container.dropdown--carousel {
    left: 0px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: 1px;
  }
}

// Gloabl search
@include media($x-small-min) {
  .dropdown__global-search {
    @include grid-span(8, 3);
  }
}

.dropdown__global-search--small {
  position: relative;
}

// Dropdown title styles
.dropdown__title__center {
  @include adjust-font-size-to($h2-font-size);
  @include leader(0, $h2-font-size);
  @include trailer(1, $h2-font-size);
  @include sans-cond();
  text-align: center;
}

.dropdown__global-search__form {
  font-size: 1.3em;
}

// Appointment dropdown styles
.dropdown__appointment-item {
  text-align: center;
  .tel {
    display: block;
    font-weight: 700;
    margin-top: rhythm(.5);
  }
}

.dropdown__appointment-item__title {
  @include adjust-font-size-to($h4-font-size);
  @include leader(2, $h4-font-size);
  @include trailer(.5, $h4-font-size);
  @include sans-gotham($weight: 700);
}

//////////////////////////////////////////
// Global Search Dropdown: Styles
//////////////////////////////////////////
.dropdown--container {
  background: $white;
  border-radius: 10px;
  box-shadow: 0 7px 15px rgba($black, .2);
  font-size: .8rem;
  max-height: calc(100vh - 280px);
  overflow: auto;
  padding: 1em;
  position: absolute;
  width: 100%;
  z-index: 2;
  .list__header {
    margin-bottom: .85em;
  }
  .list__item--doctor .list__img {
    width: 45px;
  }
  .list__item--doctor .list__content.has-img {
    margin-left: 55px;
  }
}

.dropdown--carousel {
  @include media($medium-max) {
    max-height: calc(100vh - 200px);
    padding: 2em;
  }
  @include media($x-small-max) {
    max-height: calc(100vh - 180px);
  }

      /* one item */
    > div:first-child:nth-last-child(1) {
    /* -or- li:only-child { */
        width: 100%;
    }

    /* two items */
    > div:first-child:nth-last-child(2),
    > div:first-child:nth-last-child(2) ~ li {
        width: 48%;
    }

  p {
    margin: 0;
  }
  .l-2up--1 {
    clear: left;
  }
  .l-2up--2 {
    clear: right;
  }
  .sidebar--block {
    margin-bottom: rhythm(1.5);
  }
  .list {
    margin-bottom: 2em;
  }
  .list__item {
    padding-bottom: rhythm(.5);
    margin-bottom: rhythm(.5);
    .list__address {
      margin-bottom: 0;
    }
    .list__img {
      width: 33px;
    }
    .list__content {
      &.has-img {
        margin-left: 43px;
      }
    }
  }
  .list__item--simple {
    letter-spacing: 0;
    a {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      &.link--news {
        line-height: 1.4;
      }
    }
    span {
      display: block;
      font-size: .85em;
      line-height: rhythm(1.5);
    }
  }
  .list__item--location {
    padding: 0 0 rhythm(.5) 0;
    margin-bottom: rhythm(.5);
    .list__img {
      width: 70px;
      margin-top: .4em;
    }
    .list__content {
      &.has-img {
        margin-left: 80px;
      }
    }
  }
}