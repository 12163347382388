﻿.HTMLBlock
{
    margin: 0em;
}

.shadow {
    -webkit-box-shadow: 0 5px 15px rgba(17,17,17,0.15);
    -moz-box-shadow: 0 5px 15px rgba(17,17,17,0.15);
    box-shadow: 0 5px 15px rgba(17,17,17,0.15);
}

.noPadding {
    padding: 0px;
}

.noMargin {
    margin: 0px;
}

.alignRight {
    float: right;
}

