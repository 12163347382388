/**
 * @file
 * Define singularity gs base settings here.
 * @see https://github.com/at-import/Singularity
 */

//////////////////////////////////////////
// Singularity: Setup
//////////////////////////////////////////

// Debug mode: Set to true for background grid overlay.
@use "sass:math";

@include sgs-change('debug', false);

// Set default grid for each breakpoint.
@include add-grid(1 1);
@include add-grid(12 at $x-small-min);

// Set default gutter for each breakpoint.
@include add-gutter(math.div(1, 6));
@include add-gutter(1 at $x-small-min);




//////////////////////////////////////////
// Singularity: Centered container
//////////////////////////////////////////

.container {
  @extend %clearfix;
  @include background-grid($color: rgba($red, .2));
  width: 100%;
  padding: 0 rhythm(1);
  margin: 0 auto;
  min-width: 260px; /* enforces a min-width in FF. */

  @include media($x-small-min) {
    padding: 0 rhythm(1.5);
  }

  @include media($medium-min) {
    width: 95%;
    padding: 0;
  }

  @include media($x-large-min) {
    max-width: 1300px;
  }
}
