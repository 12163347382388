/**
 * @file
 * Layout rules for one third / two thirds (33/66) content
 */

.l-33-66--1 {
  @include media($x-small-min) {
    @include isolation-span(4, 1, 'both');
  }
}

.l-33-66--2 {
  @include media($x-small-min) {
    @include isolation-span(8, 5);
  }
}
