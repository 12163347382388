// /**
//  * @file
//  * Pager
//  */

//////////////////////////////////////////
// Pager
//////////////////////////////////////////

.pager {
  @extend %clearfix;
  text-align: center;
}

.pager--top,
.pager:first-child {
  margin-bottom: rhythm(3);
}

.pager--bottom,
.pager:last-child {
  margin-top: rhythm(3);
}

.pager__querycorrection {
  @include sans-gotham($weight: 700);
  line-height: 1.8em;
  font-size: .85em;
  text-transform: uppercase;
  margin-bottom: rhythm(1);
  clear: both;

  .pager__querycorrection-term {
    text-decoration: underline;
  }

  @include media($x-small-min) {
    float: left;
    margin-bottom: 0;
  }
}

.pager__amount {
  @include sans-gotham($weight: 700);
  line-height: 1.8em;
  font-size: .85em;
  text-transform: uppercase;
  margin-bottom: rhythm(1);

  @include media($x-small-min) {
    float: left;
    margin-bottom: 0;
  }
}

.pager__pagination {
  @extend %no-bullets;

  @include media($x-small-min) {
    float: right;
  }

  li {
    display: inline-block;

    &.current {
      padding: rhythm(.25) rhythm(.5);
    }
  }

  a {
    display: block;
    background-color: $white;
    border: 1px solid $gray-light;
    border-radius: $global-radius;
    padding: rhythm(.25) rhythm(.75);
    cursor: pointer;

    &:hover {
      background-color: $mlh-blue;
      border-color: $mlh-blue;
      color: $white;
    }
  }
}
