/**
 * @file
 * Modal bg overlay
 */

//////////////////////////////////////////
// Background Overlay: Default
//////////////////////////////////////////

.bg-overlay, .site-bg-overlay {
  transition: all 1s ease;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(darken($mlh-gray-dark, 10%), .75);
  opacity: 0;
  visibility: hidden;
  

  .no-rgba & {
    background: url('/assets/images/bg--bg-overlay.png') repeat 0 0;
  }

  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.bg-overlay {
    z-index: 99;
}

.site-bg-overlay {
    z-index: 199;
    margin: 0em auto;
}

