/**
 * @file
 * Your local health system
 */

//////////////////////////////////////////
// Health System: Default
//////////////////////////////////////////

.health-system {
  background: transparent url('bg--health-system.jpg') no-repeat center;
  background-size: cover;
  text-align: center;
  @if support-legacy-browser(ie, '8') {
    -ms-behavior: url('../backgroundsize.min.htc');
  }

  @include media($x-small-min) {
    background-attachment: fixed;
  }
}




//////////////////////////////////////////
// Health System: Title
//////////////////////////////////////////

.health-system__title {
  @extend %track-in-extra;
  @include adjust-font-size-to($h1-font-size, 3);
  @include leader(0, $h1-font-size);
  @include trailer(2, $h1-font-size);
  @include sans-cond();

  @include media($x-small-min) {
    @include adjust-font-size-to($xl-heading, 3);
    @include leader(0, $xl-heading);
    @include trailer(2, $xl-heading);
  }
}




//////////////////////////////////////////
// Health System: Stats
//////////////////////////////////////////

.health-system__stats {
  @extend %no-bullets;
  transition: all 1s ease;
  padding: 0 0 rhythm(2);

  @include media($medium-min) {

    &--hide {
      transform: translateY(2em);
      opacity: 0;
    }

    &--show {
      transform: translateY(0);
      opacity: 1;
    }
  }

  li {
    display: inline-block;
    background: rgba($white, .2);
    border: 1px solid $mlh-tan-dark;
    border-radius: 4px;
    width: 100%;
    padding: rhythm(.5) rhythm(2);
    margin-bottom: rhythm(1.25);

    @include media($x-small-min) {
      width: 18em;
      margin: 0 rhythm(.5) rhythm(1.25);
    }

    @include media($x-large-min) {
      width: auto;
      margin: 0 rhythm(.5);
    }
  }

  .stat {
    @include sans-gotham($weight: 700);
    display: block;
    font-size: 4em;
    line-height: 1;
    color: $mlh-green;
  }

  .stat-label {
    @include sans-gotham($weight: 700);
    @include adjust-font-size-to($label, 1);
    color: $mlh-gray-dark;
    text-transform: uppercase;
  }
}
