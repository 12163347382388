/**
 * @file
 * Home splash
 */

//////////////////////////////////////////
// Home Splash: Updated content styles
//////////////////////////////////////////

.splash__content--home {
	background-color: transparent;
	border-bottom: 0;
	display:block;
	height: auto;

	@include media($x-small-min) {
		position: absolute;
		bottom: rhythm(5);
		left: 0;
		width: 100%;
		//background-color: transparent;
		padding: 0;
	}

	@include media($medium-min) {
		bottom: 15vh;
	}
}




//////////////////////////////////////////
// Home Splash: Change title styles
//////////////////////////////////////////

.splash__title--home {
	@extend %track-in-extra;
	@include trailer(1, $h1-font-size);
	font-weight: 400;
	color: $white;

	@include media($x-small-min) {
		@include adjust-font-size-to($xxl-heading, 3);
		@include leader(0, $xxl-heading);
		@include trailer(2, $xxl-heading);
	}
}




//////////////////////////////////////////
// Home Splash: Update image styles
//////////////////////////////////////////

.splash__image--home {
	min-height: 15em;

	@include media($x-small-min) {
		min-height: 28em;
	}

	@include media($medium-min) {
		min-height: 30em;
	}

	@include media($large-min) {
		min-height: 34em;
		height: calc(80vh - 95px); // height of header
	}

	.splash__image-background {
		background-position: center top;
		background-repeat: no-repeat;
		background-size: cover;
		opacity: 1;
		position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		transition: opacity 1s;

		&:nth-child(2) {
			opacity: 0;
		}
		&:before {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: block;
			content: '';
			background-color: rgba($mlh-gray-dark, .9);
		}
		
		@include media($x-small-min) {
			// Remove default splash overlay defuse
			&:before {
				display: none;
			}

			// Update bottom gradient
			&:after {
				background: linear-gradient(to bottom, rgba($black, 0) 0%, rgba($black, 0) 20%, rgba($black, .2) 30%, rgba($black, .5) 100%);

				.no-cssgradients & {
					background: url('/assets/images/bg--splash__image--home.png') repeat-x bottom left;
				}
			}
		}
	}
}

