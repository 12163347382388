/*#content
{
    &.ShiftDown
    {
        margin-top:0px;

        @include media($medium-min) {
            margin-top:35px;
        }
    }
}*/

.page-content {
	@extend .container;
	@extend %clearfix;
	padding-top: rhythm(2);
	padding-bottom: rhythm(2);

	@include media($x-small-min) {
		padding-top: rhythm(1);
		padding-bottom: rhythm(1);
	}

    .article.blog.blog-post-detail & ,
    .article.news.news-detail &,
    .article.news.patient-spotlight & {
        padding-top: 0px !important;
    }

	&:first-child {
	  padding-top: 0;
	}

	&:last-child {
	  padding-bottom: 0;
	}

	.container & {
		background:none;
		padding-left: 0;
		padding-right: 0;
		width: 100%;
	}

	// first element in the RTE
	& > :first-child {
		margin-top:0;
	}
	// last element in the RTE
	& > :last-child {
		margin-bottom:0;
	}

	.headshot-row-image
	{
		box-sizing: border-box;
		display: inline-block;
		margin-right: -0.125em; //compensate for whitespace
		min-width: 180px;
		padding: 0px 20px 25px;
		vertical-align: top;
		width: 100%;

		@include media($xx-small-min) {
			width: 49%;
		}
		@include media($x-small-min) {
			width: 33%;
		}
		@include media($large-min) {
			width: 25%;
		}

		.l-block--main & {
			@include media($x-small-min) {
				width: 49%;
			}

			@include media($large-min) {
				width: 33%;
			}
		}

		img {
			padding: 10px 0px 5px 0px;
		}
	}
	
	.l-block--postcontent &{
	    padding-bottom: rhythm(4);
		padding-top: rhythm(2);
	
  }
	li{
		padding-bottom: .5em
	}

    a{
        text-decoration:underline;
    }

    a:hover, a:focus, a:active {
        text-decoration: underline;
    } 

    .button {
        text-decoration: none !important;
    }
}
