﻿
/* video player */

.vdoPlayer-bg
{
    display:block; 
    width:100%;  
    position: absolute;
}

.vdo-player-media
{
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
}

.vdo-player-media iframe
{
    left:75px;
    top:20px;
    height:calc(100% - (2*20px));
    width:calc(100% - (2*75px));
    position:absolute;
}

