/**
 * @file
 * Top specialties
 */

//////////////////////////////////////////
// Top Specialties: Default
//////////////////////////////////////////

.top-specialties__list {
  @extend %no-bullets;
  column-count: 2;
  font-size: .9em;
  margin-bottom: rhythm(2);

  li {
    break-inside: avoid-column;
  }

  a {
    display: block;
    border-bottom: 1px dashed $mlh-blue-gray-dark;
    padding: rhythm(.3) rhythm(.5);

    &:hover {
      background-color: lighten($mlh-blue-gray, 5%);
    }
  }
}
