/**
 * @file
 * Site typography
 */

//////////////////////////////////////////
// Type: Continue reading link
//////////////////////////////////////////

.link {
  @extend %track-out;
  @include sans-gotham($weight: 700);
  @include adjust-font-size-to($meta, 1);
  text-transform: uppercase;
  white-space: nowrap;

  &.full {
    color: $red-dark;
  }

  &.no-reg {
    color: $text-color;
  }
}

.link--text {
  display: block;
  color: $mlh-blue;
}

.link-group {
  @extend %no-bullets;

  li {
    display: inline-block;
    margin-right: rhythm(.5);

    @include media($medium-min) {
      margin-right: rhythm(1);
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

//////////////////////////////////////////
// Type: hidden links - for mobile
//////////////////////////////////////////
.link-subtle {
  color: inherit;
  cursor: default;
  font-size: inherit;
  font-weight: inherit;
  text-decoration: inherit;

  &:hover {
    color: inherit;
  }
}

//////////////////////////////////////////
// Type: Leade style
//////////////////////////////////////////

.lede-header {
  margin-top: 0;
}

.lede-text {
  @include slab();
  font-size: 1em;
  line-height: 1.8;
  margin: 0 0 rhythm(2);
}





//////////////////////////////////////////
// Type: Form help text
//////////////////////////////////////////

.help {
    font-size: .9em;
    font-style: italic;
    margin: rhythm(.5) 0;

    a {
        text-decoration: underline;
    }

    a:hover, a:focus {
        text-decoration: underline !important;
    }
}




//////////////////////////////////////////
// Type: Button styles in content area
//////////////////////////////////////////

.l-content {

  .button {
    margin-bottom: rhythm(2);
  }
}

//////////////////////////////////////////
// Type: Error message
//////////////////////////////////////////

.error-message {
  color: $red-dark;
}

//////////////////////////////////////////
// Type: Removes X from inputs in IE
//////////////////////////////////////////

input::-ms-clear {
    display: none;
}


//////////////////////////////////////////
// Type: Removes X from inputs in IE
//////////////////////////////////////////

.superscripted {
    font-size: 75%;
    line-height: 1.4em;
    position: relative;
    vertical-align: baseline;
    top: -0.5em;
}