﻿#vdoPlaceHolder {
    width: 100%;
    margin:0px;
}

.vdoWrapper {
    background-color: black;
    display: table;
    width: 100%;
    padding: 1em;
}

.vdoFrame {
    background-color: black;
    width:100vw;
    height: 99vh;
    display: table-cell;
}

.vdoMargin {
    text-align: center;
    display: table-cell;
    vertical-align: top;
    min-width: 40px;

    &.closeButtonFrame {
        color: white;
        margin-top: 10px;

        & > .fa-close {
            color: white;
            font-size: 1em;
            cursor: pointer;
            transition: all 1s;

            &:hover {
                color: $mlh-gray-dark;
            }
        }
    }
}

@media (min-width: 1200px)
{
    .vdoMargin {
        min-width: 150px;

        &.closeButtonFrame {
            & > .fa-close {
                font-size: 3em;
            }
        }
    }

    
}



.btn-newBrand {
    width: 230px;
}

.message {
    font-family: freight-display-pro, serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1.8em;
    padding: 20px;
    line-height: 0.9em;
    color: black;
}

.watchSpotButton {
    padding: 20px;
}

@media (min-width: 400px) {
    .message {
        font-size: 2em;
    }

    .watchSpotButton {
        padding: 20px;
    }
}

@media (min-width: 768px) {
    .message {
        padding: 1.0em 1.0em 0em 1.0em;
        font-size: 2.0em;
        line-height: 0.9em;
    }

    .watchSpotButton {
        padding: 20px 35px;
    }
}

@media (min-width: 1200px) {
    .message {
        padding: 80px 60px 20px;
        font-size: 3.0em;
        line-height: 0.9em;
    }

    .watchSpotButton {
        padding: 20px 60px 20px;
    }
}

.btn-pill {
    border-radius: 50vh !important;
    cursor: pointer;
    transition: all .3s;

    &:hover {
        background: #e7eff3;
        

        > .buttonLabel {
            color: #00739d;
        }
    }
}



.btn-newBrand {
    background-color: #00aeef;
    border: solid 1px #b3c4cd;
    display: flex;
    vertical-align: middle;
    padding: 0px;
}


.buttonLabel {
    padding: 13px 5px 10px 25px;
    font-family: "Montserrat",Arial,Helvetica,sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    color: white;
    white-space: nowrap;
}

.buttonIcon {
    padding-top: 13px;
    padding-left: 21px;

    > .fa {
        color: white;
    }
}

.bg-circle {
    border-radius: 50%;
    background-color: #009dea;
    width: 50px;
    height: 50px;
    margin-left: 10px;
}

.imageBox {
    object-fit: cover;
}

.imageBox > img {
    width: 100%;
}


.add-bottomspacer {
    margin-bottom: 3.25em;
}
