/**
 * @file
 * Define your custom Sass extends here.
 * @see http://sass-lang.com/documentation/file.SASS_REFERENCE.html#extend
 */

//////////////////////////////////////////
// Helper: Typography grooming extends
//////////////////////////////////////////

// Tight tracking
%track-in {
  letter-spacing: -.0325em;
}

%track-in-extra {
  letter-spacing: -.0425em;
}

// Open tracking
%track-out {
  letter-spacing: .05em;
}

// Style larger text with a tighter tracking, change weight, etc
%display-type {
  @extend %track-in;
  font-weight: 400;
  color: $mlh-gray-dark;
}

// Style all caps with a slightly open tracking
%allcaps {
  @extend %track-out;
  text-transform: uppercase;
}




//////////////////////////////////////////
// Helper: Clearfix
//////////////////////////////////////////

.clearfix,
%clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}




//////////////////////////////////////////
// Helper: Remove list styles for ul, ol
//////////////////////////////////////////

%no-bullets {
  list-style: none;
  padding: 0;
  margin: 0;
}




//////////////////////////////////////////
// Helper: Change links to gray / blue
//////////////////////////////////////////

%gray-link {

  a {
    color: $mlh-gray-dark;

    &:hover,
    &:focus {
     // color: $mlh-blue;
     color: $mlh-blue-link;
    }
  }
}




//////////////////////////////////////////
// Helper: Form label styles
//////////////////////////////////////////

.label,
%label {
  @extend %track-in;
  @include sans-gotham();
  @include adjust-font-size-to($label, 1.6);
  @include leader(0, $label);
  @include trailer(1, $label);
  display: block;
  color: $mlh-gray-medium;
  margin-top: 0.90278em;
}




//////////////////////////////////////////
// Helper: Visually hidden extend
//////////////////////////////////////////

.element-invisible,
%element-invisible {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);

  @if support-legacy-browser(ie, '7') {
    clip: rect(1px 1px 1px 1px); // IE6 and IE7 use the wrong syntax
  }
}

// Turns off the element-invisible effect
%element-invisible-off {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto;
}

.element-focusable,
%element-focusable {
  @extend %element-invisible;

  &:active,
  &:focus {
    @extend %element-invisible-off;
  }
}
