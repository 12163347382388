/**
 * @file
 * Locations
 */

//////////////////////////////////////////
// Location Section: Nav default
//////////////////////////////////////////

.location--nav {
  padding-bottom: rhythm(2);

  @include media($medium-min) {
    @include branding-bar();
    background-color: $white;
    padding: rhythm(2) 0 rhythm(3);
    margin-top: -90px;
  }

  ul {
    @extend %no-bullets;
  }

  li {
    display: block;
    position: relative;

    &.active-child > a {
      color: $mlh-blue;
    }
    &.active > a {
      color: $mlh-blue;
      cursor:default;
    }
  }

  a {
    @include adjust-font-size-to($nav, 1.2);
    @include sans-gotham();
    display: block;
    color: $mlh-gray-dark;
    padding: rhythm(1.25) rhythm(1.5);

    &:hover,
    &:focus {
      color: $mlh-blue;
    }
  }
}




//////////////////////////////////////////
// Location Section: Menu toggle
//////////////////////////////////////////

.location--nav__menu-toggle {
  @include adjust-font-size-to($nav, 2);
  @include sans-gotham($weight: 700);
  display: block;
  background-color: $mlh-blue;
  text-transform: uppercase;
  color: $white;
  cursor: pointer;
  padding: rhythm(1);

  @include media($medium-min) {
    display: none;
  }

    i.arrow {
        transition: all .3s ease;
        top: 3px;
        float:right;
        color: $white;
        text-align: center;
        cursor:pointer;

        &.opened {
          transform: rotate(180deg);
        }

    }

}




//////////////////////////////////////////
// Location Section: Nav parent ul
//////////////////////////////////////////

.location--nav__parent {
  display: none;

  @include media($medium-min) {
    display: block !important;
    text-align: center;
  }

  > li {
    @include media($medium-min) {
      display: inline-block;
      margin: 0 rhythm(1);
    }

    @include media($large-min) {
      margin: 0 rhythm(1.5);
    }

    > a {
      border-bottom: 1px solid $mlh-blue-gray-dark;
      text-transform: uppercase;
      font-weight: 700;
      padding-right: 3em;

      @include media($medium-min) {
        border-bottom: 3px solid transparent;
        padding: 0 0 rhythm(1);



        &:focus .location--nav__level2 {
          transform: translateY(0);
          opacity: 1;
          visibility: visible;
        }


        &.active {
          border-color: $mlh-blue;
        }
      }
    }
  }

  .has-children {
    position: relative;
  }

  i {
    transition: all .3s ease;
    position: absolute;
    top: 0;
    right: 0;
    width: 2.3em;
    height: 2.6em;
    color: $mlh-orange;
    line-height: 2.6em;
    text-align: center;
    cursor:pointer;

    &.opened {
      transform: rotate(180deg);
    }

    @include media($medium-min) {
      position: absolute;
      top: 0;
      right: -1em;
      width: auto;
      height: auto;
      line-height: 1;

      &.opened {
        transform: rotate(0deg);
      }
    }
  }
}




//////////////////////////////////////////
// Location Section: Nav level 2 ul
//////////////////////////////////////////

.location--nav__level2 {
  display: none;
  background-color: $off-white;
  border-bottom: 1px solid $mlh-blue-gray-dark;

  @include media($medium-min) {
    display: block !important;
    transition: all .3s ease;
    transform: translateY(-1em);
    position: absolute;
    top: 100%;
    left: -1em;
    background-color: $white;
    border-radius: $global-radius;
    border: 1px solid $mlh-blue-gray-dark;
    box-shadow: 0 5px 15px rgba($black, .15);
    width: 15em;
    text-align: left;
    opacity: 0;
    visibility: hidden;
    z-index: 100;

    // Pointer arrow
    &:before,
    &:after {
      position: absolute;
      pointer-events: none;
      bottom: 100%;
      left: 1.5em;
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
    }

    &:after {
      border-color: rgba($white, 0);
      border-bottom-color: $white;
      border-width: 10px;
      margin-left: -10px;
    }

    &:before {
      border-color: rgba($mlh-blue-gray-dark, 0);
      border-bottom-color: $mlh-blue-gray-dark;
      border-width: 11px;
      margin-left: -11px;
    }

    .location--nav-active & {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
    }
  }

  > li {

    @include media($medium-min) {
      border-bottom: 1px solid $mlh-blue-gray-dark;
    }
  }
}

// location map
.location .profile-office__map {
	width: 100%;
	height: 100%;
	margin-bottom: rhythm(1);

	@include media($large-max) {
		display: none;
	}
}
