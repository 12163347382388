/**
 * @file
 * Patient spotlight slideshow
 */

.spotlight {
  overflow: hidden;
}

.spotlight__image {
  display: inline-block;
  border: 2px solid $mlh-green;
  border-radius: 50%;
  width: 150px;
  height: 150px;

  @include media($medium-min) {
    float: left;
    width: 215px;
    height: 215px;
    margin-left: rhythm(4);
  }

  .spotlight__image__src {
    border-radius: 50%;
    overflow: hidden;
    margin: rhythm(.5);
  }
}

.spotlight__content {

  @include media($medium-min) {
    margin: 0 rhythm(4) 0 305px;
  }
}

.spotlight__label {
    @extend %track-out;
    @include adjust-font-size-to($label, 2);
    @include leader(0, $label);
    @include trailer(.5, $label);
    color: #9f5a1d;
    text-transform: uppercase;
}

.spotlight__name {
  @include leader(.5, $h3-font-size);

  a {
    color: inherit;
  }
}

.spotlight__quote {
  @include adjust-font-size-to($lede, 2);
  @include trailer(1, $lede);
  @include sans-cond($weight: 300, $style: italic);
  border: 0;
  text-align: left;
  padding: 0;
  margin-top: 0;

  &:before {
    display: none;
  }
}

// Flexslider reset
.slides,
.slides > li,
.spotlight .flex-control-nav,
.spotlight .flex-direction-nav {
  @extend %no-bullets;
}

.spotlight .flex-pauseplay span {
  text-transform: capitalize;
}

// Flexslider base theme
.spotlight .slides > li {
  display: none;
  text-align: center;
  -webkit-backface-visibility: hidden;

  @include media($medium-min) {
    text-align: left;
  }
}

.spotlight .slides img {
  width: 100%;
  display: block;
}

.spotlight .slides:after {
  content: '\0020';
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

html[xmlns] .spotlight .slides {
  display: block;
}

@if support-legacy-browser(ie, '7') {
  // scss-lint:disable PropertySpelling
  * html .spotlight .slides {
    height: 1%;
  }
}

.no-js .spotlight .slides > li:first-child {
  display: block;
}

// Flexslider default theme
.spotlight {
  position: relative;
  margin: 0;
  zoom: 1;
}

.spotlight .slides {
  zoom: 1;
}

.spotlight .slides img {
  height: auto;
}

.spotlight .flex-viewport {
  transition: all 1s ease;
  max-height: 2000px;
}

.loading .flex-viewport {
  max-height: 300px;
}

.spotlight .carousel li {
  margin-right: 5px;
}

.spotlight .flex-direction-nav {
  display: none;
  @if support-legacy-browser(ie, '7') {
    // scss-lint:disable PropertySpelling
    *height: 0;
  }

  @include media($xx-small-min) {
    display: block;
  }
}

.spotlight .flex-direction-nav a {
  transition: all .3s ease-in-out;
  background: $white;
  border-radius: 50%;
  text-align: center;
  text-decoration: none;
  display: block;
  width: 55px;
  height: 55px;
  margin: -20px 0 0;
  position: absolute;
  top: 50%;
  z-index: 10;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
}

.spotlight .flex-direction-nav a:before {
  font-family: 'FontAwesome';
  font-size: 32px;
  line-height: 55px;
  display: inline-block;
  width: 100%;
  content: '\f104';
}

.spotlight .flex-direction-nav a.flex-next:before {
  content: '\f105';
}

.spotlight .flex-direction-nav .flex-prev {
  left: -50px;
}

.spotlight .flex-direction-nav .flex-next {
  right: -50px;
}

.spotlight:hover .flex-direction-nav .flex-prev {
  opacity: .7;
  left: 25px;
}

.spotlight:hover .flex-direction-nav .flex-prev:hover {
  opacity: 1;
}

.spotlight:hover .flex-direction-nav .flex-next {
  opacity: .7;
  right: 25px;
}

.spotlight:hover .flex-direction-nav .flex-next:hover {
  opacity: 1;
}

.spotlight .flex-direction-nav .flex-disabled {
  opacity: 0 !important;
  cursor: default;
}

.spotlight .flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: 10px;
  text-align: center;
}

.spotlight .flex-control-nav li {
  margin: 0 6px;
  display: inline-block;
  zoom: 1;
  @if support-legacy-browser(ie, '7') {
    // scss-lint:disable PropertySpelling
    *display: inline;
  }
}

.spotlight .flex-control-paging li a {
  width: 11px;
  height: 11px;
  display: block;
  background: rgba($mlh-blue, .5);
  cursor: pointer;
  text-indent: -9999px;
  border-radius: 20px;

  .no-rgba & {
    background: lighten($mlh-blue, 10%);
  }

  &:hover {
    background: rgba($mlh-blue, .7);

    .no-rgba & {
      background: lighten($mlh-blue, 5%);
    }
  }

  &.flex-active {
    background: $mlh-blue;
    cursor: default;
  }
}
