/**
 * @file
 * Location contact
 */

//////////////////////////////////////////
// Meta: Default
//////////////////////////////////////////

.detail__meta {
  @extend %track-out;
  @include slab();
  @include adjust-font-size-to($meta-sm, 2);
  @include leader(0);
  @include trailer(1.5, $meta-sm);
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 15px !important;

  @include media($x-small-min) {
    font-size: .8em;
  }

  > span {
    border-right: 1px solid $gray-light;
    padding-right: rhythm(1);
    margin-right: rhythm(1);

    &:last-child {
      border-right: 0;
      padding-right: 0;
      margin-right: 0;
    }
  }

  .detail__date,
  .detail__time,
  .detail__cost {
    white-space: nowrap;
  }

  .detail__share {
    border-right: 0;
    padding-right: 0;
  }
}

.detail__social {
  @extend %no-bullets;
  display: inline-block;

  li {
    display: inline-block;
  }
}


//////////////////////////////////////////
// Meta: Events
//////////////////////////////////////////
.detail__event-date {
  @include slab();
  display: block;
  float: left;
  min-width: 3.6em;
  border-right: 2px solid $mlh-orange;
  font-size: .7em;
  text-align: center;
  line-height: 1.1;
  text-transform: uppercase;
  padding-right: rhythm(1);
  margin: .2em .8125em .5em 0;

  @include media($xx-large-min) {
    position: absolute;
    top: 3,5em;
    left: -5em;
    float: none;
    font-size: .8em;
  }

  .detail__event-date__day {
    display: block;
    font-size: 2em;
  }
}

//////////////////////////////////////////
// Meta: Updated Blog Info
//////////////////////////////////////////
.detail__updated_meta {
  @extend %track-out;
  @include slab();
  @include adjust-font-size-to($meta-sm, 2);
  @include leader(0);
  @include trailer(1.5, $meta-sm);
  text-transform: uppercase;
  font-weight: 700;

  @include media($x-small-min) {
    font-size: .8em;
  }

  > span {
    border-right: 1px solid $gray-light;
    padding-right: rhythm(1);
    margin-right: rhythm(1);

    &:last-child {
      border-right: 0;
      padding-right: 0;
      margin-right: 0;
    }
  }

  .detail__update_date,
  .detail__update_reviewer {
    white-space: nowrap;
  }

  .detail__share {
    border-right: 0;
    padding-right: 0;
  }

}