/**
* @file
* Related People and Related Locations
*/

.related-components {
	padding: rhythm(2) 0;

    @include media($x-small-min) {
      	padding: rhythm(4) 0;

	  	.l-block .l-block--50 & {
	  		padding: rhythm(2) 0;
  		}
    }

	&--people {
		background-color: $off-white;
		

		.list--block {
			margin-bottom: 0;
		}

		.l-block--50 & {
			background-color: transparent;
			margin-top: 0;
			margin-bottom: 0;
		}
		@include media($small-min) {
			.list__item {
				border-top: 1px solid $gray-light;
				border-bottom: none;
				margin-bottom: 0;
				padding-top: 0.8125em;

				&:first-child,
				&:nth-child(2) {
					border-top: none;
					padding-top: 0;
				}
			}
			.l-block--50 &:nth-child(2):last-child .list__item,
		    .l-block--50 &:first-child:nth-last-child(2) .list__item {
				border-top: none;
				border-bottom: 1px solid $gray-light;
				margin-bottom: 0.8125em;
				padding-top: 0;
			}
		}
	}

	.block__button {
		margin-top: 1em;
	}

	.list__header .link {
		display: none;
	}
    .list--block {
        width: 100%;
    }
	.list__title {
		margin-top: 0;
	}
	.list__item {

	}

    @include media($small-min) {
        .list__items {
            @include clearfix;
        }
        .list__item {
            @include span-columns(6);
            @include omega(2n);

            &:nth-last-child(2),
            &:last-child {
                border-bottom: none;
            }
        }
    }
    // 1 of 2 elements or sidebar
    .l-sidebar & .list__item,
    .l-block--50 &:nth-child(2):last-child .list__item,
    .l-block--50 &:first-child:nth-last-child(2) .list__item {
        @include span-columns(12);
        border-bottom: 1px solid $gray-light;

        &:last-child {
            border-bottom: none;
        }
    }
	&--restricted {
		.l-block--50 &:nth-child(2):last-child,
		.l-block--50 &:first-child:nth-last-child(2) {
			.list__item {
				&:nth-child(3):nth-last-child(2), // 4 children
				&:nth-child(3):nth-last-child(3), // 5 children
				&:nth-child(3):nth-last-child(4) { // 6 children
					border-bottom: none;
				}
				&:nth-child(n+4) {
					display: none;
				}
			}
			.list__view-more {
				&--100 {
					display: none;
				}
				&--50 {
					display: block;
				}
			}
		}
	}

	.l-sidebar & {
		@include media($x-small-min) {
			background-color: transparent;
	        box-shadow: none;
	        margin: 0 0 rhythm(4);
	        padding: 0;

			.container {
				min-width: 0;
				padding: 0;
				width: 100%;
			}
			.list__header {
				margin-bottom: rhythm(1.5);
		        text-align: left;

		        .link {
		          display:block;
		          position: absolute;
		          top: .25em;
		          right: 0;
		        }

				&__title {
					@include adjust-font-size-to($text, 1.4);
			        @include leader(0, $text);
			        @include trailer(0, $text);
			        text-transform: uppercase;
			        color: $list-header-title;
			        padding-right: 4.5em;
			        background: none;
			        padding-left: 0;
			        text-align: left;
				}
			}
			.list__item {
				border-bottom: none;
                padding-top: 0.8125em;
                border-top: 1px solid $gray-light;
                &:first-child {
				    border-top: none;
			    }

			}
			.list__contact,
			.block__button {
				display:none;
			}
		}
		.list__item--location {
			padding-left: 0;
			padding-right: 0;

			&:first-child {
				padding-top: 0;
			}

			@include media($x-small-to-small) {
				.list__img {
					margin-bottom: .5em;
					width: 100%;
				}
				.has-img {
					margin-left: 0;
				}
			}
		}
	}
}
