﻿main:empty
{
    display:none;
}

.isHidden
{
    display:none;
}

.isShown
{
    display: block;
}