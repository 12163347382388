﻿@include media($large-max) {
    body.doctor, body.event-detail, body.blog-post-detail, body.news-detail, body.patient-spotlight, body.patient-spotlight {
        .bodyRightRailContainer {
            display: table !important;
            width: 100%;

            main {
                display: table-row-group !important;
                float: none !important;
            }

            aside {
                display: table-footer-group !important;
                float: none !important;
            }
        }
    }

    body.patient-spotlight {
        h4 {
            padding-bottom: rhythm(2);
        }
    }
}

aside{
     margin-bottom: rhythm(3);
}

aside:empty
{
    display:none;
}

aside:empty + main 
{
    width: 100% !important;
}