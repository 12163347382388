form[data-sc-fxb] {
  input[type="submit"] {
    margin-top: 24px;
  }

  .field-validation-error {
    display: inline-block;
    color: #b94a48;
    padding-top: 8px;
  }
}


.sitecore-form__label--required {
  &::before {
    color: #d00;
    content: "*";
    font-weight: bolder;
    padding-right: 4px;
  }
}
