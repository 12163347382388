/**
 * @file
 * Define your custom Sass mixins here.
 * @see http://sass-lang.com/docs/yardoc/file.SASS_REFERENCE.html#defining_a_mixin
 */

//////////////////////////////////////////
// Mixin: Transitions
//////////////////////////////////////////

@mixin trans($timing, $easing) {
  transition: all $timing $easing;
}




//////////////////////////////////////////
// Mixin: Fonts
//////////////////////////////////////////

@mixin sans($weight: 400, $style: normal) {
  font-family: $arial;
  font-weight: $weight;
  font-style: $style;
}

@mixin sans-gotham($weight: 400, $style: normal) {
  font-family: $montserrat;
  font-weight: $weight;
  font-style: $style;
}

@mixin sans-cond($weight: 400, $style: normal) {
  font-family: $roboto;
  font-weight: $weight;
  font-style: $style;
}

@mixin slab($weight: 400, $style: normal) {
  font-family: $arvo;
  font-weight: $weight;
  font-style: $style;
}




//////////////////////////////////////////
// Mixin: Three color branding bar
//////////////////////////////////////////

@mixin branding-bar() {
  position: relative;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: '';
    height: 4px;
    width: 100%;
    background-color: $mlh-orange;
    background: linear-gradient(to right, $mlh-blue 35%, $mlh-green 35%, $mlh-green 65%, $mlh-orange 65%, $mlh-orange 65%);
  }
}
