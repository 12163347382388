﻿
.ds-summary {
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    text-rendering: optimizeLegibility;
    font-size: 0.7em;
    /* search results and listing */
    &.providersList, &.related-ProvidersList {
        display: flex;

        a#js-jumpToProfileReviewTab {
            display: flex;
            flex-wrap: nowrap;
            justify-content: flex-start;
            /* temp - disable href link on the listings */
            pointer-events: none;
            cursor: not-allowed;

            .ds-ratings, .ds-comments {
                display: none;
            }
        }

        .ratingMethodLink {
            display: none;
        }
    }

    &.providerProfile, &.providerProfileTab {

        .ds-title {
            font-size: 1.3em;
        }

        a#js-jumpToProfileReviewTab {

            .ds-ratings, .ds-comments {
                display: inline-block;
                margin-right: 5px;
            }

            .ds-comments :before {
                content: " / ";
            }
        }
    }

    &.providerProfileTab {

        a#js-jumpToProfileReviewTab {
            /* temp - disable href link on the listings */
            pointer-events: none;
            cursor: not-allowed;

            .ds-xofy {
                font-size: 1.2em;
            }

            .starsArea {
                margin-top: 0px;
                padding: 0px;

                .ds-stars {
                    zoom: 1.3;
                    -moz-transform: scale(1.3);
                    -moz-transform-origin: 0 0;
                }
            }

            .ds-ratings, .ds-comments {
                display: inline-block;
                margin-right: 5px;
                font-size: 1.2em;
            }

            .ds-comments :before {
                content: " / ";
            }
        }
    }

    &.overallReview {
        text-align: center;

        .ds-title {
            white-space: nowrap;
            font-size: 1.6em;
        }

        .ratingOuterGroup {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;

            .starRatingGroup {
                text-align: center;
                border-right: 1px solid white;
                padding-right: 10px;

                .starsArea {
                    margin-top: 0px;
                    padding: 0px;

                    .ds-stars {
                        zoom: 1.3;
                        -moz-transform: scale(1.5);
                        -moz-transform-origin: 65px 20px;
                    }
                }

                .ds-xofy {
                    line-height: 0.6em;
                    white-space: nowrap;

                    span {
                        color: white;
                        font-weight: bold;
                    }
                }
            }

            .ds-ratings {
                padding-left: 10px;
                white-space: nowrap;
                font-weight: bold;
            }
        }

        .ribbon-ui {
            width: 92%;
            font-size: 18px;
            position: relative;
            background-color: #84bf41;
            text-align: center;
            color: white;
            letter-spacing: 3px;
            padding: 1em 6em;
            margin: 1em auto;
        }

        .ribbon-content {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;
        }

        .ribbon-ui:before, .ribbon-ui:after {
            content: "";
            position: absolute;
            display: block;
            bottom: -.99em;
            border: 1.5em solid darken(#84bf41, 10%);
            z-index: -1;
        }

        .ribbon-ui:before {
            left: -1.7em;
            border-right-width: 1.2em;
            border-left-color: transparent;
        }

        .ribbon-ui:after {
            right: -2.03em;
            border-left-width: 1.5em;
            border-right-color: transparent;
        }

        .ribbon-ui > .ribbon-content {
        }

        .ribbon-ui .ribbon-content:before, .ribbon-ui .ribbon-content:after {
            content: "";
            position: absolute;
            border-style: solid;
            border-color: darken(#84bf41, 20%) transparent transparent transparent;
            bottom: -1em;
        }

        .ribbon-ui .ribbon-content:before {
            left: 0;
            border-width: 1em 0 0 1em;
        }

        .ribbon-ui .ribbon-content:after {
            right: 0;
            border-width: 1em 1em 0 0;
        }
    }

    .ds-title {
        white-space: nowrap;
    }

    .starsArea {
        display: inline-block;
        margin-top: 3px;
        padding: 0 5px;
    }

    .ratingMethodLink {
        font-weight: bold;
        font-size: 1.2em;
    }
}

.ds-comments {
    .ds-comment {
        .ds-lcol {
            .ds-date {
                margin: 0 5px 0 0;
            }
        }
    }
}