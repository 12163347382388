﻿.ERExpressColumn
{
    display:flex;
    margin-top: 2.96563em;
    align-content: flex-start;
    flex-wrap: wrap;
}

.ERExpressColumn:first-child
{
    flex: 1;
}

.ERExpressColumn:last-child
{
    flex: 2;
}

.ERExpressColumn h3
{
    margin-top: 0px;
    padding-top: 0px;
}

.ERExpress-item-wrapper
{
    padding-top: 10px 20px 0px 0px;
    width: 100%;
    margin: 0 auto;

    min-width: 340px;
}

.ERExpressBtn-header 
{
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 0.7em;
    line-height: 1.3em;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    background: #00739d;
    border: 1px 1px 0px 1px solid #00739d;
    border-radius: 0px 0px 6px 6px;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    -webkit-transition: all .3s;
    transition: all .3s;
    vertical-align: middle;
    padding: 0.8125em 1.21875em 0.65em;
    margin-top: 0;
}
    
.ERExpressBtn-body
{
    background: #e7eff3;
    padding: 1em;
    margin-top: 0;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 0.7em;
    line-height: 1.16071em;
    border: 0px 1px 1px 1px solid #b3c4cd;
    border-radius: 6px 6px 0px 0px;
    text-align: left;

    .ERExpressTable
    {
        border: none !important;
    }
    
    .timeSlot
    {
        font-size: 2.65em;
        line-height: 1.16071em;
    }
}

.ERExpress-showAllFour
{
    list-style-type: none;
    padding: 30px 0px 20px 0px;
    margin: 30px 0px 50px 0px;
    border-bottom: solid 3px #ccc;

    li
    {
        display: inline-block;
        vertical-align: top;
        margin-bottom: 40px;
        padding-right: 2.5em;
    }
}