/**
 * @file
 * Sidebar
 */

//////////////////////////////////////////
// Sidebar: Default
//////////////////////////////////////////

.sidebar--block {
  margin-bottom: rhythm(4);
  
  .l-block--50 &{
	padding: rhythm(2) rhythm(2);
	
	@include media($x-small-min) {
      padding: rhythm(2) 0;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
  
  .l-sidebar &{
    @media only screen and (max-width: 630px)  {
	    margin-top: 0;
        margin-bottom: rhythm(3);
	}
  }
}
